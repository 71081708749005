import { MoreVertRounded } from "@mui/icons-material";
import { Avatar, Box, IconButton, Typography } from "@mui/material";
import PhotoSizeSelectActualOutlinedIcon from "@mui/icons-material/PhotoSizeSelectActualOutlined";
import {
  CardAvatar,
  CardMainText,
  ExerciseDetails,
  ModuleCardStyle,
} from "../ExerciseCollection.style";

const ExerciseCategoryCard = ({ data, openMenu, handleAction }: any) => {
  return (
    <>
      <Box sx={ModuleCardStyle} onClick={() => handleAction("click", data)}>
        <Avatar variant="rounded" src={data?.imageUrl} sx={CardAvatar}>
          <PhotoSizeSelectActualOutlinedIcon
            sx={{ width: "45px", height: "45px" }}
          />
        </Avatar>
        <Box sx={ExerciseDetails}>
          <Typography fontSize={"24px"} fontWeight="medium" sx={CardMainText}>
            {data?.title}
          </Typography>
          <Box>
            <Typography
              variant="subtitle1"
              color="#4b5563"
              display={"flex"}
              alignItems={"center"}
              gap={"8px"}
            >
              {data?.time} mins
            </Typography>
          </Box>
        </Box>
        <Box sx={{ ml: "auto" }}>
          <IconButton onClick={(e) => openMenu(e, data)}>
            <MoreVertRounded />
          </IconButton>
        </Box>
      </Box>
    </>
  );
};

export default ExerciseCategoryCard;

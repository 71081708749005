import React, { useState } from "react";
import { AxiosResponse } from "axios";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  FormLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
} from "@mui/material";

import * as yup from "yup";
import { Formik } from "formik";
import { ModalBaseStyles, ModalHeader } from "../Common/styles/modal";
import http from "../../utils/http";
import { errorToastMessage, toastMessage } from "../../utils/toast";
import { LabelStyle } from "../Common/styles/form";

const dosageUnits = [
  "Kg",
  "g",
  "mg",
  "mcg",
  "L",
  "mL",
  "cc",
  "mol",
  "mmol",
  "IU",
];

let schema = yup.object().shape({
  type: yup.string().required("Drug Type is Required"),
  brandName: yup.string().required("Brand Name is Required"),
  dose: yup.string().required("Dose is Required"),
  unit: yup.string().required("Unit is Required"),
});

type Props = {
  showModal: boolean;
  closeModal: () => void;
  data: any;
  refreshPage: () => void;
};

const AddMedicationModal = ({
  showModal,
  closeModal,
  data,
  refreshPage,
}: Props) => {
  const [submitLoader, setSubmitLoader] = useState(false);

  const submitHandler = async (values: any) => {
    try {
      setSubmitLoader(true);
      let res: AxiosResponse;
      if (data?.id) {
        res = await http.put(`/medication/${data?.id}`, values);
      } else {
        res = await http.post(`/medication`, values);
      }
      toastMessage("success", res.data.message);
      closeModal();
      refreshPage();
    } catch (err) {
      setSubmitLoader(false);
      errorToastMessage(err as Error);
    }
  };

  return (
    <Modal open={showModal} onClose={closeModal}>
      <Box sx={ModalBaseStyles}>
        <ModalHeader
          title={data?.id ? "Edit Medication" : "Add Medication"}
          onCloseClick={closeModal}
        />

        <Formik
          initialValues={{
            type: data?.type || "",
            brandName: data?.brandName || "",
            dose: data?.dose || "",
            unit: data?.unit || "Kg",
          }}
          validationSchema={schema}
          onSubmit={(values) => {
            submitHandler(values);
          }}
        >
          {({
            handleSubmit,
            getFieldProps,
            setFieldValue,
            values,
            errors,
            touched,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box sx={{ display: "flex", gap: 2 }}>
                <FormControl sx={{ width: "50%" }}>
                  <FormLabel sx={LabelStyle} htmlFor="drug-type">
                    Drug Type
                  </FormLabel>
                  <TextField
                    id="drug-type"
                    {...getFieldProps("type")}
                    error={touched?.type && errors?.type ? true : false}
                    helperText={
                      touched?.type && errors?.type
                        ? (errors?.type as string)
                        : " "
                    }
                  />
                </FormControl>
                <FormControl sx={{ width: "50%" }}>
                  <FormLabel sx={LabelStyle} htmlFor="brand-name">
                    Brand Name
                  </FormLabel>
                  <TextField
                    id="brand-name"
                    {...getFieldProps("brandName")}
                    error={
                      touched?.brandName && errors?.brandName ? true : false
                    }
                    helperText={
                      touched?.brandName && errors?.brandName
                        ? (errors?.brandName as string)
                        : " "
                    }
                  />
                </FormControl>
              </Box>
              <Box sx={{ display: "flex", gap: 2 }}>
                <FormControl sx={{ width: "50%" }}>
                  <FormLabel sx={LabelStyle} htmlFor="dose">
                    Dose
                  </FormLabel>
                  <TextField
                    id="dose"
                    fullWidth
                    {...getFieldProps("dose")}
                    error={touched?.dose && errors?.dose ? true : false}
                    helperText={
                      touched?.dose && errors?.dose
                        ? (errors?.dose as string)
                        : " "
                    }
                  />
                </FormControl>
                <FormControl sx={{ width: "50%" }}>
                  <FormLabel sx={LabelStyle} htmlFor="unit">
                    Unit
                  </FormLabel>
                  <Select
                    id="unit"
                    fullWidth
                    value={values?.unit}
                    onChange={(e) => setFieldValue("unit", e.target.value)}
                    error={touched?.unit && errors?.unit ? true : false}
                  >
                    {dosageUnits.map((unit: string) => (
                      <MenuItem key={unit} value={unit}>
                        {unit}
                      </MenuItem>
                    ))}
                  </Select>
                  {touched?.unit && errors?.unit && (
                    <FormHelperText error>
                      {errors?.unit as string}
                    </FormHelperText>
                  )}
                </FormControl>
              </Box>
              <Box
                sx={{
                  mt: 2,
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: 1,
                }}
              >
                {!submitLoader ? (
                  <>
                    <Button type="submit" variant="contained">
                      Save
                    </Button>
                    <Button onClick={closeModal} variant="outlined">
                      Cancel
                    </Button>
                  </>
                ) : (
                  <CircularProgress size={25} />
                )}
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

export default AddMedicationModal;

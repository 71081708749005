export const ptAnalyticsData = [
  {
    label: "Total Number of Patients",
    count: 5739,
  },
  {
    label: "Active Patients",
    count: 5024,
    value: 87,
  },
  {
    label: "Signed In Patients",
    count: 5010,
    value: 84,
  },
  {
    label: "Live Patients",
    count: 5010,
    value: 84,
  },
];

export const contentProgressData = [
  {
    label: "Total Modules",
    count: 240,
  },
  {
    label: "Active Modules",
    count: 5024,
    value: 87,
  },
  {
    label: "Total Lessons",
    count: 5024,
    value: 87,
  },
  {
    label: "Active Lessons",
    count: 5024,
    value: 87,
  },
];

export const QuesProgressData = {
  totalQuesCount: 25,
  AnsweredQuesCount: 22,
  AnsweredQuesValue: 87,
  overallProgress: [
    {
      label: "Global Physical Activity Questionnaire (GPAQ)",
      value: 88,
    },
    {
      label: "Food Frequency and Patterns",
      value: 74,
    },
    {
      label: "36-Item Short Form Survey Instrument (SF36)",
      value: 62,
    },
    {
      label: "Pittsburgh Sleep Quality Index (PSQI)",
      value: 35,
    },
    {
      label: "Leisure Time Activities",
      value: 10,
    },
  ],
};

export const programAnalyticsData = [
  {
    label: "Patients Enrolled Since Program Start Date",
    count: 10186,
  },
  {
    label: "Active Patients",
    count: 5024,
    value: 87,
  },
  {
    label: "New Patients",
    count: 214,
    value: 59,
  },
  {
    label: "Number of Sessions Per Patient",
    count: 5010,
    value: 84,
  },
];

export const healthProgressData = [
  {
    label: "Blood Glucose",
    medium: 40,
    high: 20,
    low: 33,
  },
  {
    label: "Blood Pressure",
    medium: 25,
    high: 15,
    low: 1,
  },
  {
    label: "Heart Rate",
    medium: 25,
    high: 15,
    low: 30,
  },
  {
    label: "HbA1c",
    medium: 25,
    high: 15,
    low: 30,
  },
  {
    label: "Weight",
    medium: 25,
    high: 15,
    low: 30,
  },
  {
    label: "Medication",
    medium: 25,
    high: 15,
    low: 30,
  },
  {
    label: "Sleep",
    medium: 25,
    high: 15,
    low: 30,
  },
];

export const userActivityAgeData = [
  {
    label: "0-17",
    inactive: 8,
    newlyInactive: 10,
    active: 40,
  },
  {
    label: "18-24",
    inactive: 8,
    newlyInactive: 10,
    active: 40,
  },
  {
    label: "25-34",
    inactive: 8,
    newlyInactive: 10,
    active: 40,
  },
  {
    label: "35-44",
    inactive: 8,
    newlyInactive: 10,
    active: 40,
  },
  {
    label: "45-54",
    inactive: 5,
    newlyInactive: 7,
    active: 30,
  },
  {
    label: "55-64",
    inactive: 8,
    newlyInactive: 10,
    active: 40,
  },
  {
    label: "65-74",
    inactive: 8,
    newlyInactive: 10,
    active: 40,
  },
  {
    label: "75+",
    inactive: 2,
    newlyInactive: 10,
    active: 20,
  },
];

export const userActivityGenderData = [
  {
    label: "Male",
    inactive: 3,
    newlyInactive: 2,
    active: 40,
  },
  {
    label: "Female",
    inactive: 6,
    newlyInactive: 3,
    active: 37,
  },
];

export const doseAdherenceData = {
  digitalAppUsers: 64,
  patientAdheringMed: 55,
  medvsAssessScore: [
    {
      label: "100%",
      mild: 10,
      moderate: 25,
      severe: 30,
    },
    {
      label: "75%",
      mild: 5,
      moderate: 9,
      severe: 20,
    },
    {
      label: "50%",
      mild: 10,
      moderate: 10,
      severe: 20,
    },
    {
      label: "25%",
      mild: 0,
      moderate: 0,
      severe: 10,
    },
  ],
};

export const retentionRateData = [
  {
    x: "Week 1",
    y: 80,
  },
  {
    x: "Week 2",
    y: 70,
  },
  {
    x: "Week 3",
    y: 50,
  },
  {
    x: "Week 4",
    y: 60,
  },
  {
    x: "Week 5",
    y: 75,
  },
  {
    x: "Week 6",
    y: 65,
  },
  {
    x: "Week 7",
    y: 85,
  },
  {
    x: "Week 8",
    y: 90,
  },
  {
    x: "Week 9",
    y: 95,
  },
  {
    x: "Week 10",
    y: 100,
  },
  {
    x: "Week 11",
    y: 50,
  },
  {
    x: "Week 12",
    y: 70,
  },
];

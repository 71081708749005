import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../Redux/hooks";
import {
  LoadingContainer,
  ModuleCardsContainer,
} from "./ExerciseCollection.style";

import {
  StyledHeader,
  HeaderLeftContent,
  HeaderRightContent,
} from "../Common/styles/header";
import { Edit, Add, ArrowBack, Delete } from "@mui/icons-material";
import ExerciseCategoryCard from "./ExerciseCollectionCard/ExerciseCategoryCard";
import {
  setExerciseCategoryModalId,
  toggleExerciseCollection,
} from "../../Redux/reducers/exerciseCollectionSlice";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { AxiosResponse } from "axios";
import http from "../../utils/http";
import { errorToastMessage, toastMessage } from "../../utils/toast";
import ExerciseCategoryModal from "./Modals/ExerciseCategoryModal";
import { NoDataContainer } from "../Common/styles/table";
import RearrangeExerciseModal from "./Sections/RearrangeExerciseModal";

const ExerciseCollectionDetails = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [exerciseCategory, setExerciseCategory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [title] = useState(searchParams.get("title") || "");
  const [showRearrangeModal, setShowRearrangeModal] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams();
    params.set("title", title);

    setSearchParams(params, {
      replace: true,
    });
  }, [title, setSearchParams]);

  const { exerciseCategoryModalId, toggler } = useAppSelector(
    (state) => state.exerciseCollection
  );

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { id } = useParams();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [selectedModule, setSelectedModule] = useState<any>(null);

  const openMenu = (
    event: React.MouseEvent<HTMLButtonElement>,
    module: any
  ) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setSelectedModule(module);
  };

  useEffect(() => {
    const fetchCategoryDetails = async (id: string) => {
      try {
        setLoading(true);
        const res: AxiosResponse = await http.get(
          `/exercise-collection/${id}/categories?pagination=false`
        );
        setExerciseCategory(res.data?.data?.categories);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        errorToastMessage(err as Error);
      }
    };
    if (id) {
      fetchCategoryDetails(id);
    }
  }, [id, setLoading, toggler]);

  const closeMenu = () => {
    setAnchorEl(null);
    setSelectedModule(null);
  };

  const handleAction = async (action: string, module?: any) => {
    const targetId = selectedModule?.id;
    if (action === "click") {
      navigate(
        `/app/exercise/${id}/categories/${module?.id}/sections?title=${module?.title}`
      );
    } else if (action === "edit" && targetId) {
      closeMenu();
      dispatch(setExerciseCategoryModalId(targetId));
    } else if (action === "delete" && targetId) {
      const res: AxiosResponse = await http.delete(
        `/exercise-collection/${id}/categories/${targetId}`
      );
      toastMessage("success", res.data.message);
      closeMenu();
      refreshPage();
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  const showModal = () => {
    dispatch(setExerciseCategoryModalId("new"));
  };

  const closeRearrangeModal = () => {
    setShowRearrangeModal(false);
  };

  const refreshPage = () => {
    dispatch(toggleExerciseCollection());
  };

  return (
    <>
      <StyledHeader>
        <Box sx={{ ...HeaderLeftContent, gap: 1 }}>
          <IconButton onClick={handleBack}>
            <ArrowBack htmlColor="#637E85" />
          </IconButton>
          <Typography fontSize={30} fontWeight="bold">
            {title}
          </Typography>
        </Box>
        <Box sx={HeaderRightContent}>
          {exerciseCategory?.length > 1 && (
            <Button
              variant="outlined"
              onClick={() => setShowRearrangeModal(true)}
            >
              Rearrange
            </Button>
          )}
          <Button variant="contained" startIcon={<Add />} onClick={showModal}>
            Add Exercise Category
          </Button>
        </Box>
        {exerciseCategoryModalId && <ExerciseCategoryModal />}
        {showRearrangeModal && (
          <RearrangeExerciseModal
            callback={refreshPage}
            closeModal={closeRearrangeModal}
            parentId={id || ""}
            items={exerciseCategory}
            title="Rearrange Category"
            type="category"
          />
        )}
      </StyledHeader>
      {!loading ? (
        <Box>
          {exerciseCategory.length > 0 ? (
            <Box>
              <Box sx={ModuleCardsContainer}>
                {exerciseCategory?.map((module: any) => (
                  <ExerciseCategoryCard
                    key={module?.id}
                    data={module}
                    openMenu={openMenu}
                    handleAction={handleAction}
                  />
                ))}
              </Box>
            </Box>
          ) : (
            <NoDataContainer sx={{ mt: 3 }}>
              <Typography variant="body1" color="gray">
                No Data
              </Typography>
            </NoDataContainer>
          )}
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={closeMenu}
            PaperProps={{
              style: {
                minWidth: "225px",
              },
            }}
          >
            <MenuItem
              key="edit"
              onClick={() => {
                handleAction("edit");
              }}
            >
              <ListItemIcon>
                <Edit fontSize="small" />
              </ListItemIcon>
              <ListItemText>Edit</ListItemText>
            </MenuItem>
            <MenuItem
              key="delete"
              onClick={() => {
                handleAction("delete");
              }}
            >
              <ListItemIcon>
                <Delete fontSize="small" color="error" />
              </ListItemIcon>
              <ListItemText>Delete</ListItemText>
            </MenuItem>
          </Menu>
        </Box>
      ) : (
        <Box sx={LoadingContainer}>
          <CircularProgress />
        </Box>
      )}
    </>
  );
};

export default ExerciseCollectionDetails;

import { SxProps } from "@mui/material";

export const BuilderWrapper: SxProps = {
  mt: "24px",
  display: "flex",
  gap: "20px",
  alignItems: "flex-start",
  position: "relative",
};

export const SectionDivider: SxProps = {
  flex: 1,
  minWidth: "1px",
  boxShadow: 1,
  borderRadius: "16px",
  p: 3,
  minHeight: "70vh",
  display: "flex",
  flexDirection: "column",
  backgroundColor: "#ffffff",
  mb: "24px",
};

export const CardContainer: SxProps = {
  flex: 1,
  minWidth: "1px",
  boxShadow: 1,
  borderRadius: "16px",
  p: 3,
  minHeight: "70vh",
  display: "flex",
  flexDirection: "column",
  backgroundColor: "#ffffff",
  position: "sticky",
  top: 0,
  maxHeight: "calc(100vh - 152px)",
  overflow: "hidden",
  "&:hover": {
    overflow: "auto",
  },
};

export const SectionCardContainer: SxProps = {
  ...CardContainer,
  maxWidth: "300px",
  backgroundColor: "#ffffff",
};

export const TypeCardContainer: SxProps = {
  display: "grid",
  gridTemplateColumns: "repeat(auto-fill, minmax(150px, 1fr))",
  gap: "20px",
};

export const TypeCard: SxProps = {
  cursor: "pointer",
  height: "175px",
  border: 1,
  borderColor: "divider",
  borderRadius: "10px",
  p: 2,
};

export const SectionContainer: SxProps = {
  flex: 1,
  minWidth: "1px",
  minHeight: "0px",
};

export const Section: SxProps = {
  height: "81px",
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  mb: 2,
  borderRadius: "10px",
  border: 1,
  borderColor: "divider",
  px: 2.5,
};

export const SectionCard: SxProps = {
  display: "flex",
  alignItems: "center",
  height: "44px",
  borderBottom: "1px solid #e4e4e7",
};

export const SectionCardSvg: SxProps = {
  mr: 1,
  "& svg": {
    width: "24px",
    height: "24px",
  },
};

export const CMSInputWrapper: SxProps = {
  width: "100%",
  mb: 2,
};

export const CMSInputLabel: SxProps = {
  fontWeight: 500,
  typography: "subtitle1",
  mb: "10px",
  color: "#111928",
};

export const ContentItemWrapper: SxProps = {
  mb: 2,
  "&:not(:last-child)": {
    borderBottom: 1,
    borderBottomColor: "divider",
  },
};

export const resourceWrapper: SxProps = {
  display: "flex",
  alignItems: "center",
  gap: 2,
};

export const UploadWrapper: SxProps = {
  borderRadius: "12px",
  border: 1,
  borderColor: "divider",
  background: "#f9fafb",
  display: "flex",
  height: "125px",
  justifyContent: "center",
  alignItems: "center",
  my: 1,
  "&:hover": {
    borderColor: "primary.main",
    cursor: "pointer",
  },
};

export const MiniUploadWrapper: SxProps = {
  ...UploadWrapper,
  height: "54px",
  width: "100px",
  mb: 0,
  mt: 2,
  "& .preview-image": {
    height: "40px",
    objectFit: "contain",
  },
};

export const ArrayIconButtonStyle: SxProps = { alignSelf: "flex-end", mb: 2 };
export const ArrayIconButtonStyle2: SxProps = { alignSelf: "flex-end", mt: 2 };

export const TagSearchContainer: SxProps = {
  height: "200px",
  overflow: "auto",
  my: 2,
  display: "flex",
  flexDirection: "column",
};

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface HealthState {
  type: string;
  startDate: string | null;
  endDate: string | null;
}

const initialState: HealthState = {
  type: "blood_pressure",
  startDate: null,
  endDate: null,
};

const getIntialState = (): HealthState => {
  return {
    ...initialState,
  };
};

export const healthSlice = createSlice({
  name: "health",
  initialState: getIntialState,
  reducers: {
    setHealthType: (state, action: PayloadAction<string>) => {
      state.type = action.payload;
    },
    setHealthStartDate: (state, action: PayloadAction<string | null>) => {
      state.startDate = action.payload;
    },
    setHealthEndDate: (state, action: PayloadAction<string | null>) => {
      state.endDate = action.payload;
    },
    reset: () => initialState,
  },
});

export const { setHealthType, reset, setHealthEndDate, setHealthStartDate } =
  healthSlice.actions;

export default healthSlice.reducer;

import React from "react";
import {
  LinearProgress,
  Table,
  TableBody,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Box,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useEffect, useState } from "react";

import http from "../../utils/http";
import { errorToastMessage } from "../../utils/toast";
import {
  NoDataContainer,
  pageSize,
  paginationLabel,
  StyledTableCell,
  TablePaginationStyle,
} from "../Common/styles/table";
import { Edit, MoreVert } from "@mui/icons-material";
import { AxiosResponse } from "axios";
import AddMedicationModal from "./AddMedicationModal";
type Props = {
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  searchText: string;
};

const MedicationList = ({
  page,
  setPage,
  showModal,
  setShowModal,
  searchText,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const [toggleLoader, setToggleLoader] = useState(false);
  const [medicationData, setMedicationData] = useState<any>([]);
  const [medicationDataCount, setMedicationDataCount] = useState(0);
  const [selectedRow, setSelectedRow] = useState<any>(null);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    const fetchMedicationData = async () => {
      try {
        setLoading(true);
        let url = `/medications?page=${page + 1}&size=${pageSize}`;
        if (searchText) {
          url += `&search=${searchText}`;
        }

        const res: AxiosResponse = await http.get(url);
        const data = res.data?.data;
        const newData = data?.medications.map((med: any) => ({
          id: med?.id,
          type: med?.drugType,
          brandName: med?.brandName,
          dose: med?.dose,
          unit: med?.unit,
        }));

        setMedicationData(newData || []);
        setMedicationDataCount(data?.count || 0);

        setLoading(false);
      } catch (err) {
        setLoading(false);
        errorToastMessage(err as Error);
      }
    };
    fetchMedicationData();
  }, [searchText, page, toggleLoader]);

  const openModal = () => {
    setShowModal(true);
    setAnchorEl(null);
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedRow(null);
  };

  const refreshPage = () => {
    setToggleLoader((prev) => !prev);
  };

  const handleChangePage = (_1: any, newPage: number) => {
    setPage(newPage);
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>, row: any) => {
    setSelectedRow(row);
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  return (
    <Box sx={{ mt: 3 }}>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell>Drug Type</StyledTableCell>
            <StyledTableCell>Brand Name</StyledTableCell>
            <StyledTableCell>Dose</StyledTableCell>
            <StyledTableCell />
          </TableRow>
        </TableHead>
        {!loading && medicationData.length > 0 && (
          <>
            <TableBody>
              {medicationData?.map((row: any) => (
                <TableRow key={row?.id}>
                  <StyledTableCell>{row?.type}</StyledTableCell>
                  <StyledTableCell>{row?.brandName}</StyledTableCell>
                  <StyledTableCell>{row?.dose}</StyledTableCell>
                  <StyledTableCell align="right">
                    <IconButton onClick={(e) => handleMenuClick(e, row)}>
                      <MoreVert />
                    </IconButton>
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
            {medicationDataCount > pageSize && (
              <TableFooter>
                <TableRow>
                  <TablePagination
                    sx={TablePaginationStyle}
                    count={medicationDataCount}
                    page={page}
                    rowsPerPage={pageSize}
                    rowsPerPageOptions={[pageSize]}
                    onPageChange={handleChangePage}
                    labelDisplayedRows={paginationLabel}
                  />
                </TableRow>
              </TableFooter>
            )}
          </>
        )}
      </Table>
      {!loading && medicationData.length === 0 && (
        <NoDataContainer>
          <Typography variant="body1" color="gray">
            No Data
          </Typography>
        </NoDataContainer>
      )}
      {loading && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      )}
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        PaperProps={{
          style: {
            maxHeight: "400px",
            width: "20ch",
          },
        }}
      >
        <MenuItem onClick={openModal}>
          <ListItemIcon>
            <Edit fontSize="small" />
          </ListItemIcon>
          <ListItemText>Edit</ListItemText>
        </MenuItem>
      </Menu>
      {showModal && (
        <AddMedicationModal
          showModal={showModal}
          closeModal={closeModal}
          data={selectedRow}
          refreshPage={refreshPage}
        />
      )}
    </Box>
  );
};

export default MedicationList;

import { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import { Box, CircularProgress } from "@mui/material";
import { CardsWrapper } from "../dashboard.style";
import { programAnalyticsData } from "../dashboardDetails";
import { errorToastMessage } from "../../../utils/toast";
import DashboardCard from "../DashboardCard";
import { LoadingContainer } from "../../CMS/cms.style";
import {
  MainTabPanel,
  StyledTab,
  StyledTabs,
  a11yProps,
} from "../../Common/UI/TabPanel";
import UserActivityTab from "./UserActivityTab";
import DoseAdherenceTab from "./DoseAdherenceTab";
import RetentionRateTab from "./RetentionRateTab";

// import http from "../../utils/http";
// import { AxiosResponse } from "axios";

const DTxInsights = () => {
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState(
    localStorage.getItem("DTxTab") || "user_activity"
  );
  const [programAnalytics, setProgramAnalytics] = useState<any>([]);

  const handleChange = (_: any, newType: string) => {
    setType(newType);
    localStorage.setItem("DTxTab", newType);
  };

  useEffect(() => {
    const fetchDTxData = async () => {
      try {
        setLoading(true);
        // const res: AxiosResponse = await http.get("/dashboard");
        setProgramAnalytics(programAnalyticsData);
        setLoading(false);
      } catch (err) {
        errorToastMessage(err as Error);
        setLoading(false);
      }
    };

    fetchDTxData();
  }, []);

  return !loading ? (
    <>
      <Typography variant="h6" fontWeight="medium" color="#355962" mb={3}>
        Program Analytics
      </Typography>
      <Box sx={CardsWrapper}>
        {programAnalytics.map((item: any) => (
          <DashboardCard
            key={item?.label}
            title={item?.label}
            count={item?.count}
            progress={item?.value}
          />
        ))}
      </Box>
      <Box sx={{ borderBottom: 1, borderColor: "#E5E7EB" }}>
        <StyledTabs value={type} onChange={handleChange}>
          {/* <StyledTab
            label="User Engagement"
            value="user_engagement"
            {...a11yProps(0)}
          /> */}
          <StyledTab
            label="User Activity"
            value="user_activity"
            {...a11yProps(1)}
          />
          {/* <StyledTab
            label="User By State"
            value="user_state"
            {...a11yProps(2)}
          /> */}
          <StyledTab
            label="Retention Rate"
            value="retention_rate"
            {...a11yProps(3)}
          />
          {/* <StyledTab
            label="New User Flow"
            value="user_flow"
            {...a11yProps(4)}
          /> */}
          <StyledTab
            label="Dose Adherence"
            value="dose_adherence"
            {...a11yProps(5)}
          />
          {/* <StyledTab
            label="App Adherence"
            value="app_adherence"
            {...a11yProps(6)}
          /> */}
        </StyledTabs>
      </Box>
      <Box sx={{ mt: "24px" }}>
        <MainTabPanel value={type} index={"user_engagement"}></MainTabPanel>
        <MainTabPanel value={type} index={"user_activity"}>
          <UserActivityTab />
        </MainTabPanel>
        <MainTabPanel value={type} index={"user_state"}></MainTabPanel>
        <MainTabPanel value={type} index={"retention_rate"}>
          <RetentionRateTab />
        </MainTabPanel>
        <MainTabPanel value={type} index={"user_flow"}></MainTabPanel>
        <MainTabPanel value={type} index={"dose_adherence"}>
          <DoseAdherenceTab />
        </MainTabPanel>
        <MainTabPanel value={type} index={"app_adherence"}></MainTabPanel>
      </Box>
    </>
  ) : (
    <Box sx={LoadingContainer}>
      <CircularProgress />
    </Box>
  );
};

export default DTxInsights;

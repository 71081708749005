import { Box, Typography } from "@mui/material";
import {
  scoreBoxStyle,
  scoreCalculatorButtonStyle,
  scoreCalculatorButtonWrapperStyle,
  scoreCalculatorStyle,
} from "./scores.style";

type Props = {
  scores: any[];
  handleExp: Function;
  handleEditExp: Function;
  questions: any[];
  handleConstants: Function;
  handleRemoveOperand: Function;
};

const ScoreCalculator = (props: Props) => {
  const calcButtons = [
    {
      func: "AC",
      classValue: "#A0A5B8",
      label: "AC",
    },
    {
      func: "constant",
      classValue: "#A0A5B8",
      label: "CONST",
    },
    {
      type: "paranthesis",
      func: "(",
      classValue: "#A0A5B8",
      label: "(",
    },
    {
      type: "paranthesis",
      func: ")",
      classValue: "#A0A5B8",
      label: ")",
    },
    {
      func: "%",
      classValue: "#A0A5B8",
      label: "%",
    },
    {
      func: "*",
      classValue: "#00B9FC",
      label: "X",
      multiLabel: "x",
    },
    {
      func: "-",
      classValue: "#00B9FC",
      label: "-",
    },
    {
      func: "+",
      classValue: "#00B9FC",
      label: "+",
    },
    {
      func: "/",
      classValue: "#00B9FC",
      label: "%",
    },
  ];

  const handleChange = (value: any, item: any) => {
    if (value === "" && item.type !== "constant") {
      props.handleRemoveOperand(item);
    } else if (item.type === "constant" && value !== "") {
      props.handleConstants(item, value);
    } else if (value === "" && item.type === "constant") {
      props.handleRemoveOperand(item);
    }
  };

  return (
    <Box sx={scoreCalculatorStyle} className="score-calculator create">
      <Typography variant="subtitle2" fontWeight="medium" mb={2}>
        Operator
      </Typography>
      <Box sx={scoreBoxStyle}>
        <Typography fontSize={16} fontWeight="bold" component="span">
          X =
        </Typography>
        {props.scores.length > 0 &&
          props.scores.map((item: any, index: any) => {
            return (
              <input
                key={index}
                className={
                  item.type === "constant"
                    ? "const-input"
                    : item.type === "question"
                    ? "question-input"
                    : "operator-input"
                }
                type="text"
                value={
                  item.type === "question"
                    ? props.questions.find(
                        (ques) => ques.questionId === item.value
                      )?.questionNo
                    : item.value !== "*"
                    ? item.value
                    : calcButtons[5].multiLabel
                }
                onClick={() => props.handleEditExp(item)}
                onChange={(e) => handleChange(e.target.value, item)}
              />
            );
          })}
      </Box>
      <Box sx={scoreCalculatorButtonWrapperStyle}>
        {calcButtons.map((item: any, index: number) => {
          return (
            <Box
              key={index}
              sx={{
                background: `${item.classValue}`,
                ...scoreCalculatorButtonStyle,
              }}
              onClick={() => props.handleExp(item)}
            >
              {item.label}
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default ScoreCalculator;

import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Divider, Typography } from "@mui/material";
import { sleepFn } from "../../../../utils/delay";
import { NoDataContainer } from "../../../Common/styles/table";

interface dataProps {
  name: string;
  value: string;
  status: string;
}

const BloodGlucoseMetrics = () => {
  const [glucoseData, setGlucoseData] = useState<dataProps[]>([]);
  const [loader, setLoader] = useState<boolean>(false);

  useEffect(() => {
    const fetchglucoseData = async () => {
      try {
        setLoader(true);

        await sleepFn(1000);
        setGlucoseData([
          {
            name: "Glucose Variability",
            value: "20 mg/dL",
            status: "High",
          },
          {
            name: "Glucose Osialliation",
            value: "22 mg/dL",
            status: "Optimal",
          },
          {
            name: "Glucose variability",
            value: "126 mg/dL",
            status: "High",
          },
          {
            name: "Monitoring Fasting Glucose",
            value: "133 mg/dL",
            status: "Very High",
          },
          {
            name: "5 Day Glucose (DG)",
            value: "110 mg/dL",
            status: "Optimal",
          },
          {
            name: "10 Day Glucose (DG)",
            value: "104 mg/dL",
            status: "Low",
          },
        ]);

        setLoader(false);
      } catch (error) {
        setLoader(false);
      }
    };

    fetchglucoseData();
  }, [setGlucoseData]);

  return (
    <Box sx={{ bgcolor: "white", borderRadius: "12px" }}>
      <Box p={"24px 16px"}>
        <Typography fontWeight={"medium"} fontSize={"22px"} color="#355962">
          Blood Glucose Insights (mmHg)
        </Typography>
      </Box>
      {!loader ? (
        <>
          {glucoseData.length > 0 ? (
            glucoseData.map((data: dataProps) => {
              return (
                <Box key={data.name}>
                  <Divider sx={{ color: "#E5E7EB" }} />
                  <Box p={"16px"}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: { xs: "100%", md: "420px" },
                      }}
                    >
                      <Box>
                        <Typography
                          sx={{ fontSize: "18px", fontWeight: "600" }}
                        >
                          {data?.name}
                        </Typography>
                        <Typography
                          sx={{
                            color: "#637E85",
                            fontSize: "14px",
                            fontWeight: "600",
                          }}
                        >
                          {data?.value}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          bgcolor:
                            data.status === "High"
                              ? "#FDF3E5"
                              : data.status === "Optimal"
                              ? "#EEF6E5"
                              : data.status === "Very High"
                              ? "#FBEFEC"
                              : "#FFFF99",
                          borderRadius: "20px",
                          padding: "1px 14px",
                        }}
                      >
                        <Typography
                          sx={{
                            color:
                              data.status === "High"
                                ? "#EB8900"
                                : data.status === "Optimal"
                                ? "#54A600"
                                : data.status === "Very High"
                                ? "#DA6246"
                                : "#FFC300",
                            fontWeight: "500",
                            fontSize: "15px",
                          }}
                        >
                          {data?.status}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              );
            })
          ) : (
            <NoDataContainer>
              <Typography variant="body1" color="gray">
                No Data
              </Typography>
            </NoDataContainer>
          )}
        </>
      ) : (
        <Box>
          <Divider sx={{ color: "#E5E7EB" }} />
          <Box sx={{ display: "flex", justifyContent: "center", p: 2 }}>
            <CircularProgress />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default BloodGlucoseMetrics;

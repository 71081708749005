import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";

import http from "../../utils/http";
import { errorToastMessage } from "../../utils/toast";
import { AuthLayout, SignInWrapper } from "./auth.style";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const VerifyEmail = () => {
  const [verifying, setVerifying] = useState("pending");
  const tokenId = useQuery().get("token");
  const emailId = useQuery().get("email");
  const navigate = useNavigate();

  useEffect(() => {
    const verifyData = async () => {
      try {
        const body = {
          email: emailId,
          emailVerifyToken: tokenId,
        };
        await http.post("/auth/verify_email", body);
        setVerifying("completed");
        setTimeout(() => {
          navigate("/auth/login");
        }, 5000);
      } catch (err) {
        errorToastMessage(err as Error);
        setVerifying("error");
      }
    };
    if (emailId && tokenId) {
      verifyData();
    }
  }, [setVerifying, emailId, tokenId]);

  return (
    <Box sx={AuthLayout}>
      <Box sx={SignInWrapper}>
        <Typography fontSize={24} fontWeight="medium">
          {verifying === "pending" && "Verifying your email address"}
          {verifying === "completed" && "Your email address has been verified"}
          {verifying === "error" &&
            "There was a problem verifying your email. Please try again"}
        </Typography>
      </Box>
    </Box>
  );
};

export default VerifyEmail;

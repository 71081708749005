import { SxProps } from "@mui/material";
import { rowFlexStyleAlignCenter } from "./flex";

export const badge: SxProps = {
  borderRadius: "8px",
  px: 1.125,
  width: "fit-content",
  textTransform: "capitalize",
  alignItems: "center",
  typography: "subtitle1",
  fontWeight: "regular",
  ...rowFlexStyleAlignCenter,
};

export const greenBadge: SxProps = {
  ...badge,
  color: "badge.greenText",
  backgroundColor: "badge.greenBg",
};

export const redBadge: SxProps = {
  ...badge,
  color: "#ffffff",
  backgroundColor: "badge.redBg",
};

export const orangeBadge: SxProps = {
  ...badge,
  color: "badge.orangeText",
  backgroundColor: "badge.orangeBg",
};

import { Box, CircularProgress } from "@mui/material";
import {
  questionListContentStyle,
  questionListHeaderStyle,
  questionListItemNoStyle,
  questionListItemStyle,
  questionListStyle,
} from "./scores.style";

type Props = {
  name: any;
  handleExp: Function;
  questionLoading: boolean;
  questions: any[];
};

const ListScoreQuestions: React.FC<Props> = ({
  name,
  handleExp,
  questions,
  questionLoading,
}) => {
  return (
    <Box sx={questionListStyle}>
      <Box sx={questionListHeaderStyle}>{`${name} questions`}</Box>
      <Box sx={questionListContentStyle}>
        {questionLoading ? (
          <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
            <CircularProgress />
          </Box>
        ) : (
          questions.length > 0 &&
          questions.map((item: any) => {
            return (
              <Box
                sx={questionListItemStyle}
                key={item.questionId}
                onClick={() => handleExp(item)}
              >
                <Box sx={questionListItemNoStyle}>{item.questionNo}</Box>
                <Box>{item.title}</Box>
              </Box>
            );
          })
        )}
      </Box>
    </Box>
  );
};

export default ListScoreQuestions;

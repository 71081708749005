import { AxiosResponse } from "axios";

import { pageSize } from "../../Components/Common/styles/table";
import http from "../../utils/http";
import { errorToastMessage } from "../../utils/toast";
import {
  setParticipantsLoader,
  setParticipantsCount,
  setParticipantsDetails,
  setParticipantsPage,
} from "../reducers/participantsSlice";
import { AppThunk } from "../store";

export const fetchParticipantsList =
  (
    page: number,
    type: string,
    filterId: string,
    searchText: string
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setParticipantsLoader(true));
      let url = `/participants?page=${
        page + 1
      }&size=${pageSize}&status=${type}`;
      if (filterId) {
        url += `&doctorId=${filterId}`;
      }
      if (type === "unregistered") {
        url = `/participants/import-study-numbers?page=${
          page + 1
        }&size=${pageSize}`;
      }
      if (searchText) {
        url += `&search=${searchText}`;
      }
      const res: AxiosResponse = await http.get(url);
      const data = res.data?.data;
      if (
        type !== "unregistered" &&
        data?.users?.length === 0 &&
        data?.count > 0 &&
        page > 0
      ) {
        dispatch(
          setParticipantsPage({
            page: 0,
            pageSize,
          })
        );
        return;
      }
      if (
        type === "unregistered" &&
        data?.rows?.length === 0 &&
        data?.count > 0 &&
        page > 0
      ) {
        dispatch(
          setParticipantsPage({
            page: 0,
            pageSize,
          })
        );
        return;
      }

      const newList = (data?.users || data?.rows).map((user: any) => {
        return {
          id: user?.id,
          studyNumber: type !== "unregistered" ? user?.code : user?.studyNumber,
          doctor: user?.doctor?.doctor?.code || "-",
          type:
            user?.participant?.isSelf === null
              ? "-"
              : user?.participant?.isSelf
              ? "Patient"
              : "Caregiver",
          status: user?.status,
          nickname: user?.participant?.nickname || "-",
          email: user?.email || "-",
        };
      });

      dispatch(setParticipantsDetails(newList));
      dispatch(setParticipantsCount(data?.count));
      dispatch(setParticipantsLoader(false));
    } catch (err) {
      errorToastMessage(err as Error);
      dispatch(setParticipantsLoader(false));
    }
  };

import { SxProps } from "@mui/material";

export const CalendarWrapper: SxProps = {
  paddingTop: "24px",
  overflow: "auto",
  height: "calc(100% - 100px)",
};

export const SearchInput: SxProps = {
  width: "300px",
};

export const InviteeContainer: SxProps = {
  borderRadius: "8px",
  bgcolor: "#E3F1F4",
  p: "8px 12px",
};

export const ButtonContainer: SxProps = {
  mt: 3.5,
  display: "flex",
};

export const LoaderContainer: SxProps = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  mt: 5,
};

import { ExpandMore, CheckCircle, CircleOutlined } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  CircularProgress,
  IconButton,
  SxProps,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { NoDataContainer } from "../../../Common/styles/table";
import { errorToastMessage } from "../../../../utils/toast";
import { sleepFn } from "../../../../utils/delay";
import { DateTime } from "luxon";

interface MedicationMetrics {
  dateLabel: string;
  dayLabel: string;
  log: Array<{
    label: string;
    meds: Array<{
      id: string;
      label: string;
      dose: string;
      taken: boolean;
    }>;
  }>;
}

const medBox: SxProps = {
  border: "1px solid #E0E3EB",
  p: 2,
  flex: 1,
  minWidth: "1px",
  borderRadius: "12px",
  minHeight: "500px",
};

const logBox: SxProps = {
  display: "flex",
  alignItems: "center",
  p: 2,
  borderRadius: "12px",
  backgroundColor: "#F3F4F6",
  height: "56px",
  mb: 2,
};

const takenLog: SxProps = {
  ...logBox,
  backgroundColor: "#DEF7EC",
};

const Medication = () => {
  const [loader, setLoader] = useState(true);
  const [data, setData] = useState<MedicationMetrics[]>([]);
  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange =
    (panel: string) => (_: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        setLoader(true);
        await sleepFn(1000);
        let end = DateTime.now().endOf("day");
        const newData = [];
        for (let i = 0; i < 7; i++) {
          newData.push({
            dayLabel: end.toFormat("cccc"),
            dateLabel: end.toFormat("dd LLLL yyyy"),
            log: [
              {
                label: "Morning",
                meds: [
                  {
                    id: "1",
                    label: "Metformin",
                    dose: "100mg",
                    taken: true,
                  },
                  {
                    id: "2",
                    label: "Alogliptin",
                    dose: "50mg",
                    taken: false,
                  },
                ],
              },
              { label: "Afternoon", meds: [] },
              { label: "Night", meds: [] },
            ],
          });
          end = end.plus({
            day: -1,
          });
        }
        setData(newData.reverse());
        setLoader(false);
      } catch (err) {
        setLoader(false);
        errorToastMessage(err as Error);
      }
    };
    fetchDetails();
  }, [setLoader]);

  return loader ? (
    <Box
      sx={{ display: "flex", justifyContent: "center", width: "100%", mt: 2 }}
    >
      <CircularProgress size={25} />
    </Box>
  ) : data.length > 0 ? (
    <>
      {data.map((item) => {
        return (
          <Accordion
            key={item.dateLabel}
            TransitionProps={{ unmountOnExit: true }}
            expanded={expanded === `panel${item.dateLabel}`}
            onChange={handleChange(`panel${item.dateLabel}`)}
            sx={{
              boxShadow: "none",
              border: "1px solid #E5E7EB",
              borderRadius: "8px !important",
              mb: 2.5,
            }}
          >
            <AccordionSummary
              expandIcon={
                <IconButton>
                  <ExpandMore sx={{ fontSize: 24 }} />
                </IconButton>
              }
              sx={{
                ".Mui-expanded": {
                  m: "12px 0px !important",
                },
              }}
            >
              <Box
                sx={{
                  py: 1.5,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Box>
                  <Typography
                    fontWeight={600}
                    fontSize={"22px"}
                    color={"#355962"}
                    mb={0.5}
                  >
                    {item?.dateLabel}
                  </Typography>
                  <Typography
                    fontWeight={500}
                    fontSize={"16px"}
                    color={"#355962"}
                    sx={{ textTransform: "capitalize" }}
                  >
                    {item?.dayLabel}
                  </Typography>
                </Box>
              </Box>
            </AccordionSummary>
            <AccordionDetails sx={{ p: 2, display: "flex", gap: "16px" }}>
              {item.log.map((logEntry) => {
                return (
                  <Box key={logEntry.label} sx={medBox}>
                    <Typography
                      fontWeight={600}
                      fontSize="20px"
                      color="#355962"
                      mb={3}
                    >
                      {logEntry.label}
                    </Typography>
                    {logEntry.meds.length > 0 ? (
                      logEntry.meds.map((med) => {
                        return (
                          <Box key={med.id} sx={med.taken ? takenLog : logBox}>
                            <Checkbox
                              icon={<CircleOutlined />}
                              checkedIcon={<CheckCircle htmlColor="#0E9F6E" />}
                              checked={med.taken}
                            />
                            <Typography
                              color="#111928"
                              fontSize={"15px"}
                              fontWeight={600}
                            >
                              {med.label}
                            </Typography>
                            <Typography
                              fontSize={"13px"}
                              sx={{ marginLeft: "auto" }}
                              color={"#6B7280"}
                              fontWeight={500}
                            >
                              {med.dose}
                            </Typography>
                          </Box>
                        );
                      })
                    ) : (
                      <Typography
                        variant="body1"
                        color="gray"
                        textAlign={"center"}
                      >
                        No Data
                      </Typography>
                    )}
                  </Box>
                );
              })}
            </AccordionDetails>
          </Accordion>
        );
      })}
    </>
  ) : (
    <NoDataContainer>
      <Typography variant="body1" color="gray">
        No Data
      </Typography>
    </NoDataContainer>
  );
};

export default Medication;

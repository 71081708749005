import { useEffect, useState } from "react";
import { ResponsiveLineCanvas } from "@nivo/line";
import { DateTime } from "luxon";
import { Box } from "@mui/material";
import { errorToastMessage } from "../../../utils/toast";
import { ResponsiveBar } from "@nivo/bar";
import { AssessmentIcon, TitleIcon, VideoIcon } from "../../CMS/Icons";

const ChartIcon = ({ type }: any) => {
  switch (type) {
    case "sleep":
      return <VideoIcon />;
    case "lunch":
      return <AssessmentIcon />;
    case "steps":
      return <TitleIcon />;
    default:
      return <TitleIcon />;
  }
};

const PFBData = [
  {
    blood_glucose_mg_per_dL: 80,
    glucose_level_flag: 2,
    timestamp: "2023-09-20T21:24:15.023836+00:00",
    trend_arrow: 0,
    type: "lunch",
    activity: true,
  },
  {
    blood_glucose_mg_per_dL: 90,
    glucose_level_flag: 0,
    timestamp: "2023-09-20T21:39:15.023836+00:00",
    trend_arrow: 0,
    type: "sleep",
  },
  {
    blood_glucose_mg_per_dL: 155,
    glucose_level_flag: 1,
    timestamp: "2023-09-20T21:54:15.023836+00:00",
    trend_arrow: 0,
    type: "breakfast",
  },
  {
    blood_glucose_mg_per_dL: 180,
    glucose_level_flag: 2,
    timestamp: "2023-09-20T22:09:15.023836+00:00",
    trend_arrow: 0,
    type: "breakfast",
    activity: true,
  },
  {
    blood_glucose_mg_per_dL: 195,
    glucose_level_flag: 1,
    timestamp: "2023-09-20T22:24:15.023836+00:00",
    trend_arrow: 0,
    type: "breakfast",
    activity: true,
  },
  {
    blood_glucose_mg_per_dL: 120,
    glucose_level_flag: 2,
    timestamp: "2023-09-20T22:39:15.023836+00:00",
    trend_arrow: 0,
    type: "breakfast",
  },
  {
    blood_glucose_mg_per_dL: 90,
    glucose_level_flag: 0,
    timestamp: "2023-09-20T22:54:15.023836+00:00",
    trend_arrow: 0,
    type: "steps",
  },
  {
    blood_glucose_mg_per_dL: 81,
    glucose_level_flag: 1,
    timestamp: "2023-09-20T23:09:15.023836+00:00",
    trend_arrow: 0,
    type: "sleep",
  },
  {
    blood_glucose_mg_per_dL: 155,
    glucose_level_flag: 2,
    timestamp: "2023-09-20T23:24:15.023836+00:00",
    trend_arrow: 0,
    type: "sleep",
  },
  {
    blood_glucose_mg_per_dL: 80,
    glucose_level_flag: 2,
    timestamp: "2023-09-20T23:39:15.023836+00:00",
    trend_arrow: 0,
    type: "sleep",
  },
  {
    blood_glucose_mg_per_dL: 90,
    glucose_level_flag: 0,
    timestamp: "2023-09-20T23:54:15.023836+00:00",
    trend_arrow: 0,
    type: "sleep",
  },
  {
    blood_glucose_mg_per_dL: 155,
    glucose_level_flag: 1,
    timestamp: "2023-09-21T00:09:15.023836+00:00",
    trend_arrow: 0,
    type: "lunch",
  },
  {
    blood_glucose_mg_per_dL: 180,
    glucose_level_flag: 2,
    timestamp: "2023-09-21T00:24:15.023836+00:00",
    trend_arrow: 0,
    type: "lunch",
  },
  {
    blood_glucose_mg_per_dL: 195,
    glucose_level_flag: 1,
    timestamp: "2023-09-21T00:39:15.023836+00:00",
    trend_arrow: 0,
    type: "lunch",
  },
];

const PFB = () => {
  const [data, setData] = useState<any>([]);
  const [barData, setBarData] = useState<any>([]);

  useEffect(() => {
    try {
      const data: any = PFBData.filter((item) => item.activity).map((item) => ({
        x: DateTime.fromISO(item?.timestamp).toFormat("hh:mm a"),
        v: 140,
        type: item?.type,
      }));
      const lineData: any = PFBData.map((item) => ({
        x: DateTime.fromISO(item?.timestamp).toFormat("hh:mm"),
        y: item?.blood_glucose_mg_per_dL,
        type: item?.type,
      }));
      setBarData(data);
      setData([
        {
          id: "linechart",
          data: lineData,
        },
      ]);
    } catch (err) {
      errorToastMessage(err as Error);
    }
  }, []);

  const ScatterCircle = ({ bars, xScale, yScale }: any) => {
    return (
      <>
        {bars.map((bar: any) => {
          console.log(bar);
          const x = xScale(bar.data.data?.x) + bar.width / 4;
          const y = yScale(bar.data.data?.v + 28);
          return (
            <g
              key={`point-${bar.data.index}`}
              transform={`translate(${x}, ${y})`}
            >
              <ChartIcon type={bar.data.data?.type} />
            </g>
          );
        })}
      </>
    );
  };

  // const Line = ({ bars, xScale, yScale }: any) => {
  //   const lineGenerator: any = line()
  //     .x((bar: any) => xScale(bar.data.data.x) + bar.width / 2)
  //     .y((bar: any) => yScale(bar.data.data.v + 0.2))
  //     .curve(curveCatmullRom.alpha(0.5));

  //   return (
  //     <>
  //       {bars.map((bar: any, index: number) => {
  //         if (index < bars.length - 1) {
  //           const lineColor =
  //             bars[index + 1].data.data.v <= 90 ? "#38A18F" : "#EF8E34";
  //           return (
  //             <path
  //               key={`line-segment-${index}`}
  //               d={lineGenerator([bars[index], bars[index + 1]])}
  //               fill="none"
  //               stroke={lineColor}
  //               style={{ pointerEvents: "none", strokeWidth: "3.5" }}
  //             />
  //           );
  //         }
  //         return null;
  //       })}
  //     </>
  //   );
  // };

  const CustomLineChart = ({
    lineGenerator,
    series,
    ctx,
    lineWidth,
    innerWidth,
  }: any) => {
    lineGenerator.context(ctx);

    function normalize(points: any, max: any) {
      return points.map((point: any) => {
        return point / max;
      });
    }
    const points = series[0].data.map((d: any) => d.position.x);
    const max = series[0].data[series[0].data.length - 1].position.x;
    const normalizedPoints = normalize(points, max);
    const gradient: any = ctx.createLinearGradient(0, 0, innerWidth, 0);

    series[0].data.forEach((d: any, index: number) => {
      const color = d.data.y <= 90 ? "#38A18F" : "#EF8E34";
      return gradient.addColorStop(normalizedPoints[index], color);
    });
    ctx.strokeStyle = gradient;
    ctx.lineWidth = lineWidth;
    ctx.beginPath();
    lineGenerator(series[0].data.map((d: any) => d.position));
    ctx.stroke();
  };

  return (
    <Box sx={{ height: "380px", width: "80%", p: 2, position: "relative" }}>
      <Box sx={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0 }}>
        <ResponsiveBar
          data={barData}
          keys={["v"]}
          colors={"rgba(0,0,0,0.1)"}
          borderRadius={10}
          indexBy="x"
          margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
          padding={0.3}
          indexScale={{ type: "band", round: true }}
          layers={["grid", "bars", ScatterCircle]}
          theme={{
            fontSize: 11,
            grid: {
              line: {
                strokeDasharray: "6 6",
              },
            },
          }}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 10,
            tickPadding: 10,
            tickRotation: 0,
          }}
          axisLeft={{
            tickSize: 0,
            tickPadding: 10,
            tickRotation: 0,
          }}
          enableGridX={false}
          enableGridY={true}
          gridYValues={[80, 100]}
          enableLabel={false}
          isInteractive={false}
        />
      </Box>
      <Box sx={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0 }}>
        <ResponsiveLineCanvas
          data={data}
          margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
          xFormat={"time:%H:%M"}
          xScale={{
            format: "%H:%M",
            precision: "minute",
            type: "time",
            useUTC: false,
          }}
          yScale={{
            type: "linear",
          }}
          layers={[
            "axes",
            CustomLineChart,
            // ({ series, ctx }: any) => {
            //   const barWidth = 20;
            //   const barColor = "#8884d8";

            //   ctx.fillStyle = barColor;

            //   series[0].data.forEach((d: any) => {
            //     const x = d.position.x - barWidth / 2;
            //     const y = d.position.y;
            //     const height = ctx.canvas.height - y;

            //     ctx.fillRect(x, y, barWidth, height);
            //   });
            // },
          ]}
          lineWidth={3.5}
          yFormat=">-.2f"
          curve="monotoneX"
          theme={{
            fontSize: 11,
          }}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 10,
            tickPadding: 10,
            tickRotation: 0,
            format: (d) => {
              const date = new Date(d);
              let hours = date.getHours();
              hours = hours % 12;
              hours = hours ? hours : 12;
              const minutes = date.getMinutes();
              let ampm = hours >= 12 ? "PM" : "AM";

              if (hours % 2 === 0 && minutes === 0) {
                return `${hours} ${ampm}`;
              }
              if (minutes === 0 || minutes === 30) {
                return "";
              }

              return null;
            },
            tickValues: "every 30 minutes",
          }}
          axisLeft={{
            tickSize: 0,
            tickPadding: 10,
            tickRotation: 0,
          }}
          enableGridX={false}
          enableGridY={true}
          gridYValues={[80, 100]}
          isInteractive={false}
        />
      </Box>
    </Box>
  );
};

export default PFB;

import { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Modal,
  Typography,
} from "@mui/material";
import { DateTime } from "luxon";
import { AxiosResponse } from "axios";
import http from "../../utils/http";
import { appointmentMap } from "../../utils/appointment";
import { errorToastMessage } from "../../utils/toast";
import { ButtonContainer, InviteeContainer, LoaderContainer } from "./styles";
import { ModalBaseStyles, ModalHeader } from "../Common/styles/modal";

const ViewAppointmentModal: React.FC<any> = ({ closeModal, id }: any) => {
  const [loader, setLoader] = useState(false);
  const [appointment, setAppointment] = useState<any>({});

  useEffect(() => {
    const fetchAdmin = async (id: string) => {
      try {
        setLoader(true);
        const response: AxiosResponse = await http.get(`/ap/${id}`);
        const event = response?.data?.data;

        const startTime = DateTime.fromISO(event.scheduledStartTime).toFormat(
          "hh:mm a"
        );
        const endTime = DateTime.fromISO(event.scheduledEndTime).toFormat(
          "hh:mm a"
        );
        setAppointment({
          id: event?.id,
          title: event?.title || "",
          inviters: event?.inviters || [],
          invitees: event?.invitees || [],
          joinLink: event?.joinLink || "",
          type: event?.type,
          startTime,
          endTime,
        });
        setLoader(false);
      } catch (err) {
        setLoader(false);
        errorToastMessage(err as Error);
      }
    };
    fetchAdmin(id);
  }, [setAppointment, id]);

  return (
    <Modal open={true} onClose={closeModal}>
      <Box sx={ModalBaseStyles}>
        <ModalHeader title={"View Appointment"} onCloseClick={closeModal} />
        {!loader ? (
          <Box>
            <Typography
              variant="body1"
              fontWeight="regular"
              mb={1.25}
              color="#6B7280"
            >
              Appointment Title
            </Typography>
            <Typography
              variant="subtitle1"
              fontWeight="regular"
              sx={{
                textTransform: "capitalize",
                mb: 2,
              }}
            >
              {appointment?.title}
            </Typography>
            <Typography
              variant="body1"
              fontWeight="regular"
              mb={1.25}
              color="#6B7280"
            >
              Appointment Type
            </Typography>
            <Typography
              variant="subtitle1"
              fontWeight="regular"
              sx={{
                textTransform: "capitalize",
                mb: 2,
              }}
            >
              {appointmentMap[appointment?.type]?.label || "-"}
            </Typography>
            <Box sx={{ display: "flex", gap: "1rem" }}>
              <Box>
                <Typography
                  variant="body1"
                  fontWeight="regular"
                  mb={1.25}
                  color="#6B7280"
                >
                  Start Time
                </Typography>
                <Typography variant="subtitle1" fontWeight="regular" mb={2}>
                  {appointment?.startTime}
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant="body1"
                  fontWeight="regular"
                  mb={1.25}
                  color="#6B7280"
                >
                  End Time
                </Typography>
                <Typography variant="subtitle1" fontWeight="regular" mb={2}>
                  {appointment?.endTime}
                </Typography>
              </Box>
            </Box>
            <Typography
              variant="body1"
              fontWeight="regular"
              mb={1.25}
              color="#6B7280"
            >
              Participants
            </Typography>
            {appointment?.invitees?.length > 0 && (
              <Box
                sx={{
                  display: "flex",
                  gap: 1.25,
                  mb: 2,
                }}
              >
                {appointment?.invitees?.map((user: any) => (
                  <Box key={user?.invitee?.id} sx={InviteeContainer}>
                    <Typography variant="body1" fontWeight="regular">
                      {user?.invitee?.participant?.subjectId +
                        (user?.invitee?.nickname
                          ? `(${user?.invitee?.nickname})`
                          : "")}
                    </Typography>
                  </Box>
                ))}
              </Box>
            )}
            {appointment?.inviters?.length > 0 && (
              <Box sx={{ mb: 2 }}>
                <Typography
                  variant="body1"
                  fontWeight="regular"
                  mb={1.25}
                  color="#6B7280"
                >
                  Inviters
                </Typography>
                {appointment.inviters?.map((user: any, index: number) => {
                  return (
                    <Typography
                      component={"span"}
                      key={user?.inviter?.id}
                      variant="subtitle1"
                      fontWeight="regular"
                      sx={{
                        textTransform: "capitalize",
                      }}
                    >
                      {user?.inviter?.firstName +
                        " " +
                        user?.inviter?.lastName +
                        (index === appointment.inviters?.length - 1
                          ? ""
                          : ", ")}
                    </Typography>
                  );
                })}
              </Box>
            )}
            {appointment?.joinLink && (
              <>
                <Typography
                  variant="body1"
                  fontWeight="regular"
                  mb={1.25}
                  color="#6B7280"
                >
                  Link
                </Typography>
                <Typography variant="subtitle1" fontWeight="regular" mb={2}>
                  {appointment?.joinLink}
                </Typography>
              </>
            )}

            <Box sx={ButtonContainer}>
              <Button variant="outlined" onClick={closeModal}>
                Close
              </Button>
            </Box>
          </Box>
        ) : (
          <Box sx={LoaderContainer}>
            <CircularProgress />
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default ViewAppointmentModal;

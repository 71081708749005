import React from "react";
import HealthHeader from "./HealthHeader";
import HealthTabs from "./HealthTabs";
import HealthDateSelector from "./HealthDateSelector";
import HealthGraph from "./HealthGraph";
import HealthMetrics from "./HealthMetrics";

const Health: React.FC = () => {
  return (
    <>
      <HealthHeader />
      <HealthTabs />
      <HealthDateSelector />
      <HealthGraph />
      <HealthMetrics />
    </>
  );
};

export default Health;

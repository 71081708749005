import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import { ResponsiveBar } from "@nivo/bar";
import { ChartContainer } from "../dashboard.style";
import { CustomTooltip } from "../../Common/UI/Tooltip";
import { errorToastMessage } from "../../../utils/toast";
import {
  userActivityAgeData,
  userActivityGenderData,
} from "../dashboardDetails";
import { LoadingContainer } from "../../CMS/cms.style";

const calculateTickValue = (data: any) => {
  const maxNo = Math.max(
    ...data.map((v: any) => v?.inactive + v?.active + v?.newlyInactive)
  );
  const arr = Array.from(
    { length: Math.floor(maxNo / 20) },
    (_, i) => (i + 1) * 20
  );
  return maxNo > 20 ? arr.length + 1 : arr.length;
};

const UserActivityTab = () => {
  const [loading, setLoading] = useState(false);
  const [activityByAge, setActivityByAge] = useState<any[]>([]);
  const [activityByGender, setActivityByGender] = useState<any[]>([]);

  useEffect(() => {
    const fetchActivityData = async () => {
      try {
        setLoading(true);
        // const res: AxiosResponse = await http.get("/dashboard");
        setActivityByAge(userActivityAgeData);
        setActivityByGender(userActivityGenderData);
        setLoading(false);
      } catch (err) {
        errorToastMessage(err as Error);
        setLoading(false);
      }
    };

    fetchActivityData();
  }, []);

  return !loading ? (
    <Box sx={{ display: "flex", gap: 2, overflow: "hidden" }}>
      <Box sx={{ ...ChartContainer, width: "80%" }}>
        <Box sx={{ pt: 2, pl: 2 }}>
          <Typography variant="subtitle1" fontWeight="medium" color="#637E85">
            User Activity By Age
          </Typography>
        </Box>
        <ResponsiveBar
          data={activityByAge}
          keys={["inactive", "newlyInactive", "active"]}
          colors={["#69AEC9", "#4D93C3", "#8ACA9F"]}
          indexBy="label"
          margin={{ top: 50, right: 40, bottom: 130, left: 40 }}
          padding={0.75}
          valueScale={{ type: "linear" }}
          indexScale={{ type: "band", round: true }}
          theme={{
            fontSize: 14,
            axis: {
              ticks: {
                text: {
                  fontSize: 14,
                  fontWeight: 600,
                  fill: "#637E85",
                },
              },
            },
          }}
          gridYValues={calculateTickValue(activityByAge) || undefined}
          axisTop={null}
          axisRight={null}
          axisLeft={null}
          axisBottom={{
            tickSize: 0,
            tickPadding: 16,
            tickRotation: 0,
          }}
          enableLabel={false}
          legends={[
            {
              dataFrom: "keys",
              data: [
                {
                  id: 0,
                  label: "Current Active Users",
                  color: "#8ACA9F",
                },
                {
                  id: 1,
                  label: "Newly Inactive Users",
                  color: "#4D93C3",
                },
                {
                  id: 2,
                  label: "Not Active 2 Periods",
                  color: "#69AEC9",
                },
              ],
              anchor: "bottom",
              direction: "row",
              justify: false,
              translateY: 75,
              itemsSpacing: 2,
              itemWidth: 180,
              itemHeight: 20,
              itemDirection: "left-to-right",
              itemTextColor: "#355962",
              symbolSize: 16,
              symbolShape: "circle",
            },
          ]}
          tooltip={({ value, color }: any) => {
            const label =
              color === "#69AEC9"
                ? "Not Active 2 Periods"
                : color === "#8ACA9F"
                ? "Current Active Users"
                : "Newly Inactive Users";
            return <CustomTooltip value={value} color={color} label={label} />;
          }}
        />
      </Box>
      <Box sx={{ ...ChartContainer, width: "20%", minWidth: 220 }}>
        <Box sx={{ pt: 2, pl: 2 }}>
          <Typography variant="subtitle1" fontWeight="medium" color="#637E85">
            User Activity By Gender
          </Typography>
        </Box>
        <ResponsiveBar
          data={activityByGender}
          keys={["inactive", "newlyInactive", "active"]}
          colors={["#69AEC9", "#4D93C3", "#8ACA9F"]}
          indexBy="label"
          margin={{ top: 50, right: 25, bottom: 130, left: 25 }}
          padding={0.65}
          valueScale={{ type: "linear" }}
          indexScale={{ type: "band", round: true }}
          theme={{
            fontSize: 14,
            axis: {
              ticks: {
                text: {
                  fontSize: 14,
                  fontWeight: 600,
                  fill: "#637E85",
                },
              },
            },
          }}
          gridYValues={calculateTickValue(activityByGender) || undefined}
          axisTop={null}
          axisRight={null}
          axisLeft={null}
          axisBottom={{
            tickSize: 0,
            tickPadding: 16,
            tickRotation: 0,
          }}
          enableLabel={false}
          tooltip={({ value, color }: any) => {
            const label =
              color === "#69AEC9"
                ? "Not Active 2 Periods"
                : color === "#8ACA9F"
                ? "Current Active Users"
                : "Newly Inactive Users";
            return <CustomTooltip value={value} color={color} label={label} />;
          }}
        />
      </Box>
    </Box>
  ) : (
    <Box sx={LoadingContainer}>
      <CircularProgress />
    </Box>
  );
};

export default UserActivityTab;

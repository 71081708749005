import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  IconButton,
  TextField,
  FormControl,
  FormLabel,
  MenuItem,
  Select,
  Backdrop,
  CircularProgress,
  Chip,
  InputAdornment,
  Checkbox,
  FormHelperText,
} from "@mui/material";
import { AxiosResponse } from "axios";
import { Add, ArrowBack, Close } from "@mui/icons-material";
import * as yup from "yup";
import { FieldArray, Form, Formik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import {
  HeaderLeftContent,
  HeaderRightContent,
  StyledHeader,
} from "../../Common/styles/header";
import { InputWrapper, LabelStyle } from "../../Common/styles/form";
import { errorToastMessage, toastMessage } from "../../../utils/toast";
import { uploadFile } from "../../../utils/upload";
import { BuilderWrapper, CardStyle, ChipStyle } from "./exercise.style";
import { LoadingContainer, UploadWrapper } from "../../CMS/cms.style";
import { DeleteIcon, VideoUploadIcon } from "../../CMS/Icons";
import http from "../../../utils/http";

const UploadItem: React.FC<any> = ({ video, setFieldValue }) => {
  const [loading, setLoading] = useState(false);

  const onDrop = useCallback(
    async (acceptedFiles: any) => {
      try {
        const file = acceptedFiles?.[0];
        if (file) {
          if (file.size > 5 * 1024 * 1024) {
            toastMessage("warning", "File Size cannot be greater than 5 MB!");
            return;
          }
          setLoading(true);
          const url = await uploadFile(file, "questionnaire_image");
          setFieldValue("videoUrl", url);
          setLoading(false);
        }
      } catch (err) {
        setLoading(false);
        errorToastMessage(err as Error);
      }
    },
    [setFieldValue]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    accept: {
      "video/*": [],
    },
  });

  return (
    <>
      {loading && (
        <Backdrop
          open={true}
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <Box
        {...getRootProps({ className: "dropzone" })}
        sx={{ ...UploadWrapper, width: "376px", height: "242px" }}
      >
        <input {...getInputProps()} />
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {video ? (
            <Typography variant="subtitle1" fontWeight={"medium"}>
              File available. Drop Files to change
            </Typography>
          ) : (
            <>
              <VideoUploadIcon />
              <Typography
                variant="subtitle1"
                fontWeight={"medium"}
                ml={2}
                color="#6B7280"
              >
                Drop Files to upload
              </Typography>
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

const schema = yup.object().shape({
  name: yup.string().required("Exercise Name is Required"),
  muscleGroups: yup.array().min(1, "Atleast one Muscle group is Required"),
  videoUrl: yup.string().required("Video is Required"),
});

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const ExerciseBuilder = () => {
  const [loading, setLoading] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [groupsList] = useState<string[]>(["Core", "Legs"]);
  const [data, setData] = useState<any>(null);
  const navigate = useNavigate();
  const id = useQuery().get("id");

  useEffect(() => {
    const fetchExerciseData = async () => {
      try {
        setLoading(true);
        const res: AxiosResponse = await http.get(`exercise/${id}`);
        const newData = res.data?.data;
        const exerciseData = {
          name: newData?.name,
          instructions: newData?.instructions
            ? newData?.instructions.map((item: any) => ({ value: item?.value }))
            : null,
          muscleGroups: newData?.muscleGroups,
          videoUrl: newData?.resourceUrl,
        };
        setData(exerciseData);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        errorToastMessage(err as Error);
      }
    };

    if (id) {
      fetchExerciseData();
    }
  }, [id]);

  const submitHandler = async (values: any) => {
    try {
      setButtonLoader(true);

      if (!values.name.trim()) {
        throw new Error("Exercise Name cannot be empty");
      }

      if (!values.muscleGroups.length) {
        throw new Error("Atleast one Muscle group is Required");
      }

      const instructions = values.instructions.slice(0, -1);

      if (!instructions.length) {
        throw new Error("Atleast one Instruction is required");
      }

      if (instructions.some((instr: any) => instr.value.trim() === "")) {
        throw new Error("Instructions cannot be empty");
      }

      const body = {
        name: values?.name,
        instructions: instructions.map((instr: any, index: number) => ({
          position: index + 1,
          value: instr.value,
        })),
        muscleGroups: values?.muscleGroups,
        resourceUrl: values?.videoUrl,
      };
      let res: AxiosResponse;
      if (id) {
        res = await http.put(`/exercise/${id}`, body);
      } else {
        res = await http.post("/exercise", body);
      }

      toastMessage("success", res.data.message);
      setButtonLoader(false);
      navigate("/app/exercise");
    } catch (err) {
      setButtonLoader(false);
      errorToastMessage(err as Error);
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <Formik
      initialValues={{
        name: data?.name || "",
        instructions: data?.instructions
          ? [...data?.instructions, { value: "" }]
          : [
              {
                value: "",
              },
            ],
        muscleGroups: data?.muscleGroups || [],
        videoUrl: data?.videoUrl || "",
      }}
      validationSchema={schema}
      onSubmit={(values) => {
        submitHandler(values);
      }}
      enableReinitialize
    >
      {({ getFieldProps, setFieldValue, values, errors, touched }) => (
        <Form>
          <StyledHeader>
            <Box sx={{ ...HeaderLeftContent, gap: 1 }}>
              <IconButton onClick={handleBack}>
                <ArrowBack htmlColor="#637E85" />
              </IconButton>
              <Typography variant="h2">
                {data?.id ? "Edit" : "Add"} Exercise
              </Typography>
            </Box>
            <Box sx={HeaderRightContent}>
              {!buttonLoader ? (
                <>
                  <Button variant="outlined" onClick={handleBack}>
                    Cancel
                  </Button>
                  <Button type="submit" variant="contained">
                    Save
                  </Button>
                </>
              ) : (
                <Box>
                  <CircularProgress size={"2rem"} />
                </Box>
              )}
            </Box>
          </StyledHeader>
          {!loading ? (
            <Box sx={BuilderWrapper}>
              <Box sx={CardStyle}>
                <Typography
                  variant="subtitle2"
                  fontWeight="medium"
                  color="#355962"
                  mb={3}
                >
                  Workout Information
                </Typography>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="exercise-name">
                    Exercise Name
                  </FormLabel>
                  <TextField
                    placeholder="Exercise Name"
                    id="exercise-name"
                    {...getFieldProps("name")}
                    error={touched?.name && errors?.name ? true : false}
                    helperText={
                      touched?.name && errors?.name
                        ? (errors?.name as string)
                        : " "
                    }
                  />
                </FormControl>
              </Box>
              <Box sx={CardStyle}>
                <Typography
                  variant="subtitle2"
                  fontWeight="medium"
                  color="#355962"
                  mb={3}
                >
                  Instructions
                </Typography>
                <FieldArray name="instructions">
                  {({ remove, push }) => (
                    <>
                      {values?.instructions.map((_: any, index: number) => (
                        <Box
                          key={index}
                          sx={{
                            display: "flex",
                            mb: 2,
                            gap: 1,
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              border: "1px solid #E0E3EB",
                              borderRadius: "8px",
                              padding: "12px 16px",
                              width: "48px",
                            }}
                          >
                            {index + 1 !== values?.instructions.length && (
                              <Typography variant="subtitle1">
                                {index + 1}
                              </Typography>
                            )}
                          </Box>
                          <TextField
                            fullWidth
                            key={index}
                            {...getFieldProps(`instructions.${index}.value`)}
                            InputProps={{
                              startAdornment:
                                index + 1 <
                                values.instructions.length ? null : (
                                  <InputAdornment position="start">
                                    <IconButton
                                      onClick={() =>
                                        push({
                                          value: "",
                                        })
                                      }
                                    >
                                      <Add />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                            }}
                          />
                          <Box sx={{ width: "3%", alignSelf: "flex-end" }}>
                            {index + 1 !== values?.instructions.length && (
                              <IconButton onClick={() => remove(index)}>
                                <DeleteIcon />
                              </IconButton>
                            )}
                          </Box>
                        </Box>
                      ))}
                    </>
                  )}
                </FieldArray>
              </Box>
              <Box sx={CardStyle}>
                <Typography
                  variant="subtitle2"
                  fontWeight="medium"
                  color="#355962"
                  mb={3}
                >
                  Muscle Groups
                </Typography>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle}>Select Muscle Group</FormLabel>
                  <Select
                    multiple
                    displayEmpty
                    fullWidth
                    value={values.muscleGroups}
                    onChange={(e) => {
                      setFieldValue("muscleGroups", e.target.value);
                    }}
                    error={
                      touched?.muscleGroups && errors?.muscleGroups
                        ? true
                        : false
                    }
                    renderValue={() => {
                      return <></>;
                    }}
                    sx={{
                      maxWidth: "477px",
                    }}
                  >
                    {groupsList.map((item: any) => (
                      <MenuItem key={item} value={item}>
                        <Checkbox
                          checked={values.muscleGroups.indexOf(item) > -1}
                          size="small"
                        />
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                  {touched?.muscleGroups && errors?.muscleGroups && (
                    <FormHelperText error>
                      {errors?.muscleGroups as string}
                    </FormHelperText>
                  )}
                </FormControl>
                {values.muscleGroups.length > 0 && (
                  <Box sx={{ display: "flex", gap: 2, mt: 2.5 }}>
                    {values.muscleGroups.map((tag: any) => (
                      <Chip
                        label={tag}
                        key={tag}
                        sx={ChipStyle}
                        deleteIcon={
                          <Close htmlColor="#374151" fontSize="small" />
                        }
                        onDelete={() => {
                          const data = values.muscleGroups.filter(
                            (group: any) => group !== tag
                          );
                          setFieldValue("muscleGroups", data);
                        }}
                      />
                    ))}
                  </Box>
                )}
              </Box>
              <Box sx={CardStyle}>
                <Typography
                  variant="subtitle2"
                  fontWeight="medium"
                  color="#355962"
                  mb={3}
                >
                  Exercise Video
                </Typography>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle}>Upload Video</FormLabel>
                  <Box
                    sx={{ display: "flex", alignItems: "flex-start", gap: 1 }}
                  >
                    <UploadItem
                      setFieldValue={setFieldValue}
                      video={values.videoUrl}
                    />
                    {values.videoUrl && (
                      <IconButton onClick={() => setFieldValue("videoUrl", "")}>
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </Box>
                </FormControl>
                <FormLabel sx={LabelStyle}>Or</FormLabel>
                <FormControl sx={{ ...InputWrapper, mt: 2 }}>
                  <FormLabel sx={LabelStyle} htmlFor="video-url">
                    Paste Video URL here
                  </FormLabel>
                  <TextField
                    fullWidth
                    id="video-url"
                    {...getFieldProps("videoUrl")}
                    error={touched?.videoUrl && errors?.videoUrl ? true : false}
                    helperText={
                      touched?.videoUrl && errors?.videoUrl
                        ? (errors?.videoUrl as string)
                        : " "
                    }
                  />
                </FormControl>
              </Box>
            </Box>
          ) : (
            <Box sx={LoadingContainer}>
              <CircularProgress />
            </Box>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default ExerciseBuilder;

import { Box, Divider, Typography } from "@mui/material";
import { NavLink } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../Redux/hooks";
import {
  AdminIcon,
  Logo,
  ParticipantIcon,
  // MessagesIcon,
  HealthIcon,
  ContentIcon,
  DashboardIcon,
  MyAccountIcon,
  LogoutIcon,
  ExerciseIcon,
  MedicationIcon,
  AppointmentsIcon,
  QuestionIcon,
} from "../Common/assets/Sidebar";
import http from "../../utils/http";
import { resetState } from "../../Redux/actions/resetAction";

const Sidebar = () => {
  const { hasAdminAccess } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();

  const logout = async () => {
    try {
      await http.post("/auth/logout");
    } catch (err) {
      console.log(err);
    } finally {
      localStorage.clear();
      dispatch(resetState());
    }
  };

  return (
    <Box
      sx={{
        height: "100vh",
        width: "224px",
        boxShadow: " 0px 0px 12px 0px rgba(0, 0, 0, 0.08)",
        padding: "16px",
      }}
      className="sidebar"
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          borderBottom: 1,
          borderBottomColor: "#E0E3EB",
          paddingBottom: 2,
          margin: "20px 0px",
        }}
      >
        <Logo />
      </Box>

      {hasAdminAccess && (
        <NavLink
          to="/app/dashboard"
          title="Dashboard"
          className={({ isActive }) =>
            isActive ? "sider-menu active" : "sider-menu"
          }
        >
          <DashboardIcon />
          <Typography variant="subtitle1">Dashboard</Typography>
        </NavLink>
      )}
      {hasAdminAccess && (
        <NavLink
          title="Patients"
          to="/app/participants"
          className={({ isActive }) =>
            isActive ? "sider-menu active" : "sider-menu"
          }
        >
          <ParticipantIcon />
          <Typography variant="subtitle1">Patients</Typography>
        </NavLink>
      )}
      {/* {hasAdminAccess && (
        <NavLink
          to="/app/messages"
          title="Messages"
          className={({ isActive }) =>
            isActive ? "sider-menu active" : "sider-menu"
          }
        >
          <MessagesIcon />
          <Typography variant="subtitle1">Messages</Typography>
        </NavLink>
      )} */}
      {hasAdminAccess && (
        <NavLink
          to="/app/health"
          title="Health"
          className={({ isActive }) =>
            isActive ? "sider-menu active" : "sider-menu"
          }
        >
          <HealthIcon />
          <Typography variant="subtitle1">Health</Typography>
        </NavLink>
      )}
      {hasAdminAccess && (
        <NavLink
          to="/app/appointments"
          title="Appointments"
          className={({ isActive }) =>
            isActive ? "sider-menu active" : "sider-menu"
          }
        >
          <AppointmentsIcon />
          <Typography variant="subtitle1">Appointments</Typography>
        </NavLink>
      )}
      {hasAdminAccess && (
        <NavLink
          to="/app/question"
          title="Questionnaire"
          className={({ isActive }) =>
            isActive ? "sider-menu active" : "sider-menu"
          }
        >
          <QuestionIcon />
          <Typography variant="subtitle1">Questionnaire</Typography>
        </NavLink>
      )}
      {hasAdminAccess && (
        <NavLink
          to="/app/cms"
          title="Content"
          className={({ isActive }) =>
            isActive ? "sider-menu active" : "sider-menu"
          }
        >
          <ContentIcon />
          <Typography variant="subtitle1">Content</Typography>
        </NavLink>
      )}
      {hasAdminAccess && (
        <NavLink
          to="/app/exercise"
          title="Exercise"
          className={({ isActive }) =>
            isActive ? "sider-menu active" : "sider-menu"
          }
        >
          <ExerciseIcon />
          <Typography variant="subtitle1">Exercise</Typography>
        </NavLink>
      )}
      {hasAdminAccess && (
        <NavLink
          to="/app/medication"
          title="Medication"
          className={({ isActive }) =>
            isActive ? "sider-menu active" : "sider-menu"
          }
        >
          <MedicationIcon />
          <Typography variant="subtitle1">Medication</Typography>
        </NavLink>
      )}
      {hasAdminAccess && (
        <NavLink
          to="/app/administrators"
          title="Admins"
          className={({ isActive }) =>
            isActive ? "sider-menu active" : "sider-menu"
          }
        >
          <AdminIcon />
          <Typography variant="subtitle1">Admins</Typography>
        </NavLink>
      )}
      <Divider sx={{ my: 2 }} />
      <NavLink
        to="/app/my-account"
        title="My account"
        className={({ isActive }) =>
          isActive ? "sider-menu active" : "sider-menu"
        }
      >
        <MyAccountIcon />
        <Typography variant="subtitle1">My account</Typography>
      </NavLink>
      <Box className="sider-menu" onClick={logout}>
        <LogoutIcon />
        <Typography variant="subtitle1">Logout</Typography>
      </Box>
    </Box>
  );
};

export default Sidebar;

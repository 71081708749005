import SectionBuilderWrapper from "./SectionBuilder/SectionBuilder";
import ContentBuilder from "./ContentBuilder/ContentBuilder";
import { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { errorToastMessage, toastMessage } from "../../../utils/toast";
import { AxiosResponse } from "axios";
import http from "../../../utils/http";
import { educationResponse } from "../../../utils/education";
import BuilderPreview from "../Preview/BuilderPreview";
import { Backdrop, Box, CircularProgress } from "@mui/material";
import { BuilderWrapper } from "./styles";
import { useAppDispatch, useAppSelector } from "../../../Redux/hooks";
import {
  setDefaults,
  setLoading,
} from "../../../Redux/reducers/cmsBuilderSlice";
import Header from "./Header";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const CMSBuilder = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const pageId = useQuery().get("pageId");
  const lessonId = useQuery().get("lessonId");
  const navigate = useNavigate();

  const { previewMode, editMode, loading } = useAppSelector(
    (state) => state.cmsBuilder
  );

  useEffect(() => {
    const fetchLessonDetails = async () => {
      if (id) {
        try {
          dispatch(setLoading(true));
          const res: AxiosResponse = await http.get(
            `/lms/lessons/${lessonId}/units/${id}`
          );
          if (res.data.data?.educationLesson?.status !== "draft") {
            toastMessage("error", "Only draft modules can be edited");
            navigate(-1);
            return;
          }
          const { pages = [], title, position } = res.data.data;
          const newLesson = {
            pages,
            title,
            position,
          };
          if (pageId) {
            const response: AxiosResponse = await http.get(
              `/lms/lessons/${id}/pages/${pageId}`
            );
            const pageSections = educationResponse(response.data.data.sections);
            dispatch(
              setDefaults({
                lesson: newLesson,
                title: response.data.data.title,
                questionsMandatory: response.data.data.areQuestionsMandatory,
                sections: pageSections,
                loading: false,
                editMode: true,
                previewMode: false,
                lang: res.data.data?.educationLesson?.lang || "en",
              })
            );
          } else {
            dispatch(
              setDefaults({
                lesson: newLesson,
                title: "",
                questionsMandatory: false,
                sections: [],
                loading: false,
                editMode: false,
                previewMode: false,
                lang: res.data.data?.educationLesson?.lang || "en",
              })
            );
          }
        } catch (err) {
          errorToastMessage(err as Error);
          navigate(-1);
        }
      }
    };
    fetchLessonDetails();
    return () => {
      dispatch(
        setDefaults({
          lesson: { pages: [], title: "", position: 0 },
          title: "",
          questionsMandatory: false,
          sections: [],
          loading: true,
          editMode: false,
          previewMode: false,
          lang: "en",
        })
      );
    };
  }, [id, pageId, lessonId, dispatch]);

  return previewMode ? (
    <BuilderPreview pageId={pageId} />
  ) : (
    <Box sx={{ height: "100%" }}>
      {loading && (
        <Backdrop
          open={true}
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <Header />
      <Box sx={BuilderWrapper}>
        {editMode ? <ContentBuilder /> : <SectionBuilderWrapper />}
      </Box>
    </Box>
  );
};

export default CMSBuilder;

import { Box } from "@mui/material";

import {
  a11yProps,
  MainTabPanel,
  StyledTab,
  StyledTabs,
} from "../../Common/UI/TabPanel";
import ParticipantHeader from "./ParticipantHeader";
import ParticipantList from "./ParticipantList";
import { useAppDispatch, useAppSelector } from "../../../Redux/hooks";
import {
  setDoctorsList,
  setParticipantsType,
  // setUrlLoadedFalse,
} from "../../../Redux/reducers/participantsSlice";
import { errorToastMessage } from "../../../utils/toast";
import { AxiosResponse } from "axios";
import http from "../../../utils/http";
import { useEffect } from "react";
import ParticipantUrlLoader from "./ParticipantUrlLoader";
import ParticipantUrlSetter from "./ParticipantUrlSetter";

const Participants = () => {
  const dispatch = useAppDispatch();
  const { type, urlLoaded } = useAppSelector((state) => state.participants);
  const { hasAdminAccess } = useAppSelector((state) => state.user);

  const handleChange = (_: any, newType: string) => {
    dispatch(setParticipantsType(newType));
  };

  useEffect(() => {
    const fetchDoctors = async () => {
      try {
        let url = `/doctors/list`;
        const res: AxiosResponse = await http.get(url);
        const doctors = res.data?.data.map((doctor: any) => {
          return {
            value: doctor?.id,
            text: doctor?.code,
          };
        });
        dispatch(setDoctorsList(doctors));
      } catch (err) {
        errorToastMessage(err as Error);
      }
    };
    if (hasAdminAccess) {
      fetchDoctors();
    }
  }, [dispatch, hasAdminAccess]);

  // useEffect(() => {
  //   return () => {
  //     dispatch(setUrlLoadedFalse());
  //   };
  // }, [dispatch]);

  return urlLoaded ? (
    <>
      <ParticipantHeader />
      <Box sx={{ borderTop: 1, borderColor: "#E5E7EB" }}>
        <StyledTabs value={type} onChange={handleChange}>
          <StyledTab
            label="Active Participants"
            value="active"
            {...a11yProps(0)}
          />
          <StyledTab
            label="Archived Participants"
            value="inactive"
            {...a11yProps(1)}
          />
          {/* {hasAdminAccess && (
            <StyledTab
              label="Unregistered Participants"
              value="unregistered"
              {...a11yProps(2)}
            />
          )} */}
        </StyledTabs>
      </Box>
      <Box sx={{ mt: "24px" }}>
        <MainTabPanel value={type} index={type}>
          <ParticipantList />
        </MainTabPanel>
      </Box>
      <ParticipantUrlSetter />
    </>
  ) : (
    <ParticipantUrlLoader />
  );
};

export default Participants;

import { PersonOutlineOutlined } from "@mui/icons-material";
import { Avatar, Box, SxProps, Typography } from "@mui/material";
import React from "react";

const HeaderStyle: SxProps = {
  height: "112px",
  width: "100%",
  borderRadius: "16px",
  background: "#FEFEFE",
  boxShadow: "0px 0px 12px 0px rgba(0, 0, 0, 0.08)",
  mb: 3,
  display: "flex",
  alignItems: "center",
  p: 2,
};

const InfoContainer: SxProps = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  pr: 2,
  mr: 2,
};

const BorderedInfoContainer: SxProps = {
  ...InfoContainer,
  borderRight: "1px solid",
  borderColor: "#E0E3EB",
};

const text1: SxProps = {
  color: "#111928",
  fontSize: "18px",
  fontWeight: "600",
  lineHeight: "150%",
  mb: 1,
};

const text2: SxProps = {
  color: "#6B7280",
  fontSize: "12px",
  fontWeight: "500",
  lineHeight: "150%",
};

const HealthHeader: React.FC = () => {
  return (
    <Box sx={HeaderStyle}>
      <Avatar
        variant={"square"}
        sx={{
          height: "80px",
          width: "80px",
          bgcolor: "lightgray",
          color: "#000",
          mr: 2,
        }}
      >
        <PersonOutlineOutlined fontSize="large" />
      </Avatar>
      <Box sx={BorderedInfoContainer}>
        <Typography sx={text1}>User name</Typography>
        <Typography sx={text2}>user@example.com</Typography>
      </Box>
      <Box sx={BorderedInfoContainer}>
        <Typography sx={text1}>Female</Typography>
        <Typography sx={text2}>Gender</Typography>
      </Box>
      <Box sx={BorderedInfoContainer}>
        <Typography sx={text1}>22/10/1989</Typography>
        <Typography sx={text2}>Birthday</Typography>
      </Box>
      <Box sx={BorderedInfoContainer}>
        <Typography sx={text1}>USA</Typography>
        <Typography sx={text2}>Country</Typography>
      </Box>
      <Box sx={BorderedInfoContainer}>
        <Typography sx={text1}>+1 323 3233 13232</Typography>
        <Typography sx={text2}>Phone Number</Typography>
      </Box>
      <Box sx={BorderedInfoContainer}>
        <Typography sx={text1}>5</Typography>
        <Typography sx={text2}>Medical Conditions</Typography>
      </Box>
      <Box sx={InfoContainer}>
        <Typography sx={text1}>5</Typography>
        <Typography sx={text2}>Devices</Typography>
      </Box>
    </Box>
  );
};

export default HealthHeader;

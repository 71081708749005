import { Box, SxProps } from "@mui/material";
import { createElement, useEffect, useState } from "react";
import { CustomLayerProps, ResponsiveLine } from "@nivo/line";
import { errorToastMessage } from "../../../../utils/toast";
import { sleepFn } from "../../../../utils/delay";
import ChartLoader from "./ChartLoader";

const container: SxProps = {
  borderRadius: "12px",
  backgroundColor: "#ffffff",
  boxShadow: "0px 0px 12px 0px rgba(0, 0, 0, 0.08)",
  mb: 3,
  height: "450px",
  width: "100%",
  overflow: "hidden",
  display: "flex",
  flexDirection: "column",
};

const sampleData = [
  {
    id: "weight",
    data: [
      {
        x: "",
        y: null,
      },
      {
        x: "2023-09-23",
        y: 88,
      },
      {
        x: "2023-09-22",
        y: 71,
      },
      {
        x: " ",
        y: null,
      },
    ],
  },
];

const CustomGridLine = (y: number, color: string, dash: number) => {
  return ({ yScale, innerWidth }: any) => {
    return (
      <>
        <line
          x1={0}
          x2={innerWidth}
          y1={yScale(y)}
          y2={yScale(y)}
          stroke={color}
          strokeWidth={2}
          strokeDasharray={dash}
        />
      </>
    );
  };
};

function Points({ points }: CustomLayerProps) {
  const mappedPoints = points.reverse().map((point) => ({
    id: point.id,
    symbol: CustomPoint,
    x: point.x - 15 / 2,
    y: point.y - 15 / 2,
    fill: point.color,
    borderColor: point.borderColor,
  }));

  return (
    <g>
      {mappedPoints.map(({ symbol, ...point }) =>
        createElement(symbol, { ...point, key: point.id })
      )}
    </g>
  );
}

const CustomPoint = ({ x, y }: any) => {
  return (
    <g>
      <circle cx={x} cy={y} r="15" fill="#DEF7EC" />
      <circle cx={x} cy={y} r="10.5" fill="#70AE71" />
    </g>
  );
};
const Weight: React.FC = () => {
  const [data, setData] = useState({
    data: [
      {
        id: "weight",
        data: [],
      },
    ] as any,
    loading: false,
    error: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        setData({
          error: "",
          loading: true,
          data: [
            {
              id: "weight",
              data: [],
            },
          ],
        });
        await sleepFn(500);
        setData({
          error: "",
          loading: false,
          data: sampleData,
        });
      } catch (err) {
        setData((prev) => {
          return {
            ...prev,
            error: "Something went wrong",
            loading: false,
            data: [
              {
                id: "weight",
                data: [],
              },
            ],
          };
        });
        errorToastMessage(err as Error);
      }
    };
    fetchData();
  }, [setData]);

  return (
    <Box sx={container}>
      <Box sx={{ flex: 1, minHeight: "1px", p: 4 }}>
        <ResponsiveLine
          theme={{
            fontFamily: "Poppins, sans-serif",
            axis: {
              domain: {
                line: {
                  stroke: "#E0E3EB",
                  strokeWidth: 1,
                },
              },
              ticks: {
                text: {
                  fontSize: 14,
                  fill: "#355962",
                  fontWeight: 500,
                },
              },
              legend: {
                text: {
                  fontSize: 14,
                  fill: "#9CA3AF",
                  fontWeight: 500,
                },
              },
            },
          }}
          yScale={{
            max: 110,
            min: 40,
            type: "linear",
          }}
          data={data.data}
          margin={{ top: 10, right: 10, bottom: 60, left: 60 }}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            legend: "Months",
            legendPosition: "middle",
            legendOffset: 50,
            tickSize: 0,
          }}
          axisLeft={{
            legend: "Weight",
            legendPosition: "middle",
            legendOffset: -50,
            tickSize: 0,
            tickValues: [75, 100],
          }}
          layers={[
            CustomGridLine(100, "#E0E3EB", 4),
            CustomGridLine(75, "#E0E3EB", 4),
            CustomGridLine(70, "#327091", 8),
            "markers",
            "axes",
            "areas",
            "crosshair",
            Points,
            "slices",
            "mesh",
            "legends",
            ChartLoader(
              data.loading,
              data.error,
              data.data.length === 0,
              "#70AE71"
            ),
          ]}
        />
      </Box>
    </Box>
  );
};

export default Weight;

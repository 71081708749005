import { Box, CircularProgress, Modal, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import http from "../../utils/http";
import { scoreEvalFromResponse, scoresFromResponse } from "../../utils/score";
import { errorToastMessage } from "../../utils/toast";
import { ModalBaseStyles } from "../Common/styles/modal";
import { CloseButton, DividerWithBG } from "../Common/UI/CommonModalDesign";
import {
  questionListItemStyle,
  questionListItemNoStyle,
  previewQuestionList,
  previewQuestionWrapper,
} from "./scores.style";

const calcButtons = [
  {
    func: "AC",
    classValue: "#A0A5B8",
    label: "AC",
  },
  {
    func: "constant",
    classValue: "#A0A5B8",
    label: "CONST",
  },
  {
    type: "paranthesis",
    func: "(",
    classValue: "#A0A5B8",
    label: "(",
  },
  {
    type: "paranthesis",
    func: ")",
    classValue: "#A0A5B8",
    label: ")",
  },
  {
    func: "%",
    classValue: "#A0A5B8",
    label: "%",
  },
  {
    func: "*",
    classValue: "#00B9FC",
    label: "X",
    multiLabel: "x",
  },
  {
    func: "-",
    classValue: "#00B9FC",
    label: "-",
  },
  {
    func: "+",
    classValue: "#00B9FC",
    label: "+",
  },
  {
    func: "/",
    classValue: "#00B9FC",
    label: "%",
  },
];

const scoreConditionTypes: any = {
  gt: "Greater than",
  gte: "Greater than or equal to",
  lt: "Less than",
  lte: "Less than or equal to",
  between: "Between",
  eq: "Equal to",
};

const ViewScoreModal: React.FC<any> = ({ scoreId, qid, closeHandler }) => {
  const [questionLoading, setQuestionLoading] = useState(true);
  const [loading, setLoading] = useState(true);
  const [name, setName] = useState("");
  let [scores, setScores] = useState<any[]>([]);
  let [scoreEval, setEval] = useState<any[]>([]);
  const [questions, setQuestions] = useState<any[]>([]);

  const updateStateInfo = useCallback(
    async (scoreId: string) => {
      try {
        setLoading(true);
        const response = await http.get(`/score_expressions/${scoreId}`);
        let scoreInfoValues = response.data.data;
        setScores(scoresFromResponse(scoreInfoValues.scoreExpressionNodes));
        setEval(
          scoreEvalFromResponse(scoreInfoValues.scoreExpressionConditions)
        );
        setName(scoreInfoValues.title);
        setLoading(false);
      } catch (err) {
        errorToastMessage(err as Error);
        setLoading(false);
      }
    },
    [setScores, setEval, setName]
  );

  useEffect(() => {
    if (scoreId !== undefined) {
      updateStateInfo(scoreId);
    }
  }, [scoreId, updateStateInfo]);

  useEffect(() => {
    const fetchDetails = async () => {
      if (qid) {
        try {
          setQuestionLoading(true);
          let { data } = await http.get(`/questionnaires/${qid}/questions`);
          let ques: any[] = [];
          const temp = data.data.questions;
          temp.sort(function (a: any, b: any) {
            return a.position - b.position;
          });
          temp.forEach((que: any) => {
            if (que.type !== "group") {
              ques.push({
                questionId: que.id,
                questionTitle: que.title,
                questionNo: `Q${que.position}`,
              });
            } else {
              const groupQues = que?.properties?.questions || [];
              groupQues.sort(function (a: any, b: any) {
                return a.position - b.position;
              });
              groupQues.forEach((q: any) => {
                ques.push({
                  questionId: q.id,
                  questionTitle: q.title,
                  questionNo: `Q${que.position}.${q.position}`,
                });
              });
            }
          });
          setQuestions(ques);
          setQuestionLoading(false);
        } catch (err) {
          errorToastMessage(err as Error);
          setQuestionLoading(false);
        }
      }
    };
    fetchDetails();
  }, [qid, setQuestions]);

  return (
    <Modal open={true} onClose={closeHandler}>
      <Box sx={{ ...ModalBaseStyles, p: 4, width: "75vw" }}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h2">{name}</Typography>
          <CloseButton buttonClick={closeHandler} />
        </Box>
        <DividerWithBG />
        <Typography variant="h5" mb={1}>
          Questions
        </Typography>
        <Box sx={{ display: "flex", columnGap: 2 }}>
          <Box sx={previewQuestionWrapper}>
            <Box sx={previewQuestionList}>
              {questionLoading ? (
                <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
                  <CircularProgress />
                </Box>
              ) : (
                questions.length > 0 &&
                questions.map((item: any) => {
                  return (
                    <Box sx={questionListItemStyle} key={item.questionId}>
                      <Box sx={questionListItemNoStyle}>{item.questionNo}</Box>
                      <Box>{item.questionTitle}</Box>
                    </Box>
                  );
                })
              )}
            </Box>
          </Box>
          <Box sx={{ flex: 1, minWidth: "1px" }}>
            {loading ? (
              <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
                <CircularProgress />
              </Box>
            ) : (
              <>
                <div className="score-preview-exp">
                  <Typography variant="h5" mb={1}>
                    Expression
                  </Typography>
                  <div className="score-box">
                    <span className="score-x">X =</span>
                    {scores.length > 0 &&
                      scores.map((item: any, _: any) => {
                        return (
                          <input
                            className={
                              item.type === "constant"
                                ? "const-input"
                                : item.type === "question"
                                ? "question-input"
                                : "operator-input"
                            }
                            type="text"
                            value={
                              item.type === "question"
                                ? questions.find(
                                    (ques: any) =>
                                      ques.questionId === item.value
                                  )?.questionNo
                                : item.value !== "*"
                                ? item.value
                                : calcButtons[5].multiLabel
                            }
                            readOnly
                          />
                        );
                      })}
                  </div>
                </div>
                <div className="score-preview-conditions">
                  <Typography variant="h5" mb={1}>
                    Scores
                  </Typography>
                  {scoreEval.map((_: any, index: any) => {
                    const conditionType =
                      scoreConditionTypes[scoreEval[index].condition];
                    return (
                      <div
                        className="score-conditions"
                        key={`${index}-score-eval`}
                      >
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Box sx={{ mr: 2 }}>If X</Box>
                          <Box sx={{ mr: 2 }} className="score-border-cond">
                            {conditionType}
                          </Box>
                          <Box sx={{ mr: 2 }} className="score-border-cond">
                            {conditionType === "Between"
                              ? `${scoreEval[index].minValue} and ${scoreEval[index].maxValue}`
                              : conditionType === "Less than" ||
                                conditionType === "Less than or equal to"
                              ? `${scoreEval[index].maxValue}`
                              : `${scoreEval[index].minValue}`}
                          </Box>
                        </Box>
                        <Box
                          sx={{ display: "flex", alignItems: "center", mt: 3 }}
                        >
                          <Box sx={{ mr: 2 }}>{`Then Score`}</Box>
                          <div className="score-border-cond">{`${scoreEval[index].score}`}</div>
                        </Box>
                      </div>
                    );
                  })}
                </div>
              </>
            )}
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default ViewScoreModal;

import { Delete, Edit, MoreVert } from "@mui/icons-material";
import {
  Box,
  IconButton,
  LinearProgress,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { DateTime } from "luxon";

import { useEffect, useState } from "react";
import { fetchHighlightsData } from "../../Redux/actions/cloudAction";
import { useAppDispatch, useAppSelector } from "../../Redux/hooks";
import {
  setCloudLoader,
  setCloudModalProps,
  setHighlightsPage,
  toggleCloud,
} from "../../Redux/reducers/cloudSlice";
import http from "../../utils/http";
import { errorToastMessage, toastMessage } from "../../utils/toast";
import {
  NoDataContainer,
  pageSize,
  paginationLabel,
  StyledTableCell,
  TablePaginationStyle,
} from "../Common/styles/table";

const Highlights = () => {
  const [selectedRow, setSelectedRow] = useState<any>(null);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const dispatch = useAppDispatch();
  const {
    searchText,
    page,
    highlightsData,
    loading,
    totalHighlights,
    toggleLoader,
  } = useAppSelector((state) => state.cloud);

  const handleChangePage = (_1: any, newPage: number) => {
    dispatch(setHighlightsPage(newPage));
  };

  useEffect(() => {
    dispatch(fetchHighlightsData(page, searchText));
  }, [dispatch, page, searchText, toggleLoader]);

  const handleMenuClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    row: any
  ) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const closeMenu = () => {
    setSelectedRow(null);
    setAnchorEl(null);
  };

  const deleteHandler = async () => {
    try {
      if (selectedRow) {
        const id = selectedRow?.id;
        closeMenu();
        dispatch(setCloudLoader(true));
        const res = await http.delete(`/highlight/${id}`);
        toastMessage("success", res.data.message);
        dispatch(toggleCloud());
      }
    } catch (err) {
      errorToastMessage(err as Error);
      dispatch(setCloudLoader(false));
    }
  };

  const editHandler = () => {
    dispatch(
      setCloudModalProps({
        show: true,
        title: selectedRow.label,
        description: selectedRow.description,
        id: selectedRow.id,
      })
    );
    closeMenu();
  };
  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell>Highlight Name</StyledTableCell>
            <StyledTableCell>Description</StyledTableCell>
            <StyledTableCell width={150}>Creation Date</StyledTableCell>
            <StyledTableCell></StyledTableCell>
          </TableRow>
        </TableHead>
        {!loading && highlightsData.length > 0 && (
          <>
            <TableBody>
              {highlightsData?.map((row: any) => (
                <TableRow key={row?.id}>
                  <StyledTableCell>{row?.label}</StyledTableCell>
                  <StyledTableCell>{row?.description}</StyledTableCell>

                  <StyledTableCell>
                    {DateTime.fromISO(row?.createdAt).toFormat(
                      "dd'-'LL'-'yyyy"
                    )}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    <IconButton
                      onClick={(e) => {
                        handleMenuClick(e, row);
                      }}
                    >
                      <MoreVert />
                    </IconButton>
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                {totalHighlights > pageSize && (
                  <TablePagination
                    sx={TablePaginationStyle}
                    count={totalHighlights}
                    page={page}
                    rowsPerPage={pageSize}
                    rowsPerPageOptions={[pageSize]}
                    onPageChange={handleChangePage}
                    labelDisplayedRows={paginationLabel}
                  />
                )}
              </TableRow>
            </TableFooter>
          </>
        )}
      </Table>
      {!loading && highlightsData.length === 0 && (
        <NoDataContainer>
          <Typography variant="body1" color="gray">
            No Data
          </Typography>
        </NoDataContainer>
      )}
      {loading && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      )}
      <Menu anchorEl={anchorEl} open={open} onClose={closeMenu}>
        <MenuItem onClick={editHandler}>
          <ListItemIcon>
            <Edit />
          </ListItemIcon>
          <ListItemText>Edit</ListItemText>
        </MenuItem>
        <MenuItem onClick={deleteHandler}>
          <ListItemIcon>
            <Delete color="error" />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};

export default Highlights;

import React from "react";
import { useAppSelector } from "../../../Redux/hooks";
import { conditions } from "../questionTypes";
import {
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
  Box,
} from "@mui/material";
import { Add, Delete } from "@mui/icons-material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";

type Props = {
  question: any;
  preview?: boolean;
  modifyQuestion: any;
};

const initState: any = {
  TEXT: {
    condition: "contains",
    variable: {
      type: "text",
      value: "",
    },
    nextQuestionId: "",
  },
  CHOICE: {
    condition: "is",
    variable: {
      type: "choice",
      value: "",
    },
    nextQuestionId: "",
  },
  BOOL: {
    condition: "is",
    variable: {
      type: "text",
      value: "",
    },
    nextQuestionId: "",
  },
  NUMBER: {
    condition: "lt",
    variable: {
      type: "number",
      value: 0,
    },
    nextQuestionId: "",
  },
  DATE: {
    condition: "on",
    variable: {
      type: "date",
      value: "",
    },
    nextQuestionId: "",
  },
};

const BranchLogic: React.FC<Props> = ({
  question,
  modifyQuestion,
  preview,
}) => {
  const { questions } = useAppSelector((state) => state.question);
  const addLogicBranch = () => {
    const newQuestion = {
      ...question,
      branches: [...question.branches, initState[question.branchType]],
    };
    modifyQuestion(newQuestion);
  };

  const removeLogicBranch = (index: number) => {
    const newQuestion = {
      ...question,
      branches: question.branches.filter((_: any, i: number) => i !== index),
    };
    modifyQuestion(newQuestion);
  };

  const handleValue = (val: any, idx: number) => {
    const newQuestion = {
      ...question,
      branches: question.branches.map((branch: any, i: number) =>
        i === idx
          ? {
              ...branch,
              variable: {
                ...branch.variable,
                value: val,
              },
            }
          : branch
      ),
    };
    modifyQuestion(newQuestion);
  };

  const handleNumberValue = (val: any, idx: number) => {
    const newQuestion = {
      ...question,
      branches: question.branches.map((branch: any, i: number) =>
        i === idx
          ? {
              ...branch,
              variable: {
                ...branch.variable,
                value: val ? Number(val) : "",
              },
            }
          : branch
      ),
    };
    modifyQuestion(newQuestion);
  };

  const handleChange = (name: string, val: string, idx: number) => {
    const newQuestion = {
      ...question,
      branches: question.branches.map((branch: any, i: number) =>
        i === idx ? { ...branch, [name]: val } : branch
      ),
    };
    modifyQuestion(newQuestion);
  };

  const renderConditionOptions = (type: string) =>
    conditions[type].map((cond: any) => (
      <MenuItem key={cond.label} value={cond.value}>
        {cond.label}
      </MenuItem>
    ));

  const renderAnswer = (branch: any, idx: number) => {
    switch (question.branchType) {
      case "TEXT":
        return (
          <TextField
            value={branch.variable.value}
            fullWidth
            placeholder="Type text here"
            InputProps={{
              readOnly: preview,
            }}
            onChange={(e: any) => handleValue(e.target.value, idx)}
          />
        );
      case "NUMBER":
        return (
          <TextField
            type="number"
            placeholder="type numeric values here"
            value={branch.variable.value}
            fullWidth
            InputProps={{
              readOnly: preview,
            }}
            onChange={(e: any) => handleNumberValue(e.target.value, idx)}
          />
        );
      case "DATE":
        return (
          <DatePicker
            readOnly={preview}
            inputFormat="DD/MM/YYYY"
            value={
              branch.variable.value !== ""
                ? moment(branch.variable.value)
                : null
            }
            onChange={(momentVal) => {
              if (momentVal?.isValid()) {
                handleValue(momentVal.toISOString(), idx);
              }
            }}
            renderInput={(params) => <TextField {...params} fullWidth />}
          />
        );
      case "BOOL":
        return (
          <Select
            fullWidth
            value={branch.variable.value}
            onChange={(event) => handleValue(event.target.value, idx)}
            readOnly={preview}
          >
            <MenuItem value="yes">Yes</MenuItem>
            <MenuItem value="no">No</MenuItem>
          </Select>
        );
      case "CHOICE":
        return (
          <Select
            fullWidth
            value={branch.variable.value}
            onChange={(event) => handleValue(event.target.value, idx)}
            readOnly={preview}
          >
            {question.fields.map((field: any) => (
              <MenuItem key={field.id} value={field.id}>
                {field.label !== ""
                  ? field.label
                  : field.isOther
                  ? "Other"
                  : "No Values"}
              </MenuItem>
            ))}
          </Select>
        );
      default:
        return null;
    }
  };

  const questionTitle = (question: any, idx: number, grpIdx?: number) => {
    return grpIdx ? (
      <Tooltip
        placement="right-end"
        title={`${grpIdx}.${idx + 1}. ${question.title}`}
      >
        <Typography variant="inherit" noWrap>{`${grpIdx}.${idx + 1}. ${
          question.title
        }`}</Typography>
      </Tooltip>
    ) : (
      <Tooltip placement="right-end" title={`${idx + 1}. ${question.title}`}>
        <Typography variant="inherit" noWrap>{`${idx + 1}. ${
          question.title
        }`}</Typography>
      </Tooltip>
    );
  };

  const renderQuestions = (questions: any) =>
    questions.map((que: any, index: number) => {
      if (que.id !== question.id) {
        if (que.type !== "group") {
          return (
            <MenuItem key={que.id} value={que.id} sx={{ width: "100%" }}>
              {questionTitle(que, index)}
            </MenuItem>
          );
        } else {
          return que.questions.map((nestedQuestion: any, nestedIdx: number) => {
            if (nestedQuestion.id !== question.id) {
              return (
                <MenuItem key={nestedQuestion.id} value={nestedQuestion.id}>
                  {questionTitle(nestedQuestion, nestedIdx, index + 1)}
                </MenuItem>
              );
            } else {
              return null;
            }
          });
        }
      } else {
        return null;
      }
    });

  const renderBranch = (branch: any, idx: number) => {
    return (
      <Box key={idx}>
        <Grid mb={2} container alignItems={"center"} spacing={2}>
          <Grid item xs={6}>
            <Box>
              <Typography variant="subtitle1" fontWeight="regular" mb={2}>
                If condition
              </Typography>
              <Select
                fullWidth
                value={branch.condition}
                onChange={(event) =>
                  handleChange("condition", event.target.value, idx)
                }
                readOnly={preview}
              >
                {renderConditionOptions(question.branchType)}
              </Select>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box>
              <Typography variant="subtitle1" fontWeight="regular" mb={2}>
                the value
              </Typography>
              {renderAnswer(branch, idx)}
            </Box>
          </Grid>
        </Grid>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            mb: 2,
          }}
        >
          <Typography variant="subtitle1" fontWeight="regular" mr={2}>
            Then Jump to
          </Typography>
          <FormControl sx={{ width: "50%" }}>
            <InputLabel>Select Question</InputLabel>
            <Select
              fullWidth
              label="Select Question"
              MenuProps={{
                sx: {
                  width: "fit-content",
                },
              }}
              readOnly={preview}
              value={
                questions.find((q: any) =>
                  q.type === "group"
                    ? q.questions.find(
                        (nQ: any) => nQ.id === branch.nextQuestionId
                      )
                    : q.id === branch.nextQuestionId
                )
                  ? branch.nextQuestionId
                  : ""
              }
              onChange={(event) =>
                handleChange("nextQuestionId", event.target.value, idx)
              }
            >
              {renderQuestions(questions)}
            </Select>
          </FormControl>
          {!preview && (
            <IconButton
              onClick={() => removeLogicBranch(idx)}
              sx={{ marginLeft: "auto" }}
            >
              <Delete fontSize="medium" sx={{ color: "error.main" }} />
            </IconButton>
          )}
        </Box>
      </Box>
    );
  };

  return (
    <>
      {question.branches.length > 0 ? (
        <>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              mb: 2,
            }}
          >
            <Typography variant="subtitle1" fontWeight="medium" mb={1}>
              When someone answers Question {question.position}
            </Typography>
            {!preview && (
              <Button
                variant="contained"
                onClick={addLogicBranch}
                startIcon={<Add />}
              >
                Add branch
              </Button>
            )}
          </Box>
          {question.branches.map((branch: any, idx: number) => {
            if (branch.condition !== "always") {
              return renderBranch(branch, idx);
            } else {
              return null;
            }
          })}
        </>
      ) : preview ? (
        <Typography variant="subtitle1" fontWeight="medium" mb={1} color="grey">
          No Logic Jumps
        </Typography>
      ) : (
        <>
          <Typography variant="subtitle1" fontWeight="medium" mb={1}>
            Show different questions based on people's answers
          </Typography>
          <Button
            variant="contained"
            onClick={addLogicBranch}
            startIcon={<Add />}
          >
            Add Logic Jump
          </Button>
        </>
      )}
    </>
  );
};

export default BranchLogic;

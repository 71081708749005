import { Box, Typography } from "@mui/material";
import { borderedContainer, footContainerWrapper } from "./style";
import { WeightIcon } from "../../Common/assets/FoodJournalIcons";

const Weight = ({ data }: any) => {
  return (
    <Box sx={footContainerWrapper}>
      <Typography variant="subtitle2" fontWeight={600} mb={2}>
        Weight
      </Typography>
      <Box sx={borderedContainer}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1.75,
            p: 1.75,
          }}
        >
          <WeightIcon />
          {data ? (
            <Typography variant="h6" color="#212121" fontWeight="medium">
              {data?.weight}
              <Typography
                component="span"
                variant="body1"
                fontWeight="regular"
                color="#676970"
              >
                {" "}
                Kg
              </Typography>
            </Typography>
          ) : (
            <Typography variant="subtitle1" fontWeight="regular" color={"gray"}>
              Data Unavailable
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Weight;

import { ChangeEvent, useMemo, useState } from "react";
import { Box, Button, debounce, TextField, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../Redux/hooks";
import {
  setQuestionListLoader,
  setQuestionListSearchText,
} from "../../Redux/reducers/questionListSlice";
import {
  HeaderLeftContent,
  HeaderRightContent,
  StyledHeader,
} from "../Common/styles/header";
import CreateQuestionModal from "./CreateQuestionModal";
import { errorToastMessage, toastMessage } from "../../utils/toast";
import { importQuestionnaire } from "../../utils/question";

const QuestionHeader = () => {
  const [showCreateModal, setShowCreateModal] = useState(false);
  const dispatch = useAppDispatch();
  const { searchText } = useAppSelector((state) => state.quesList);
  const navigate = useNavigate();

  const modifySearchTerm = useMemo(
    () =>
      debounce((val) => {
        dispatch(setQuestionListSearchText(val));
      }, 500),
    [dispatch]
  );

  const handleCreateClick = () => {
    setShowCreateModal(true);
  };

  const closeCreateModal = () => {
    setShowCreateModal(false);
  };

  const importQuestionHandler = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      dispatch(setQuestionListLoader(true));
      const file = e.target.files[0];
      const reader = new FileReader();

      reader.onload = async (e: any) => {
        try {
          const result = JSON.parse(JSON.stringify(e.target.result));
          const id = await importQuestionnaire(JSON.parse(result));
          dispatch(setQuestionListLoader(false));
          if (id) {
            toastMessage("success", "Questionnaire created successfully");
            navigate(`/app/question/builder?id=${id}`);
          }
        } catch (err) {
          dispatch(setQuestionListLoader(false));
          errorToastMessage(err as Error);
        }
      };
      reader.readAsText(file);

      return false;
    }
  };

  return (
    <StyledHeader>
      <Box sx={HeaderLeftContent}>
        <Typography fontSize={30} fontWeight="bold">
          Questionnaire
        </Typography>

        <TextField
          onChange={(e) => modifySearchTerm(e.target.value)}
          style={{ width: "300px" }}
          placeholder="Search for Questionnaire"
          defaultValue={searchText}
        />
      </Box>
      <Box sx={HeaderRightContent}>
        <Button variant="outlined" component="label">
          Import
          <input
            hidden
            accept="application/json"
            type="file"
            onChange={importQuestionHandler}
          />
        </Button>
        <Button variant="contained" onClick={handleCreateClick}>
          {"Create new Questionnaire"}
        </Button>
      </Box>
      {showCreateModal && (
        <CreateQuestionModal
          showModal={showCreateModal}
          closeModal={closeCreateModal}
        />
      )}
    </StyledHeader>
  );
};

export default QuestionHeader;

import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import { DTxCardStyle } from "../dashboard.style";
import { CircularProgressWithLabel } from "../../Common/UI/ProgressWithLabel";
import { errorToastMessage } from "../../../utils/toast";
import { doseAdherenceData } from "../dashboardDetails";
import { LoadingContainer } from "../../CMS/cms.style";
import { ResponsiveBar } from "@nivo/bar";
import { CustomTooltip } from "../../Common/UI/Tooltip";

const calculateTickValue = (data: any) => {
  const maxNo = Math.max(
    ...data.map((v: any) => v?.mild + v?.moderate + v?.severe)
  );
  const arr = Array.from(
    { length: Math.floor(maxNo / 20) },
    (_, i) => (i + 1) * 20
  );
  return maxNo > 20 ? arr.length + 1 : arr.length;
};

const DoseAdherenceTab = () => {
  const [loading, setLoading] = useState(false);
  const [doseAdherence, setDoseAdherence] = useState<any>({
    digitalAppUsers: 0,
    patientAdheringMed: 0,
    medvsAssessScore: [],
  });

  useEffect(() => {
    const fetchActivityData = async () => {
      try {
        setLoading(true);
        // const res: AxiosResponse = await http.get("/dashboard");
        setDoseAdherence(doseAdherenceData);
        setLoading(false);
      } catch (err) {
        errorToastMessage(err as Error);
        setLoading(false);
      }
    };

    fetchActivityData();
  }, []);
  return !loading ? (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "repeat(auto-fill, minmax(576px, 1fr))",
        gap: "20px",
      }}
    >
      <Box sx={DTxCardStyle}>
        <Typography
          variant="subtitle1"
          fontWeight="medium"
          mb={6}
          color="#637E85"
        >
          Patients Using the Digital Health App
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "center", mb: 4 }}>
          <CircularProgressWithLabel
            size={230}
            value={64}
            fontSize={47}
            fontWeight={600}
          />
        </Box>
      </Box>
      <Box sx={DTxCardStyle}>
        <Typography
          variant="subtitle1"
          fontWeight="medium"
          mb={6}
          color="#637E85"
        >
          Patients Adhering to Medication
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "center", mb: 4 }}>
          <CircularProgressWithLabel
            size={230}
            value={55}
            fontSize={47}
            fontWeight={600}
          />
        </Box>
      </Box>
      <Box sx={{ ...DTxCardStyle, height: "473px" }}>
        <Typography
          variant="subtitle1"
          fontWeight="medium"
          mb={6}
          color="#637E85"
        >
          Medication Adherence vs Assessment Score
        </Typography>
        <ResponsiveBar
          data={doseAdherence?.medvsAssessScore}
          keys={["severe", "moderate", "mild"]}
          colors={["#F05252", "#FF8A4C", "#FACA15"]}
          indexBy="label"
          margin={{ top: 10, right: 15, bottom: 180, left: 15 }}
          padding={0.75}
          valueScale={{ type: "linear" }}
          indexScale={{ type: "band", round: true }}
          theme={{
            fontSize: 14,
            axis: {
              ticks: {
                text: {
                  fontSize: 14,
                  fontWeight: 600,
                  fill: "#637E85",
                },
              },
            },
          }}
          gridYValues={
            calculateTickValue(doseAdherence?.medvsAssessScore) || undefined
          }
          axisTop={null}
          axisRight={null}
          axisLeft={null}
          axisBottom={{
            tickSize: 0,
            tickPadding: 16,
            tickRotation: 0,
          }}
          enableLabel={false}
          legends={[
            {
              dataFrom: "keys",
              data: [
                {
                  id: 0,
                  label: "Mild",
                  color: "#FACA15",
                },
                {
                  id: 1,
                  label: "Moderate",
                  color: "#FF8A4C",
                },
                {
                  id: 2,
                  label: "Severe",
                  color: "#F05252",
                },
              ],
              anchor: "bottom",
              direction: "row",
              justify: false,
              translateY: 90,
              itemsSpacing: 2,
              itemWidth: 90,
              itemHeight: 20,
              itemDirection: "left-to-right",
              itemTextColor: "#355962",
              symbolSize: 16,
              symbolShape: "circle",
            },
          ]}
          tooltip={({ value, color }: any) => {
            const label =
              color === "#F05252"
                ? "Severe"
                : color === "#FACA15"
                ? "Mild"
                : "Moderate";
            return <CustomTooltip value={value} color={color} label={label} />;
          }}
        />
      </Box>
    </Box>
  ) : (
    <Box sx={LoadingContainer}>
      <CircularProgress />
    </Box>
  );
};

export default DoseAdherenceTab;

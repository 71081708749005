import { createBrowserRouter, Navigate, Outlet } from "react-router-dom";

import ChangePassword from "./Components/Auth/ChangePassword";
import ForgotPassword from "./Components/Auth/ForgotPassword";
// import NotificationCenter from "./Components/NotificationCenter/NotificationCenter";
// import PasswordRequest from "./Components/Auth/PasswordRequest";
import ResetPassword from "./Components/Auth/ResetPassword";
import SetPassword from "./Components/Auth/SetPassword";
import SignIn from "./Components/Auth/SignIn";
import VerifyEmail from "./Components/Auth/VerifyEmail";
import AppLayout from "./Components/Layout/AppLayout";
import Administrators from "./Components/UserManagement/Administrators/Administrators";
import Participants from "./Components/UserManagement/Participants/Participants";
import { UnAuthenticatedRoute, AuthenticatedRoute } from "./utils/Route";
import Chatbot from "./Components/Chatbot/Chatbot";
// import BrokenLink from "./Components/Chatbot/BrokenLink/BrokenLink";
import OnboardingBuilder from "./Components/Onboarding/Builder/Builder";
import CMS from "./Components/CMS/CMS";
import LearningModuleDetails from "./Components/CMS/Modules/LearningModule/LearningModuleDetails";
import Units from "./Components/CMS/Modules/EducationModule.tsx/Units";
import Pages from "./Components/CMS/Modules/EducationModule.tsx/Pages";
import Reviews from "./Components/CMS/Modules/EducationModule.tsx/Feedback/Reviews";
import Cloud from "./Components/Cloud/Cloud";
import ChatbotBuilder from "./Components/Chatbot/Builder/ChatbotBuilder";
import CMSPreviewWrapper from "./Components/CMS/Preview/CMSPreviewWrapper";
import CMSBuilder from "./Components/CMS/Builder/CMSBuilder";
import WebviewWrapper from "./Components/CMS/Webview/WebviewWrapper";
import Journal from "./Components/PatientDetails/FoodJournal/Journal";
import PreviewWrapper from "./Components/Chatbot/Preview/PreviewWrapper";
import Settings from "./Components/Settings/Settings";
import LearningPath from "./Components/LearningPath/LearningPath";
// import Activities from "./Components/Activities/Activities";
import ReflectionJournal from "./Components/UserManagement/Participants/ReflectionJournal";
import ParticipantDetails from "./Components/UserManagement/Participants/ParticipantDetails";
import PreviewPrivacyPolicy from "./Components/Settings/PreviewPrivacyPolicy";
// import DeleteAccountSteps from "./Components/Settings/DeleteAccountSteps";
// import GoogleFit from "./Components/Settings/GoogleFit/GoogleFit";
import QuestionPreview from "./Components/QuestionPreview/QuestionPreview";
import ScoreList from "./Components/Scores/Scores";
import CreateStore from "./Components/Scores/CreateScore";
import QuestionBuilderContext from "./Components/QuestionBuilder/QuestionBuilder";
import Questions from "./Components/Question/Questions";
import ExerciseCollection from "./Components/ExerciseCollection/ExerciseCollection";
import ExerciseCollectionDetails from "./Components/ExerciseCollection/ExerciseCollectionDetails";
import Health from "./Components/PatientDetails/Health/Health";
import Dashboard from "./Components/Dashboard/Dashboard";
import SectionBuilder from "./Components/ExerciseCollection/Sections/SectionBuilder";
import ExerciseBuilder from "./Components/ExerciseCollection/ExerciseBuilder/ExerciseBuilder";
import Medication from "./Components/Medication/Medication";
import Appointments from "./Components/Appointments/Appointments";
import AppointmentListView from "./Components/Appointments/AppointmentsListView";

const routes = createBrowserRouter([
  {
    path: "/auth",
    element: (
      <UnAuthenticatedRoute>
        <Outlet />
      </UnAuthenticatedRoute>
    ),
    children: [
      {
        path: "login",
        element: <SignIn />,
      },
      {
        path: "forgot-password",
        element: <ForgotPassword />,
      },
      {
        path: "reset-password/:token",
        element: <ResetPassword />,
      },
      {
        path: "set-password",
        element: <SetPassword />,
      },
      {
        path: "verify-email",
        element: <VerifyEmail />,
      },
    ],
  },
  {
    path: "/app",
    element: (
      <AuthenticatedRoute>
        <AppLayout />
      </AuthenticatedRoute>
    ),
    children: [
      {
        path: "dashboard",
        element: (
          <AuthenticatedRoute
            allowedRoles={["master_admin", "admin", "doctor"]}
          >
            <Dashboard />
          </AuthenticatedRoute>
        ),
      },
      {
        path: "change-password",
        element: (
          <AuthenticatedRoute
            allowedRoles={["master_admin", "admin", "doctor"]}
          >
            <ChangePassword />
          </AuthenticatedRoute>
        ),
      },
      {
        path: "administrators",
        element: (
          <AuthenticatedRoute allowedRoles={["master_admin", "admin"]}>
            <Administrators />
          </AuthenticatedRoute>
        ),
      },
      {
        path: "exercise",
        children: [
          {
            index: true,
            element: (
              <AuthenticatedRoute allowedRoles={["master_admin", "admin"]}>
                <ExerciseCollection />
              </AuthenticatedRoute>
            ),
          },
          {
            path: ":id/categories",
            element: (
              <AuthenticatedRoute allowedRoles={["master_admin", "admin"]}>
                <ExerciseCollectionDetails />
              </AuthenticatedRoute>
            ),
          },
          {
            path: ":id/categories/:categoryId/sections",
            element: (
              <AuthenticatedRoute allowedRoles={["master_admin", "admin"]}>
                <SectionBuilder />
              </AuthenticatedRoute>
            ),
          },
          {
            path: "builder",
            element: (
              <AuthenticatedRoute allowedRoles={["master_admin", "admin"]}>
                <ExerciseBuilder />
              </AuthenticatedRoute>
            ),
          },
        ],
      },
      {
        path: "medication",
        element: <Medication />,
      },
      {
        path: "appointments",
        children: [
          {
            index: true,
            element: <Appointments />,
          },
          {
            path: "list",
            element: <AppointmentListView />,
          },
        ],
      },
      {
        path: "participants",
        children: [
          {
            index: true,
            element: (
              <AuthenticatedRoute
                allowedRoles={["master_admin", "admin", "doctor"]}
              >
                <Participants />
              </AuthenticatedRoute>
            ),
          },
          {
            path: ":id",
            element: (
              <AuthenticatedRoute
                allowedRoles={["master_admin", "admin", "doctor"]}
              >
                <ParticipantDetails />
              </AuthenticatedRoute>
            ),
          },
          {
            path: ":id/journal",
            element: (
              <AuthenticatedRoute
                allowedRoles={["master_admin", "admin", "doctor"]}
              >
                <Journal />
              </AuthenticatedRoute>
            ),
          },
          {
            path: ":id/self-reflection",
            element: (
              <AuthenticatedRoute
                allowedRoles={["master_admin", "admin", "doctor"]}
              >
                <ReflectionJournal />
              </AuthenticatedRoute>
            ),
          },
        ],
      },
      // {
      //   path: "password-request",
      //   element: (
      //     <AuthenticatedRoute allowedRoles={["master_admin", "admin"]}>
      //       <PasswordRequest />
      //     </AuthenticatedRoute>
      //   ),
      // },
      {
        path: "onboarding",
        element: (
          <AuthenticatedRoute allowedRoles={["master_admin", "admin"]}>
            <OnboardingBuilder />
          </AuthenticatedRoute>
        ),
      },
      // {
      //   path: "notification",
      //   element: (
      //     <AuthenticatedRoute allowedRoles={["master_admin", "admin"]}>
      //       <NotificationCenter />
      //     </AuthenticatedRoute>
      //   ),
      // },
      // {
      //   path: "daily-activities",
      //   element: (
      //     <AuthenticatedRoute allowedRoles={["master_admin", "admin"]}>
      //       <Activities />
      //     </AuthenticatedRoute>
      //   ),
      // },
      {
        path: "chatbot",
        children: [
          {
            index: true,
            element: (
              <AuthenticatedRoute allowedRoles={["master_admin", "admin"]}>
                <Chatbot />
              </AuthenticatedRoute>
            ),
          },
          // {
          //   path: "broken-link",
          //   element: (
          //     <AuthenticatedRoute allowedRoles={["master_admin", "admin"]}>
          //       <BrokenLink />
          //     </AuthenticatedRoute>
          //   ),
          // },
          {
            path: "builder/:id",
            element: (
              <AuthenticatedRoute allowedRoles={["master_admin", "admin"]}>
                <ChatbotBuilder />
              </AuthenticatedRoute>
            ),
          },
        ],
      },
      {
        path: "cms",
        children: [
          {
            index: true,
            element: (
              <AuthenticatedRoute allowedRoles={["master_admin", "admin"]}>
                <CMS />
              </AuthenticatedRoute>
            ),
          },
          {
            path: "learning/:id",
            element: (
              <AuthenticatedRoute allowedRoles={["master_admin", "admin"]}>
                <LearningModuleDetails />
              </AuthenticatedRoute>
            ),
          },
          {
            path: ":id",
            element: (
              <AuthenticatedRoute allowedRoles={["master_admin", "admin"]}>
                <Units />
              </AuthenticatedRoute>
            ),
          },
          {
            path: "units/:id",
            element: (
              <AuthenticatedRoute allowedRoles={["master_admin", "admin"]}>
                <Pages />
              </AuthenticatedRoute>
            ),
          },
          {
            path: "review/:id",
            element: (
              <AuthenticatedRoute allowedRoles={["master_admin", "admin"]}>
                <Reviews />
              </AuthenticatedRoute>
            ),
          },
          {
            path: "builder/:id",
            element: (
              <AuthenticatedRoute allowedRoles={["master_admin", "admin"]}>
                <CMSBuilder />
              </AuthenticatedRoute>
            ),
          },
          {
            path: "preview/:id",
            element: (
              <AuthenticatedRoute allowedRoles={["master_admin", "admin"]}>
                <CMSPreviewWrapper />
              </AuthenticatedRoute>
            ),
          },
        ],
      },
      {
        path: "question",
        children: [
          {
            index: true,
            element: (
              <AuthenticatedRoute allowedRoles={["master_admin", "admin"]}>
                <Questions />
              </AuthenticatedRoute>
            ),
          },
          {
            path: "builder",
            element: (
              <AuthenticatedRoute allowedRoles={["master_admin", "admin"]}>
                <QuestionBuilderContext />
              </AuthenticatedRoute>
            ),
          },
          {
            path: "preview",
            element: (
              <AuthenticatedRoute allowedRoles={["master_admin", "admin"]}>
                <QuestionPreview />
              </AuthenticatedRoute>
            ),
          },
          {
            path: "score",
            element: (
              <AuthenticatedRoute allowedRoles={["master_admin", "admin"]}>
                <ScoreList />
              </AuthenticatedRoute>
            ),
          },
          {
            path: "score/create",
            element: (
              <AuthenticatedRoute allowedRoles={["master_admin", "admin"]}>
                <CreateStore />
              </AuthenticatedRoute>
            ),
          },
        ],
      },
      {
        path: "tags-highlights",
        element: (
          <AuthenticatedRoute allowedRoles={["master_admin", "admin"]}>
            <Cloud />
          </AuthenticatedRoute>
        ),
      },
      {
        path: "settings",
        element: (
          <AuthenticatedRoute allowedRoles={["master_admin"]}>
            <Settings />
          </AuthenticatedRoute>
        ),
      },
      {
        path: "health",
        element: (
          <AuthenticatedRoute allowedRoles={["master_admin", "admin"]}>
            <Health />
          </AuthenticatedRoute>
        ),
      },
      {
        path: "learning-path",
        element: (
          <AuthenticatedRoute allowedRoles={["master_admin", "admin"]}>
            <LearningPath />
          </AuthenticatedRoute>
        ),
      },
    ],
  },
  {
    path: "/lesson/read/:id",
    element: <WebviewWrapper />,
  },
  {
    path: "chatbot/preview/:id",
    element: (
      <AuthenticatedRoute allowedRoles={["master_admin", "admin"]}>
        <PreviewWrapper />
      </AuthenticatedRoute>
    ),
  },
  {
    path: "/privacy-policy/:lang",
    element: <PreviewPrivacyPolicy />,
  },
  {
    path: "/privacy-policy",
    element: <PreviewPrivacyPolicy />,
  },
  // {
  //   path: "/delete-steps",
  //   element: <DeleteAccountSteps />,
  // },
  // {
  //   path: "/home",
  //   element: <GoogleFit />,
  // },
  {
    path: "/",
    element: <Navigate to="/app/participants" replace />,
  },
  {
    path: "*",
    element: <Navigate to="/app/participants" replace />,
  },
]);

export default routes;

import React from "react";
import { Avatar, Box, IconButton, Typography } from "@mui/material";
import {
  BorderedBox,
  CardAvatar,
  CardStyle,
  gridWrapper,
} from "../ExerciseBuilder/exercise.style";
import { ExerciseType, SectionType } from "../exercise.type";
import {
  Add,
  MoreVert,
  PhotoSizeSelectActualOutlined,
} from "@mui/icons-material";
import { CardMainText } from "../ExerciseCollection.style";

type Props = {
  section: SectionType;
  openExerciseModal: (data: SectionType) => void;
  openMenu: any;
  openSectionMenu: any;
};

const SectionCards = ({
  section,
  openExerciseModal,
  openMenu,
  openSectionMenu,
}: Props) => {
  return (
    <Box sx={CardStyle}>
      <Box sx={{ display: "flex", alignItems: "center", mb: "12px" }}>
        <Typography
          variant="subtitle2"
          fontWeight="medium"
          color="#355962"
          sx={{ textTransform: "capitalize" }}
        >
          {section?.title || ""}
        </Typography>
        <IconButton
          sx={{ ml: "auto" }}
          onClick={(e) => openSectionMenu(e, section)}
        >
          <MoreVert htmlColor="#637E85" />
        </IconButton>
      </Box>
      {section?.exercises && section?.exercises.length > 0 && (
        <Box sx={gridWrapper}>
          {section?.exercises.map((item: ExerciseType) => (
            <Box key={item?.exercises?.id} sx={BorderedBox}>
              <Avatar
                variant="rounded"
                src={item?.exercises?.imageUrl}
                sx={CardAvatar}
              >
                <PhotoSizeSelectActualOutlined
                  sx={{ width: "30px", height: "30px" }}
                />
              </Avatar>
              <Box>
                <Typography
                  variant="subtitle2"
                  fontWeight="medium"
                  sx={CardMainText}
                >
                  {item?.exercises?.name}
                </Typography>
                {/* <Typography
                  variant="body1"
                  fontWeight="regular"
                  color="#6B7280"
                  sx={{ mt : 1}}
                >
                  {item?.exercises?.duration} mins
                </Typography> */}
              </Box>
              <Box sx={{ ml: "auto" }}>
                <IconButton
                  onClick={(e) =>
                    openMenu(
                      e,
                      { title: section?.title, id: section?.id },
                      item?.exercises?.id
                    )
                  }
                >
                  <MoreVert />
                </IconButton>
              </Box>
            </Box>
          ))}
        </Box>
      )}
      <Box
        sx={{
          p: "4px",
          borderRadius: "8px",
          border: "2px dashed #E0E3EB",
        }}
      >
        <IconButton
          onClick={() =>
            openExerciseModal({ title: section?.title, id: section?.id })
          }
        >
          <Add htmlColor="#637E85" />
        </IconButton>
      </Box>
    </Box>
  );
};

export default SectionCards;

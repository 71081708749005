import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../Redux/hooks";
import { useNavigate } from "react-router-dom";
import { fetchExerciseCollectionData } from "../../../Redux/actions/exerciseCollectionAction";
import {
  setExerciseCollectionModalId,
  toggleExerciseCollection,
} from "../../../Redux/reducers/exerciseCollectionSlice";
import {
  Box,
  CircularProgress,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import {
  LoadingContainer,
  ModuleCardsContainer,
} from "../ExerciseCollection.style";
import ExerciseCollectionCard from "../ExerciseCollectionCard/ExerciseCollectionCard";
import { Delete, Edit } from "@mui/icons-material";
import { NoDataContainer } from "../../Common/styles/table";
import { AxiosResponse } from "axios";
import http from "../../../utils/http";
import { toastMessage } from "../../../utils/toast";

const ExerciseCollectionTab = () => {
  const [selectedModule, setSelectedModule] = useState<any>(null);
  const { exerciseCollectionData, loading, toggler } = useAppSelector(
    (state) => state.exerciseCollection
  );

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const openMenu = (
    event: React.MouseEvent<HTMLButtonElement>,
    module: any
  ) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setSelectedModule(module);
  };

  useEffect(() => {
    dispatch(fetchExerciseCollectionData());
  }, [dispatch, toggler]);

  const closeMenu = () => {
    setAnchorEl(null);
    setSelectedModule(null);
  };

  const handleAction = async (action: string, module?: any) => {
    const targetId = selectedModule?.id;
    if (action === "click") {
      navigate(`/app/exercise/${module?.id}/categories?title=${module?.title}`);
    } else if (action === "edit" && targetId) {
      closeMenu();
      dispatch(setExerciseCollectionModalId(targetId));
    } else if (action === "delete" && targetId) {
      const res: AxiosResponse = await http.delete(
        `/exercise-collection/${targetId}`
      );
      toastMessage("success", res.data.message);
      closeMenu();
      dispatch(toggleExerciseCollection());
    }
  };

  return !loading ? (
    <>
      {exerciseCollectionData.length > 0 ? (
        <Box>
          <Box sx={ModuleCardsContainer}>
            {exerciseCollectionData?.map((module: any) => (
              <ExerciseCollectionCard
                key={module?.id}
                module={module}
                openMenu={openMenu}
                handleAction={handleAction}
              />
            ))}
          </Box>
        </Box>
      ) : (
        <NoDataContainer>
          <Typography variant="body1" color="gray">
            No Data
          </Typography>
        </NoDataContainer>
      )}

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={closeMenu}
        PaperProps={{
          style: {
            minWidth: "225px",
          },
        }}
      >
        <MenuItem
          key="edit"
          onClick={() => {
            handleAction("edit");
          }}
        >
          <ListItemIcon>
            <Edit fontSize="small" />
          </ListItemIcon>
          <ListItemText>Edit</ListItemText>
        </MenuItem>
        <MenuItem
          key="delete"
          onClick={() => {
            handleAction("delete");
          }}
        >
          <ListItemIcon>
            <Delete fontSize="small" color="error" />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
      </Menu>
    </>
  ) : (
    <Box sx={LoadingContainer}>
      <CircularProgress />
    </Box>
  );
};

export default ExerciseCollectionTab;

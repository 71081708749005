import React, { useMemo, useState } from "react";
import {
  Autocomplete,
  AutocompleteInputChangeReason,
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormLabel,
  Modal,
  TextField,
} from "@mui/material";
import { AxiosResponse } from "axios";
import { debounce } from "lodash";
import { ModalBaseStyles, ModalHeader } from "../../Common/styles/modal";
import { InputWrapper, LabelStyle } from "../../Common/styles/form";
import { errorToastMessage, toastMessage } from "../../../utils/toast";
import http from "../../../utils/http";

type Props = {
  showModal: boolean;
  closeModal: () => void;
  refreshPage: () => void;
  exerciseId: string;
  categoryId: string;
  sectionId: string;
};

const AddExerciseModal = ({
  showModal,
  closeModal,
  refreshPage,
  exerciseId,
  categoryId,
  sectionId,
}: Props) => {
  const [submitLoader, setSubmitLoader] = useState<boolean>(false);
  const [searchLoader, setSearchLoader] = useState<boolean>(false);
  const [options, setOptions] = useState<any>([]);
  const [selectedOptions, setSelectedOptions] = useState<any>([]);

  const handleSearch = useMemo(
    () =>
      debounce(async (value: string) => {
        try {
          if (value) {
            setSearchLoader(true);
            const res: AxiosResponse = await http.get(
              `/exercise?pagination=false&search=${value}`
            );
            const newExercises = res.data?.data?.exercises.map(
              (exercise: any) => {
                return {
                  id: exercise?.id,
                  name: exercise?.name,
                };
              }
            );
            setOptions(newExercises);
            setSearchLoader(false);
          }
        } catch (err) {
          setSearchLoader(false);
          errorToastMessage(err as Error);
        }
      }, 500),
    []
  );

  const submitHandler = async (values: any) => {
    try {
      setSubmitLoader(true);
      const body = {
        addIds: values.map((val: any) => val?.id),
      };
      const res: AxiosResponse = await http.post(
        `/exercise-collection/${exerciseId}/categories/${categoryId}/section/${sectionId}/item`,
        body
      );
      toastMessage("success", res.data.message);
      closeModal();
      refreshPage();
    } catch (err) {
      setSubmitLoader(false);
      errorToastMessage(err as Error);
    }
  };

  return (
    <Modal open={showModal} onClose={closeModal}>
      <Box sx={{ ...ModalBaseStyles, minHeight: "20vh" }}>
        <ModalHeader title={"Add Exercise"} onCloseClick={closeModal} />
        <>
          <FormControl sx={InputWrapper}>
            <FormLabel sx={LabelStyle} htmlFor="exercise">
              Select Exercise
            </FormLabel>
            <Autocomplete
              id="exercise"
              multiple
              filterOptions={(x) => x}
              onInputChange={(
                _1: any,
                value: string,
                reason: AutocompleteInputChangeReason
              ) => {
                if (reason === "input") handleSearch(value);
              }}
              onChange={(_1: any, value: any) => {
                setSelectedOptions(value);
              }}
              options={options}
              value={selectedOptions || null}
              getOptionLabel={(option) => option?.name}
              isOptionEqualToValue={(option, value) => {
                return option.id === value.id;
              }}
              loading={searchLoader}
              loadingText={<CircularProgress size={20} />}
              noOptionsText="No Results"
              disableClearable
              clearOnBlur={true}
              renderInput={(params) => (
                <TextField {...params} placeholder="Search Exercise..." />
              )}
            />
          </FormControl>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              gap: 1,
              mt: 2,
            }}
          >
            {!submitLoader ? (
              <>
                <Button
                  type="submit"
                  variant="contained"
                  onClick={() => submitHandler(selectedOptions)}
                >
                  Add
                </Button>
                <Button onClick={closeModal} variant="outlined">
                  Cancel
                </Button>
              </>
            ) : (
              <CircularProgress size={25} />
            )}
          </Box>
        </>
      </Box>
    </Modal>
  );
};

export default AddExerciseModal;

import { Box } from "@mui/material";
import QuestionHeader from "./QuestionHeader";
import QuestionList from "./QuestionList";
import { useAppSelector } from "../../Redux/hooks";
import QuestionUrlLoader from "./QuestionUrlLoader";
import QuestionUrlSetter from "./QuestionUrlSetter";

const Questions = () => {
  const { urlLoaded } = useAppSelector((state) => state.quesList);

  return urlLoaded ? (
    <>
      <QuestionHeader />
      <Box
        sx={{
          // height: "calc(100% - 85px)",
          // overflow: "auto",
          // padding: 2,
          mt: "24px",
        }}
      >
        <QuestionList />
      </Box>
      <QuestionUrlSetter />
    </>
  ) : (
    <QuestionUrlLoader />
  );
};

export default Questions;

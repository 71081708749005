import React, { useEffect, useState } from "react";
import { Box, SxProps } from "@mui/material";
import { ResponsiveScatterPlot } from "@nivo/scatterplot";
import { errorToastMessage } from "../../../../utils/toast";
import { sleepFn } from "../../../../utils/delay";
import ChartLoader from "./ChartLoader";
import { DateTime } from "luxon";

const sampleData = [
  {
    systolic: { high: 140, low: 120 },
    diastolic: { high: 80, low: 60 },
    time: "2023-09-21T10:30:15.023836+00:00",
  },
  {
    systolic: { high: 112, low: 100 },
    diastolic: { high: 37, low: 20 },
    time: "2023-09-21T10:48:15.023836+00:00",
  },
  {
    systolic: { high: 133, low: 120 },
    diastolic: { high: 82, low: 63 },
    time: "2023-09-21T12:30:15.023836+00:00",
  },
  {
    systolic: { high: 115, low: 105 },
    diastolic: { high: 40, low: 25 },
    time: "2023-09-21T13:45:15.023836+00:00",
  },
  {
    systolic: { high: 138, low: 116 },
    diastolic: { high: 80, low: 60 },
    time: "2023-09-21T14:50:15.023836+00:00",
  },
  {
    systolic: { high: 73, low: 55 },
    diastolic: { high: 40, low: 20 },
    time: "2023-09-21T16:15:15.023836+00:00",
  },
  {
    systolic: { high: 100, low: 50 },
    diastolic: { high: 30, low: 20 },
    time: "2023-09-21T16:45:15.023836+00:00",
  },
];

const container: SxProps = {
  borderRadius: "12px",
  backgroundColor: "#ffffff",
  boxShadow: "0px 0px 12px 0px rgba(0, 0, 0, 0.08)",
  mb: 3,
  height: "450px",
  width: "100%",
  overflow: "hidden",
  display: "flex",
  flexDirection: "column",
};

const CustomNodes = (props: any) => {
  return props.nodes.map((node: any, index: number) => (
    <g key={index}>
      <text
        x={node.x}
        y={props.yScale(node.data.y) - 10}
        textAnchor="middle"
        dominantBaseline="middle"
        fontSize={11}
        fontWeight={600}
        fill="#4B5563"
      >
        {node.data.y}
      </text>
      <rect
        x={node.x - node.size / 1.25}
        y={props.yScale(node.data.y)}
        width={node.size + 5}
        height={props.yScale(node.data.y2) - props.yScale(node.data.y)}
        fill={node.color}
        rx={7}
        ry={7}
      />
    </g>
  ));
};

const BloodPressure = () => {
  const [data, setData] = useState<any>({
    data: [
      {
        id: "systolic",
        data: [],
      },
      {
        id: "diastolic",
        data: [],
      },
    ] as any,
    loading: false,
    error: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        setData({
          data: [
            {
              id: "systolic",
              data: [],
            },
            {
              id: "diastolic",
              data: [],
            },
          ] as any,
          loading: true,
          error: "",
        });
        await sleepFn(500);
        let systolicData: any = [];
        let diastolicData: any = [];

        sampleData.forEach((item: any) => {
          if (item?.systolic) {
            systolicData.push({
              x: DateTime.fromISO(item?.time).toFormat("hh:mm a"),
              y: item?.systolic?.high,
              y2: item?.systolic?.low,
            });
          }
          if (item?.diastolic) {
            diastolicData.push({
              x: DateTime.fromISO(item?.time).toFormat("hh:mm a"),
              y: item?.diastolic?.high,
              y2: item?.diastolic?.low,
            });
          }
        });

        setData({
          data: [
            {
              id: "systolic",
              data: systolicData,
            },
            {
              id: "diastolic",
              data: diastolicData,
            },
          ],
          loading: false,
          error: "",
        });
      } catch (err) {
        setData((prev: any) => {
          return {
            ...prev,
            error: "Something went wrong",
            loading: false,
            data: [
              {
                id: "systolic",
                data: [],
              },
              {
                id: "diastolic",
                data: [],
              },
            ],
          };
        });
        errorToastMessage(err as Error);
      }
    };

    fetchData();
  }, []);

  return (
    <Box sx={container}>
      <Box sx={{ flex: 1, minHeight: "1px", p: 4 }}>
        <ResponsiveScatterPlot
          data={data?.data}
          margin={{ top: 20, right: 20, bottom: 75, left: 40 }}
          isInteractive={false}
          xFormat={"time:%I:%M %p"}
          xScale={{
            format: "%I:%M %p",
            precision: "minute",
            type: "time",
            useUTC: false,
          }}
          yScale={{ type: "linear" }}
          yFormat=">-.2f"
          colors={"#327091"}
          layers={[
            "grid",
            "axes",
            CustomNodes,
            ChartLoader(
              data?.loading,
              data?.error,
              data?.data.length === 0,
              "#70AE71"
            ),
          ]}
          axisTop={null}
          axisRight={null}
          axisLeft={null}
          axisBottom={{
            tickSize: 0,
            tickPadding: 10,
            tickRotation: 0,
            format: "%-I %p",
            tickValues: "every 2 hours",
            legend: "Time",
            legendPosition: "middle",
            legendOffset: 60,
          }}
          enableGridX={false}
          enableGridY={false}
          theme={{
            fontFamily: "Poppins, sans-serif",
            fontSize: 14,
            axis: {
              ticks: {
                text: {
                  fontSize: 14,
                  fontWeight: 500,
                  fill: "#355962",
                },
              },
              legend: {
                text: {
                  fill: "#9CA3AF",
                  fontWeight: 500,
                  fontSize: 14,
                },
              },
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default BloodPressure;

import {
  // ChangeEvent,
  useMemo,
  useState,
} from "react";
import { debounce } from "lodash";
import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
// import UploadIcon from "@mui/icons-material/Upload";

import {
  HeaderLeftContent,
  HeaderRightContent,
  StyledHeader,
} from "../../Common/styles/header";
import { useAppDispatch, useAppSelector } from "../../../Redux/hooks";
import {
  setParticipantsSearchText,
  // setParticipantToggle,
} from "../../../Redux/reducers/participantsSlice";
// import { errorToastMessage, toastMessage } from "../../../utils/toast";
// import http from "../../../utils/http";
import AssignDoctorModal from "./AssignDoctorModal";
// import { downloadStudyNumber } from "../../../utils/download";

const ParticipantHeader = () => {
  const dispatch = useAppDispatch();
  const [loader] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { searchText, type, selectedRows } = useAppSelector(
    (state) => state.participants
  );
  const { hasAdminAccess } = useAppSelector((state) => state.user);

  const modifySearchTerm = useMemo(
    () =>
      debounce((val) => {
        dispatch(setParticipantsSearchText(val));
      }, 500),
    [dispatch]
  );

  // const uploadStudyList = async (e: ChangeEvent<HTMLInputElement>) => {
  //   if (e.target.files && e.target.files.length > 0) {
  //     const file = e.target.files[0];
  //     const isLt5M = file.size / 1024 / 1024 < 5;
  //     if (!isLt5M) {
  //       toastMessage("warning", "File size must smaller than 5MB!");
  //       return false;
  //     }
  //     try {
  //       setLoader(true);
  //       const formData = new FormData();
  //       formData.append("file", file);
  //       await http.post("/participants/import-study-numbers", formData, {
  //         headers: {
  //           "Content-Type": "multipart/form-data",
  //         },
  //       });
  //       toastMessage("success", "Study numbers added successfully");
  //       if (type === "unregistered") {
  //         dispatch(setParticipantToggle());
  //       }
  //       setLoader(false);
  //     } catch (err) {
  //       errorToastMessage(err as Error);
  //       setLoader(false);
  //     }
  //   }
  // };

  const reassignDoctor = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  // const downloadSample = () => {
  //   downloadStudyNumber();
  // };

  return (
    <StyledHeader>
      <Box sx={HeaderLeftContent}>
        <Typography fontSize={30} fontWeight="bold">
          Participants
        </Typography>

        <TextField
          onChange={(e) => modifySearchTerm(e.target.value)}
          style={{ width: "300px" }}
          placeholder="Search for Participants"
          defaultValue={searchText}
          key={type}
        />
      </Box>
      {hasAdminAccess && (
        <Box sx={HeaderRightContent}>
          {loader ? (
            <CircularProgress size={25} />
          ) : (
            <>
              {selectedRows?.length > 0 && (
                <Button
                  variant="outlined"
                  component="label"
                  onClick={reassignDoctor}
                >
                  Assign Doctors
                </Button>
              )}
              {/* <Button variant="contained" onClick={downloadSample}>
                Download Sample CSV
              </Button>
              <Button
                variant="outlined"
                component="label"
                startIcon={<UploadIcon />}
              >
                Upload Study Numbers
                <input
                  hidden
                  accept="text/csv"
                  type="file"
                  onChange={uploadStudyList}
                />
              </Button> */}
            </>
          )}
        </Box>
      )}
      {showModal && hasAdminAccess && (
        <AssignDoctorModal
          showModal={showModal}
          closeModal={closeModal}
          selectedRows={selectedRows}
        />
      )}
    </StyledHeader>
  );
};

export default ParticipantHeader;

import React, { useEffect, useState } from "react";
import {
  Box,
  LinearProgress,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useLocation } from "react-router";
import { DateTime } from "luxon";
import { AxiosResponse } from "axios";
import http from "../../utils/http";
import { errorToastMessage } from "../../utils/toast";
import {
  appointmentMap,
  appointmentPerTimePeriodConstructor,
} from "../../utils/appointment";
import { NoDataContainer, StyledTableCell } from "../Common/styles/table";
import ViewAppointmentModal from "./ViewAppointmentModal";

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const AppointmentsTab = ({ tabType }: any) => {
  const [loading, setLoading] = useState(false);
  const [appointmentsData, setAppointmentsData] = useState([]);
  const inviterId = useQuery().get("id");
  const groupId = useQuery().get("groupId");
  const inviteeId = useQuery().get("inviteeId");
  const [toggle, setToggle] = useState(false);
  const [appointmentId, setAppointmentId] = useState("");

  useEffect(() => {
    const fetchAppointments = async () => {
      try {
        setLoading(true);
        let query = appointmentPerTimePeriodConstructor(tabType);
        query += `&unconfirmed=false&status=confirmed&noPagination=true`;
        if (inviteeId) {
          query += `&inviteeId=${inviteeId}`;
        } else if (groupId) {
          query += `&groupId=${groupId}`;
        } else {
          const userId = inviterId || localStorage.getItem("userId");
          query += `&inviterId=${userId}`;
        }
        let url = `/ap/generic${query}`;
        const res: AxiosResponse = await http.get(url);
        const newData = res.data?.data.map((event: any) => {
          const dateStr = DateTime.fromISO(event.scheduledStartTime).toFormat(
            "dd/LL/yyyy"
          );
          const startStr = DateTime.fromISO(event.scheduledStartTime).toFormat(
            "hh:mm a"
          );
          const timeStr =
            startStr +
            " - " +
            DateTime.fromISO(event.scheduledEndTime).toFormat("hh:mm a");
          const upcoming =
            DateTime.now() <= DateTime.fromISO(event.scheduledStartTime);

          return {
            id: event.id,
            scheduledStartTime: new Date(event.scheduledStartTime),
            scheduledEndTime: new Date(event.scheduledEndTime),
            actualTitle: event.title,
            title: event.title,
            inviters: event.inviters,
            invitees: event.invitees,
            joinLink: event.joinLink,
            notes: event.notes,
            type: event.type,
            repeating: event.repeating || false,
            group: event.participantGroup,
            timeStr,
            dateStr,
            upcoming,
          };
        });
        setAppointmentsData(newData);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        errorToastMessage(err as Error);
      }
    };
    fetchAppointments();
  }, [
    setToggle,
    setAppointmentsData,
    tabType,
    groupId,
    inviteeId,
    toggle,
    inviterId,
  ]);

  const openModal = (id: string) => {
    setAppointmentId(id);
  };

  const closeModal = () => {
    setAppointmentId("");
  };

  const joinLink = (url: string) => {
    window.open(url, "_blank");
  };

  return (
    <Box>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <StyledTableCell>Title</StyledTableCell>
            <StyledTableCell>Type</StyledTableCell>
            <StyledTableCell>Description</StyledTableCell>
            <StyledTableCell>Link</StyledTableCell>
            {tabType !== "day" && <StyledTableCell>Date</StyledTableCell>}
            <StyledTableCell>Start Time</StyledTableCell>
            <StyledTableCell>End Time</StyledTableCell>
          </TableRow>
        </TableHead>
        {!loading && appointmentsData.length > 0 && (
          <TableBody>
            {appointmentsData?.map((row: any) => (
              <TableRow key={row?.id}>
                <StyledTableCell
                  onClick={() => openModal(row?.id)}
                  sx={{ cursor: "pointer" }}
                >
                  {row?.title}
                </StyledTableCell>
                <StyledTableCell>
                  {appointmentMap[row?.type]?.label || "-"}
                </StyledTableCell>
                <StyledTableCell>{row?.notes || "-"}</StyledTableCell>
                <StyledTableCell>
                  {row?.joinLink ? (
                    <span
                      className="link"
                      onClick={() => joinLink(row?.joinLink)}
                    >
                      Join
                    </span>
                  ) : (
                    "-"
                  )}
                </StyledTableCell>
                {tabType !== "day" && (
                  <StyledTableCell>
                    {DateTime.fromJSDate(row?.scheduledStartTime).toFormat(
                      "dd/LL/yyyy"
                    )}
                  </StyledTableCell>
                )}
                <StyledTableCell>
                  {DateTime.fromJSDate(row?.scheduledStartTime).toFormat(
                    "hh:mm a"
                  )}
                </StyledTableCell>
                <StyledTableCell>
                  {DateTime.fromJSDate(row?.scheduledEndTime).toFormat(
                    "hh:mm a"
                  )}
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        )}
      </Table>
      {!loading && appointmentsData.length === 0 && (
        <NoDataContainer>
          <Typography variant="body1" color="gray">
            No Data
          </Typography>
        </NoDataContainer>
      )}
      {loading && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      )}
      {appointmentId && (
        <ViewAppointmentModal id={appointmentId} closeModal={closeModal} />
      )}
    </Box>
  );
};

export default AppointmentsTab;

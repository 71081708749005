import { useState } from "react";
import { useNavigate } from "react-router";
import { NavLink } from "react-router-dom";
import {
  Box,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import axios from "axios";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import http from "../../utils/http";
import { errorToastMessage } from "../../utils/toast";
import { AuthLayout, AuthRadioSelector, SignInWrapper } from "./auth.style";
import { StyledButton } from "../Common/styles/button";
import ForceLogoutModal from "./ForceLogoutModal";
import { useAppDispatch } from "../../Redux/hooks";
import { setUserAuth } from "../../Redux/reducers/userSlice";
import { InputWrapper, LabelStyle } from "../Common/styles/form";

import jwt_decode from "jwt-decode";
import { Logo } from "../Common/assets/AuthIcons";

const schema = yup.object({
  email: yup.string().when("role", {
    is: (val: string) => val !== "doctor",
    then: (schema) =>
      schema
        .email("*Please enter a valid Email ID.")
        .required("*Email ID is Required"),
  }),
  id: yup.string().when("role", {
    is: (val: string) => val === "doctor",
    then: (schema) => schema.required("*Doctor ID is Required"),
  }),
  password: yup
    .string()
    .min(6, "*Password too short")
    .required("*Password is Required"),
  role: yup.string().required("Role is required"),
});

const SignIn = () => {
  const [submitLoader, setSubmitLoader] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [logoutData, setLogoutData] = useState({});
  const [showPassword, setShowPassword] = useState(false);

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const closeModal = () => {
    setShowModal(false);
  };

  const openModal = (data: any) => {
    setLogoutData(data);
    setShowModal(true);
  };

  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const handleSubmit = async (data: any) => {
    try {
      setSubmitLoader(true);
      const body: any = {};
      let url = "/auth/login";
      if (data.role === "doctor") {
        body.username = data.id;
        body.password = data.password;
        body.userAgent = window.navigator.userAgent;
        body.strategy = "code";
      } else {
        body.username = data.email;
        body.password = data.password;
        body.userAgent = window.navigator.userAgent;
        body.strategy = "email";
      }
      const res = await http.post(url, body);
      setSubmitLoader(true);
      const token = res.data.data.accessToken;
      const refreshToken = res.data.data.refreshToken;
      const decoded: any = jwt_decode(token);
      localStorage.setItem("wellfeet-access-token", token);
      localStorage.setItem("wellfeet-refresh-token", refreshToken);
      dispatch(setUserAuth({ authenticated: true, role: decoded.role }));
      // decodeToken();
      navigate("/");
    } catch (err) {
      setSubmitLoader(false);
      if (axios.isAxiosError(err) && err?.response?.status === 409) {
        openModal(data);
      } else {
        errorToastMessage(err as Error);
      }
    }
  };

  return (
    <Box sx={AuthLayout}>
      <Box sx={SignInWrapper}>
        <Box sx={{ display: "flex", justifyContent: "center", mb: 5 }}>
          <Logo />
        </Box>
        <Formik
          initialValues={{
            email: "",
            password: "",
            role: "admin",
            id: "",
          }}
          onSubmit={handleSubmit}
          validationSchema={schema}
        >
          {({ values, errors, touched, setFieldValue, getFieldProps }) => {
            return (
              <Form>
                {values.role === "doctor" ? (
                  <FormControl sx={InputWrapper}>
                    <FormLabel htmlFor="id" sx={LabelStyle}>
                      Your Doctor ID
                    </FormLabel>
                    <TextField
                      fullWidth
                      placeholder="Doctor ID"
                      id="id"
                      error={touched?.id && errors?.id ? true : false}
                      helperText={touched?.id && errors?.id ? errors?.id : " "}
                      {...getFieldProps("id")}
                    />
                  </FormControl>
                ) : (
                  <FormControl sx={InputWrapper}>
                    <FormLabel sx={LabelStyle} htmlFor="email">
                      Your Email
                    </FormLabel>
                    <TextField
                      placeholder="name@example.com"
                      id="email"
                      {...getFieldProps("email")}
                      error={touched?.email && errors?.email ? true : false}
                      helperText={
                        touched?.email && errors?.email ? errors?.email : " "
                      }
                    />
                  </FormControl>
                )}
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="password">
                    Password
                  </FormLabel>
                  <TextField
                    placeholder="Your password"
                    id="password"
                    {...getFieldProps("password")}
                    error={touched?.password && errors?.password ? true : false}
                    helperText={
                      touched?.password && errors?.password
                        ? errors?.password
                        : " "
                    }
                    type={showPassword ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <IconButton
                            onClick={handleClickShowPassword}
                            edge="end"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="role">
                    Role
                  </FormLabel>
                  <RadioGroup
                    sx={AuthRadioSelector}
                    id="role"
                    value={values.role}
                    onChange={(event) =>
                      setFieldValue("role", event.target.value)
                    }
                  >
                    <FormControlLabel
                      value="admin"
                      control={<Radio />}
                      label="Admin"
                    />
                    <FormControlLabel
                      value="doctor"
                      control={<Radio />}
                      label="Doctor"
                    />
                  </RadioGroup>
                </FormControl>
                {!submitLoader ? (
                  <StyledButton variant="contained" type="submit" fullWidth>
                    Sign In
                  </StyledButton>
                ) : (
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <CircularProgress size={25} />
                  </Box>
                )}
                <Box sx={{ textAlign: "center", mt: 2 }}>
                  <NavLink to={"/auth/forgot-password?type=" + values.role}>
                    Forgot password
                  </NavLink>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </Box>
      {showModal && (
        <ForceLogoutModal
          showModal={showModal}
          closeModal={closeModal}
          logoutData={logoutData}
        />
      )}
    </Box>
  );
};

export default SignIn;

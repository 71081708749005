import { DateTime } from "luxon";

export const getCalendarParams = (view: string, date: DateTime) => {
  if (view === "day") {
    return {
      label: date.toFormat("LLLL dd, yyyy"),
      startDate: date.startOf("day"),
      endDate: date.endOf("day"),
    };
  } else if (view === "month") {
    return {
      label: date.toFormat("LLLL yyyy"),
      startDate: date.startOf("month"),
      endDate: date.endOf("month"),
    };
  } else if (view === "week") {
    const startDate = date.startOf("week");
    const endDate = date.endOf("week");
    const startYear = startDate.toFormat("yyyy");
    const endYear = endDate.toFormat("yyyy");
    const sameYear = startYear === endYear;
    const startMonth = startDate.toFormat("LLLL");
    const endMonth = endDate.toFormat("LLLL");

    let str = "";
    if (startMonth === endMonth) {
      str =
        startDate.toFormat("LLLL dd") +
        " - " +
        endDate.toFormat("dd, ") +
        startYear;
    } else {
      str =
        startDate.toFormat("LLLL dd" + (sameYear ? "" : ", yyyy")) +
        " - " +
        endDate.toFormat("LLLL dd, yyyy");
    }
    return {
      label: str,
      startDate,
      endDate,
    };
  } else {
    return null;
  }
};

export const appointmentMap: any = {
  outcome_visit: {
    label: "Outcome visit",
    key: "outcome_visit",
    color: "#1C64F2",
    linkRequired: false,
  },
  clinic_visit: {
    label: "Clinic visit",
    key: "clinic_visit",
    color: "#F05252",
    linkRequired: false,
  },
  exercise_group: {
    label: "Exercise Group (Zoom)",
    key: "exercise_group",
    color: "#7E3AF2",
    linkRequired: true,
  },
  gym_exercise: {
    label: "Gym Exercise",
    key: "gym_exercise",
    color: "#D61F69",
    linkRequired: false,
  },
  cognitive_group_talk: {
    label: "Cognitive Group Talk (Zoom)",
    key: "cognitive_group_talk",
    color: "#FF5A1F",
    linkRequired: true,
  },
  cognitive_training: {
    label: "Cognitive Training (Zoom)",
    key: "cognitive_training",
    color: "#FACA15",
    linkRequired: true,
  },
  diet_group_talk: {
    label: "Diet Group Talk (Zoom)",
    key: "diet_group_talk",
    color: "#0E9F6E",
    linkRequired: true,
  },
  diet_individual_consultation: {
    label: "Diet individual consultation (Zoom)",
    key: "diet_individual_consultation",
    color: "#5850EC",
    linkRequired: true,
  },
  technical_check: {
    label: "Technical check (Zoom)",
    key: "technical_check",
    color: "#4B5563",
    linkRequired: true,
  },
  others: {
    label: "Others",
    key: "others",
    color: "#00B9FC",
    linkRequired: false,
  },
};

export const appointmentPerTimePeriodConstructor: (tab: string) => string = (
  tab
) => {
  const now = DateTime.now();
  let from_date = now.startOf("day");
  let to_date =
    tab === "month"
      ? now.plus({ month: 1 })
      : tab === "week"
      ? now.plus({ week: 1 })
      : now;

  let query = `?tab=${tab}&from_date=${from_date.toUTC().toISO()}`;
  if (tab !== "upcoming") {
    query += `&to_date=${to_date.endOf("day").toUTC().toISO()}`;
  }
  return query;
};

import React, { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  Divider,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
} from "@mui/material";
import { sleepFn } from "../../../../utils/delay";
import { NoDataContainer } from "../../../Common/styles/table";
import { SxProps } from "@mui/material";

interface dataProps {
  date: string;
  outOfRange: string;
  fatBurn: string;
  cardio: string;
  peak: string;
  activeZoneMinutes: string;
}

interface childDataInsightsProps {
  label: string;
  value: string;
}

interface dataInsightsProps {
  title: string;
  data: childDataInsightsProps[];
}

const TableCellHeadStyle: SxProps = {
  fontSize: "14px",
  fontWeight: "600",
  color: "#355962",
  borderBottom: "1px solid #E0E3EB",
};

const TableCellBodyStyle: SxProps = {
  fontSize: "14px",
  fontWeight: "600",
  color: "#637E85",
  borderBottom: "1px solid #E0E3EB",
};

const HeartRateMetrics = () => {
  const [heartRateData, setHeartRateData] = useState<dataProps[]>([]);
  const [dataInsights, setDataInsights] = useState<dataInsightsProps[]>([]);
  const [loader, setLoader] = useState<boolean>(false);

  useEffect(() => {
    const fetchHeartRateData = async () => {
      try {
        setLoader(true);

        await sleepFn(1000);
        setHeartRateData([
          {
            date: "May 11 2023",
            outOfRange: "1439",
            fatBurn: "0",
            cardio: "0",
            peak: "0",
            activeZoneMinutes: "0",
          },
          {
            date: "May 11 2023",
            outOfRange: "1439",
            fatBurn: "0",
            cardio: "0",
            peak: "0",
            activeZoneMinutes: "0",
          },
          {
            date: "May 11 2023",
            outOfRange: "1439",
            fatBurn: "0",
            cardio: "0",
            peak: "0",
            activeZoneMinutes: "0",
          },
          {
            date: "May 11 2023",
            outOfRange: "1439",
            fatBurn: "0",
            cardio: "0",
            peak: "0",
            activeZoneMinutes: "0",
          },
          {
            date: "May 11 2023",
            outOfRange: "1439",
            fatBurn: "0",
            cardio: "0",
            peak: "0",
            activeZoneMinutes: "0",
          },
          {
            date: "May 11 2023",
            outOfRange: "1439",
            fatBurn: "0",
            cardio: "0",
            peak: "0",
            activeZoneMinutes: "0",
          },
        ]);

        setLoader(false);
      } catch (error) {
        setLoader(false);
      }
    };

    fetchHeartRateData();
  }, [setHeartRateData]);

  useEffect(() => {
    const fetchDataInsights = async () => {
      try {
        setLoader(true);

        await sleepFn(1000);
        setDataInsights([
          {
            title: "Average",
            data: [
              {
                label: "Peak Minutes",
                value: "0",
              },
              {
                label: "Cardio Minutes",
                value: "0",
              },
              {
                label: "Fat Burn Minutes",
                value: "0",
              },
              {
                label: "Out of Range Minutes",
                value: "1,147.5",
              },
            ],
          },
          {
            title: "High",
            data: [
              {
                label: "Peak Minutes",
                value: "0",
              },
              {
                label: "Cardio Minutes",
                value: "0",
              },
              {
                label: "Fat Burn Minutes",
                value: "0",
              },
              {
                label: "Out of Range Minutes",
                value: "1,147.5",
              },
            ],
          },
          {
            title: "Low",
            data: [
              {
                label: "Peak Minutes",
                value: "0",
              },
              {
                label: "Cardio Minutes",
                value: "0",
              },
              {
                label: "Fat Burn Minutes",
                value: "0",
              },
              {
                label: "Out of Range Minutes",
                value: "1,147.5",
              },
            ],
          },
          {
            title: "Total",
            data: [
              {
                label: "Total Zone Minutes",
                value: "9,178",
              },
              {
                label: "Total In Range Minutes Minutes",
                value: "0",
              },
              {
                label: "Peak Minutes",
                value: "0",
              },
              {
                label: "Cardio Minutes",
                value: "0",
              },
              {
                label: "Fat Burn Minutes",
                value: "0",
              },
              {
                label: "Out of Range Minutes",
                value: "9,178",
              },
            ],
          },
        ]);

        setLoader(false);
      } catch (error) {
        setLoader(false);
      }
    };

    fetchDataInsights();
  }, [setDataInsights]);

  return (
    <Box display={"flex"} gap={"16px"}>
      <Box
        sx={{
          bgcolor: "white",
          borderRadius: "12px",
          width: "60%",
          height: "100%",
        }}
      >
        <Box p={"24px 16px"}>
          <Typography fontWeight={"medium"} fontSize={"22px"} color="#355962">
            Heart Rate Insights
          </Typography>
        </Box>
        <Divider sx={{ color: "#E5E7EB" }} />
        {!loader ? (
          <>
            <Table>
              <TableHead>
                <TableRow
                  sx={{
                    padding: "20px 16px",
                    bgcolor: "#F9FAFB",
                  }}
                >
                  <TableCell sx={TableCellHeadStyle}>Date</TableCell>
                  <TableCell sx={TableCellHeadStyle}>Out of Range</TableCell>
                  <TableCell sx={TableCellHeadStyle}>Fat Burn</TableCell>
                  <TableCell sx={TableCellHeadStyle}>Cardio</TableCell>
                  <TableCell sx={TableCellHeadStyle}>Peak</TableCell>
                  <TableCell sx={TableCellHeadStyle}>
                    Active Zone Minutes
                  </TableCell>
                </TableRow>
              </TableHead>
              {heartRateData.length > 0 ? (
                <TableBody>
                  {heartRateData?.map((data, i) => (
                    <TableRow
                      key={i}
                      sx={{
                        padding: "20px 16px",
                        borderBottom: "1px solid red",
                      }}
                    >
                      <TableCell sx={TableCellBodyStyle}>
                        {data?.date}
                      </TableCell>
                      <TableCell sx={TableCellBodyStyle}>
                        {data?.outOfRange}
                      </TableCell>
                      <TableCell sx={TableCellBodyStyle}>
                        {data?.fatBurn}
                      </TableCell>
                      <TableCell sx={TableCellBodyStyle}>
                        {data?.cardio}
                      </TableCell>
                      <TableCell sx={TableCellBodyStyle}>
                        {data?.peak}
                      </TableCell>
                      <TableCell sx={TableCellBodyStyle}>
                        {data?.activeZoneMinutes}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              ) : (
                <NoDataContainer>
                  <Typography variant="body1" color="gray">
                    No Data
                  </Typography>
                </NoDataContainer>
              )}
            </Table>
          </>
        ) : (
          <Box sx={{ display: "flex", justifyContent: "center", p: 2 }}>
            <CircularProgress />
          </Box>
        )}
      </Box>

      <Box sx={{ bgcolor: "white", borderRadius: "12px", width: "40%" }}>
        <Box p={"24px 16px"}>
          <Typography fontWeight={"medium"} fontSize={"22px"} color="#355962">
            Blood Pressure Insights
          </Typography>
        </Box>
        <Divider sx={{ color: "#E5E7EB" }} />
        {!loader ? (
          <>
            {dataInsights.length > 0 ? (
              <Box
                sx={{
                  display: "grid",
                  gap: "16px",
                  padding: "16px",
                }}
              >
                {dataInsights.map((data: dataInsightsProps) => {
                  return (
                    <Box
                      key={data.title}
                      sx={{ borderRadius: "12px", border: "1px solid #E0E3EB" }}
                    >
                      <Box p={"16px"}>
                        <Typography
                          sx={{
                            color: "#637E85",
                            fontSize: "14px",
                            fontWeight: "600",
                          }}
                        >
                          {data.title}
                        </Typography>
                      </Box>
                      <Divider sx={{ color: "#E5E7EB" }} />
                      {data.data.map((subData, i) => (
                        <Box p={"0px 16px"}>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              p: "16px 0px",
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: "14px",
                                fontWeight: "400",
                                color: "#637E85",
                              }}
                            >
                              {subData.label}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "18px",
                                fontWeight: "600",
                                color: "#355962",
                              }}
                            >
                              {subData.value}
                            </Typography>
                          </Box>
                          <Divider sx={{ color: "#E5E7EB" }} />
                        </Box>
                      ))}
                    </Box>
                  );
                })}
              </Box>
            ) : (
              <NoDataContainer>
                <Typography variant="body1" color="gray">
                  No Data
                </Typography>
              </NoDataContainer>
            )}
          </>
        ) : (
          <Box sx={{ display: "flex", justifyContent: "center", p: 2 }}>
            <CircularProgress />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default HeartRateMetrics;

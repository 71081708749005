import React from "react";
import { useAppSelector } from "../../../Redux/hooks";
import BloodGlucoseMetrics from "./Block/BloodGlucoseMetrics";
import Medication from "./Block/Medication";
import BloodPressureMetrics from "./Block/BloodPressureMetrics";
import HeartRateMetrics from "./Block/HeartRateMetrics";

const HealthMetrics: React.FC = () => {
  const { type } = useAppSelector((state) => state.health);

  switch (type) {
    case "blood_glucose":
      return <BloodGlucoseMetrics />;
    case "medication":
      return <Medication />;
    case "blood_pressure":
      return <BloodPressureMetrics />;
    case "heart_rate":
      return <HeartRateMetrics />;
    default:
      return null;
  }
};

export default HealthMetrics;

import { MoreVertRounded } from "@mui/icons-material";
import { Avatar, Box, IconButton, Typography } from "@mui/material";

import {
  ModuleCardStyle,
  ExerciseDetails,
  CardMainText,
  CardAvatar,
} from "../ExerciseCollection.style";

import PhotoSizeSelectActualOutlinedIcon from "@mui/icons-material/PhotoSizeSelectActualOutlined";
import { Dot } from "../Icons";

type Props = {
  module: any;
  openMenu: Function;
  handleAction: Function;
};

const ExerciseCollectionCard: React.FC<Props> = ({
  module,
  openMenu,
  handleAction,
}) => {
  return (
    <>
      <Box sx={ModuleCardStyle} onClick={() => handleAction("click", module)}>
        <Avatar src={module?.imageUrl} sx={CardAvatar}>
          <PhotoSizeSelectActualOutlinedIcon
            sx={{ width: "45px", height: "45px" }}
          />
        </Avatar>
        <Box sx={ExerciseDetails}>
          <Typography fontSize={"24px"} fontWeight="medium" sx={CardMainText}>
            {module?.title}
          </Typography>
          {/* <Box>
            <Typography
              variant="subtitle1"
              color="#4b5563"
              display={"flex"}
              alignItems={"center"}
              gap={"8px"}
            >
              90 mins <Dot /> All
            </Typography>
          </Box> */}
        </Box>
        <Box sx={{ ml: "auto" }}>
          <IconButton onClick={(e) => openMenu(e, module)}>
            <MoreVertRounded />
          </IconButton>
        </Box>
      </Box>
    </>
  );
};

export default ExerciseCollectionCard;

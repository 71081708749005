import { useRef, useState } from "react";
import { ContentCopy, Delete, MoreVert } from "@mui/icons-material";
import {
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";

import { useAppDispatch, useAppSelector } from "../../../../Redux/hooks";
import { CbBuilderStep } from "../../../../types/chatbotBuilder";
import { useDrag, useDrop, XYCoord } from "react-dnd";
import {
  deleteStep,
  duplicateStep,
  handleMoveStep,
  setActiveStepIndex,
} from "../../../../Redux/reducers/chatbotBuilderSlice";
import {
  CbBuilderStepTypes,
  getStepLabelFromType,
  CbBuilderStepIconMap,
} from "../../../../utils/chatbotBuilder";
import { StepCardSelectedStyle, StepCardStyle } from "../chatbotBuilder.style";
import { ShuffleIcon } from "../Icons";

type Props = {
  index: number;
  step: CbBuilderStep;
};

interface DragItem {
  index: number;
  id: string;
  type: string;
}

const StepCard = ({ step, index }: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const { activeStepIndex, editable } = useAppSelector(
    (state) => state.chatbotBuilder
  );

  const Icon = CbBuilderStepIconMap[step?.type];

  const [, drop] = useDrop(
    () => ({
      accept: CbBuilderStepTypes.map((step) => step.type),
      hover(item: DragItem, monitor) {
        if (!ref.current) {
          return;
        }
        const dragIndex = item.index;
        const dropIndex = index;

        if (dragIndex === dropIndex) {
          return;
        }

        const hoverBoundingRect = ref.current?.getBoundingClientRect();

        const hoverMiddleY =
          (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

        const clientOffset = monitor.getClientOffset();

        const hoverClientY =
          (clientOffset as XYCoord).y - hoverBoundingRect.top;
        if (dragIndex < dropIndex && hoverClientY < hoverMiddleY) {
          return;
        }
        if (dragIndex > dropIndex && hoverClientY > hoverMiddleY) {
          return;
        }
        dispatch(handleMoveStep({ dragIndex, dropIndex }));
        item.index = dropIndex;
      },
    }),
    [index]
  );

  const [, drag] = useDrag(() => {
    return {
      type: step.type,
      item: {
        index: index,
      },
    };
  }, [index, step.type]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const deleteItem = () => {
    handleClose();
    dispatch(deleteStep());
  };

  const duplicateItem = () => {
    handleClose();
    dispatch(duplicateStep());
  };

  const selectItem = (index: number) => {
    dispatch(setActiveStepIndex({ index }));
  };

  drag(drop(ref));

  return (
    <>
      <Box
        sx={index === activeStepIndex ? StepCardSelectedStyle : StepCardStyle}
        ref={ref}
        key={step?.id}
        onClick={() => selectItem(index)}
      >
        {editable && (
          <Box sx={{ flexShrink: 0 }}>
            <ShuffleIcon />
          </Box>
        )}
        {Icon ? (
          <Box sx={{ flexShrink: 0 }}>
            <Icon />
          </Box>
        ) : null}
        <Typography noWrap variant="subtitle1">
          {step?.name || getStepLabelFromType(step?.type)}
        </Typography>
        {editable && (
          <IconButton
            onClick={handleClick}
            sx={{ marginLeft: "auto", flexShrink: 0 }}
          >
            <MoreVert />
          </IconButton>
        )}
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            minWidth: "225px",
          },
        }}
      >
        <MenuItem key="duplicate" onClick={duplicateItem}>
          <ListItemIcon>
            <ContentCopy fontSize="small" />
          </ListItemIcon>
          <ListItemText>Duplicate</ListItemText>
        </MenuItem>
        <MenuItem key="delete" onClick={deleteItem}>
          <ListItemIcon>
            <Delete fontSize="small" color="error" />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};

const StepCards = () => {
  const { steps } = useAppSelector((state) => state.chatbotBuilder);

  return (
    <>
      {steps.map((step, index) => (
        <StepCard key={step?.id} step={step} index={index} />
      ))}
    </>
  );
};

export default StepCards;

import React, { useCallback, useEffect, useState } from "react";
import { Calendar, View, luxonLocalizer } from "react-big-calendar";
import { DateTime } from "luxon";
import {
  Box,
  Button,
  MenuItem,
  Select,
  Typography,
  IconButton,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { AxiosResponse } from "axios";
import { errorToastMessage } from "../../utils/toast";
import http from "../../utils/http";
import { appointmentMap, getCalendarParams } from "../../utils/appointment";
import { CalendarWrapper, SearchInput } from "./styles";
import ViewAppointmentModal from "./ViewAppointmentModal";
import {
  HeaderLeftContent,
  HeaderRightContent,
  StyledHeader,
} from "../Common/styles/header";
import "./appointments.scss";

const localizer = luxonLocalizer(DateTime, { firstDayOfWeek: 1 });

function formatTime12h(date: any) {
  return DateTime.fromJSDate(date).toFormat("hh:mm a");
}

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Appointments = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [view, setView] = useState<any>(searchParams.get("period") || "month");
  const [loader, setLoader] = useState(false);
  const [events, setEvents] = useState<any>([]);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [currentDateString, setCurrentDateString] = useState(
    DateTime.local().toFormat("LLLL yyyy")
  );
  const [appointmentId, setAppointmentId] = useState("");
  const name = useQuery().get("name");
  const inviterId = useQuery().get("id");
  const groupId = useQuery().get("groupId");
  const inviteeId = useQuery().get("inviteeId");
  const navigate = useNavigate();

  const viewHandler = (val: View) => {
    if (["month", "week", "day"].includes(val)) {
      setView(val);
    }
  };

  useEffect(() => {
    const params = new URLSearchParams();
    if (view) {
      params.set("period", view);
    }
    if (name) {
      params.set("name", name);
    }
    if (inviterId) {
      params.set("id", inviterId);
    }
    if (groupId) {
      params.set("groupId", groupId);
    }
    if (inviteeId) {
      params.set("inviteeId", inviteeId);
    }
    setSearchParams(params, {
      replace: true,
    });
  }, [view, name, inviterId, groupId, inviteeId, setSearchParams]);

  useEffect(() => {
    const fetchAppointments = async () => {
      try {
        setLoader(true);
        const date = DateTime.fromJSDate(currentDate);
        const params = getCalendarParams(view, date);
        if (params) {
          const { label, startDate, endDate } = params;
          let query = `?unconfirmed=false&status=confirmed&noPagination=true`;
          if (inviteeId) {
            query += `&inviteeId=${inviteeId}`;
          } else if (groupId) {
            query += `&groupId=${groupId}`;
          } else {
            const userId = inviterId || localStorage.getItem("userId");
            query += `&inviterId=${userId}`;
          }
          query += `&from_date=${startDate.toUTC().toISO()}&to_date=${endDate
            .toUTC()
            .toISO()}&tab=${view}`;
          let url = `/ap/generic${query}`;
          const res: AxiosResponse = await http.get(url);
          const sanitizedEvents = res.data.data.map((event: any) => {
            const dateStr = DateTime.fromISO(event.scheduledStartTime).toFormat(
              "dd/LL/yyyy"
            );
            const startStr = DateTime.fromISO(
              event.scheduledStartTime
            ).toFormat("hh:mm a");
            const timeStr =
              startStr +
              " - " +
              DateTime.fromISO(event.scheduledEndTime).toFormat("hh:mm a");
            const upcoming =
              DateTime.now() <= DateTime.fromISO(event.scheduledStartTime);
            return {
              id: event.id,
              scheduledStartTime: new Date(event.scheduledStartTime),
              scheduledEndTime: new Date(event.scheduledEndTime),
              actualTitle: event.title,
              title: (view === "month" ? startStr + " " : "") + event.title,
              inviters: event.inviters,
              invitees: event.invitees,
              joinLink: event.joinLink,
              notes: event.notes,
              type: event.type,
              repeating: event.repeating || false,
              group: event.participantGroup,
              timeStr,
              dateStr,
              upcoming,
            };
          });
          setEvents(sanitizedEvents);
          setCurrentDateString(label);
        }

        setLoader(false);
      } catch (err) {
        errorToastMessage(err as Error);
        setLoader(false);
      }
    };

    fetchAppointments();
  }, [
    currentDate,
    view,
    setCurrentDateString,
    setEvents,
    inviterId,
    groupId,
    inviteeId,
  ]);

  const openListView = () => {
    let url = `/app/appointments/list?`;
    if (inviteeId) {
      url += `name=${name}&inviteeId=${inviteeId}`;
    }
    if (groupId) {
      url += `name=${name}&groupId=${groupId}`;
    }
    if (inviterId) {
      url += `name=${name}&id=${inviterId}`;
    }
    navigate(url);
  };

  const navigateCalendar = (offset: number) => {
    const date = DateTime.fromJSDate(currentDate).plus({ [view]: offset });
    setCurrentDate(date.toJSDate());
  };

  const eventPropGetter = useCallback((event: any) => {
    return {
      style: {
        backgroundColor: appointmentMap[event.type]?.color || "#00b9fc",
      },
    };
  }, []);

  const drillDownHandler = (date: Date, view: View) => {
    if (date && ["month", "week", "day"].includes(view)) {
      setCurrentDate(date);
      //@ts-ignore
      setView(view);
    }
  };

  const setToday = () => {
    const today = DateTime.local();
    setCurrentDate(today.toJSDate());
  };

  const onSelectEvent = (event: any) => {
    setAppointmentId(event?.id);
  };

  const closeModal = () => {
    setAppointmentId("");
  };

  return (
    <>
      {loader && (
        <Backdrop
          open={true}
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <StyledHeader>
        <Box sx={HeaderLeftContent}>
          <Typography fontSize={30} fontWeight="bold">
            Your appointments
          </Typography>
          <Button variant="outlined" onClick={setToday}>
            Today
          </Button>
          <Box>
            <IconButton onClick={() => navigateCalendar(-1)}>
              <ChevronLeft fontSize="large" />
            </IconButton>
            <IconButton onClick={() => navigateCalendar(1)}>
              <ChevronRight fontSize="large" />
            </IconButton>
          </Box>
          <Typography fontSize={30} fontWeight="bold">
            {currentDateString}
          </Typography>
        </Box>
        <Box sx={HeaderRightContent}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="subtitle1" fontWeight={"medium"} mr={1.25}>
              Period
            </Typography>
            <Select
              value={view}
              sx={SearchInput}
              onChange={(e) => viewHandler(e.target.value)}
            >
              <MenuItem key="day" value="day">
                Day
              </MenuItem>
              <MenuItem key="week" value="week">
                Week
              </MenuItem>
              <MenuItem key="month" value="month">
                Month
              </MenuItem>
            </Select>
          </Box>
          <Button variant="outlined" onClick={openListView}>
            List View
          </Button>
        </Box>
      </StyledHeader>
      <Box sx={CalendarWrapper}>
        <Calendar
          localizer={localizer}
          date={currentDate}
          events={events}
          startAccessor="scheduledStartTime"
          endAccessor="scheduledEndTime"
          view={view}
          onView={viewHandler}
          onNavigate={() => {}}
          toolbar={false}
          eventPropGetter={eventPropGetter}
          onDrillDown={drillDownHandler}
          onSelectEvent={onSelectEvent}
          formats={{
            timeGutterFormat: (date) => formatTime12h(date),
            eventTimeRangeFormat: ({ start, end }) => {
              const startTime = formatTime12h(start);
              const endTime = formatTime12h(end);
              return `${startTime} - ${endTime}`;
            },
          }}
        />
      </Box>
      {appointmentId && (
        <ViewAppointmentModal closeModal={closeModal} id={appointmentId} />
      )}
    </>
  );
};

export default Appointments;

import { Box, Typography } from "@mui/material";
import { ResponsiveBar } from "@nivo/bar";
import { CustomTooltip } from "../../Common/UI/Tooltip";
import { ChartContainer } from "../dashboard.style";

type Props = {
  data: Array<{ label: string; medium: number; high: number; low: number }>;
  tickValue: number;
};

const HealthProgress = ({ data, tickValue }: Props) => {
  return (
    <Box mb={3}>
      <Typography variant="h6" fontWeight="medium" color="#355962" mb={3}>
        Health Progress
      </Typography>
      <Box sx={ChartContainer}>
        <ResponsiveBar
          data={data}
          keys={["medium", "high", "low"]}
          colors={["#327091", "#83C1CE", "#DEEFF2"]}
          indexBy="label"
          margin={{ top: 80, right: 60, bottom: 70, left: 60 }}
          padding={0.75}
          valueScale={{ type: "linear" }}
          indexScale={{ type: "band", round: true }}
          theme={{
            fontSize: 14,
            axis: {
              ticks: {
                text: {
                  fontWeight: 600,
                  fontSize: 14,
                  fill: "#637E85",
                },
              },
            },
          }}
          gridYValues={tickValue || undefined}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 0,
            tickPadding: 16,
            tickRotation: 0,
          }}
          axisLeft={{
            tickSize: 0,
            tickPadding: 10,
            tickRotation: 0,
            tickValues: tickValue || undefined,
            renderTick: ({ x, y, rotate, textAnchor, value }) => (
              <text
                x={x}
                y={y}
                fill="#ADAFB1"
                textAnchor={textAnchor}
                dominantBaseline="middle"
                transform={`translate(20 ,-15) rotate(${rotate})`}
                fontSize={13}
                fontWeight={500}
              >
                {value}
              </text>
            ),
          }}
          enableLabel={false}
          legends={[
            {
              dataFrom: "keys",
              data: [
                {
                  id: 0,
                  label: "In Range",
                  color: "#327091",
                },
                {
                  id: 1,
                  label: "High",
                  color: "#82C1CE",
                },
                {
                  id: 2,
                  label: "Low",
                  color: "#DEEFF2",
                },
              ],
              anchor: "top",
              direction: "row",
              justify: false,
              translateY: -60,
              itemsSpacing: 2,
              itemWidth: 100,
              itemHeight: 20,
              itemDirection: "left-to-right",
              itemTextColor: "#355962",
              symbolSize: 16,
              symbolShape: "circle",
            },
          ]}
          tooltip={({ value, color }: any) => {
            const label =
              color === "#327091"
                ? "In Range"
                : color === "#83C1CE"
                ? "High"
                : "Low";
            return <CustomTooltip value={value} color={color} label={label} />;
          }}
        />
      </Box>
    </Box>
  );
};

export default HealthProgress;

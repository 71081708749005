import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Breadcrumbs,
  Link,
  Typography,
  Divider,
  IconButton,
  CircularProgress,
  Button,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  LinkProps,
} from "@mui/material";
import {
  foodContainer,
  headerContainer,
  journalContainer,
  journalWrapper,
} from "./style";
import MedicationJournal from "./MedicationJournal";
// import FootJournal from "./FootJournal";
import { ExpandMore } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { errorToastMessage } from "../../../utils/toast";
import { CalendarIcon } from "../../Common/assets/FoodJournalIcons";
import { DatePicker } from "@mui/x-date-pickers";
import { DateTime } from "luxon";
import {
  useLocation,
  useParams,
  useSearchParams,
  Link as RouterLink,
} from "react-router-dom";
import { AxiosResponse } from "axios";
import http from "../../../utils/http";
import Sleep from "./Sleep";
import Steps from "./Steps";
import Food from "./Food";
import NotificationModal from "./NotificationModal";
import { useAppSelector } from "../../../Redux/hooks";
import Bp from "./Bp";
import Weight from "./Weight";
import Hb from "./Hb";
import BloodGlucose from "./BloodGlucose";
// import PFB from "./PFB";

const menuItems = [
  {
    label: "Food",
    value: "food",
  },
  {
    label: "Medication",
    value: "med",
  },
  {
    label: "Feet",
    value: "feet",
  },
];

interface LinkRouterProps extends LinkProps {
  to: string;
  replace?: boolean;
}

function LinkRouter(props: LinkRouterProps) {
  return <Link {...props} component={RouterLink as any} />;
}

const Journal = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>([]);
  const [showCalendar, setShowCalendar] = useState(false);
  const [endDate, setEndDate] = useState<any>(
    searchParams.get("date") &&
      DateTime.fromFormat(searchParams.get("date") || "", "dd-LL-yyyy").isValid
      ? DateTime.fromFormat(searchParams.get("date") || "", "dd-LL-yyyy")
      : DateTime.now()
  );

  const [filters, setFilters] = useState(
    searchParams.get("selected")?.split(",") || []
  );
  const [selectedOptions, setSelectedOptions] = useState<any>(filters);
  const [filterEl, setFilterEl] = useState<null | HTMLElement>(null);
  const openFilter = Boolean(filterEl);
  const [expanded, setExpanded] = useState<string | false>(false);
  const [showNotifModal, setShowNotifModal] = useState(false);
  const { role } = useAppSelector((state) => state.user);

  const location = useLocation();
  const user = location.state?.user || "";

  const [studyNumber] = useState(searchParams.get("user") || user);

  const { id } = useParams();

  useEffect(() => {
    const params = new URLSearchParams();

    if (studyNumber) {
      params.set("user", studyNumber);
    }

    if (endDate) {
      params.set("date", endDate?.toFormat("dd-LL-yyyy"));
    }
    if (filters.length > 0) {
      params.set("selected", filters.join(","));
    }

    setSearchParams(params, {
      replace: true,
    });
  }, [endDate, filters, setSearchParams, studyNumber]);

  useEffect(() => {
    const fetchJournalDetails = async () => {
      try {
        setLoading(true);
        let startDate = endDate?.minus({ day: 6 }).startOf("day");
        const res: AxiosResponse = await http.get(
          `/activity/all?userId=${id}&startDate=${startDate
            .toUTC()
            .toISO()}&endDate=${endDate.endOf("day").toUTC().toISO()}`
        );
        const data = res.data.data;

        const values = Object.keys(data)
          .sort()
          .reverse()
          .map((key: any) => {
            return {
              ...data[key],
              date: DateTime.fromFormat(key || "", "yyyy-LL-dd").toFormat(
                "dd LLLL yyyy"
              ),
              day: DateTime.fromFormat(key || "", "yyyy-LL-dd").weekdayLong,
            };
          });
        setData(values);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        errorToastMessage(err as Error);
      }
    };

    fetchJournalDetails();
  }, [endDate, filters, id]);

  const handleChange =
    (panel: string) => (_: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  // const openFilterMenu = (event: React.MouseEvent<HTMLElement>) => {
  //   setFilterEl(event?.currentTarget);
  // };

  const handleFilter = (value: string) => {
    if (selectedOptions.includes(value)) {
      setSelectedOptions((prev: any) =>
        prev.filter((item: string) => item !== value)
      );
    } else {
      setSelectedOptions((prev: any) => [...prev, value]);
    }
  };

  const clearFilter = () => {
    setSelectedOptions([]);
    setFilters([]);
    setFilterEl(null);
  };

  const closeFilterMenu = () => {
    setFilterEl(null);
    if (
      filters.length !== selectedOptions.length ||
      JSON.stringify(filters.sort()) !== JSON.stringify(selectedOptions.sort())
    ) {
      setFilters(selectedOptions);
    }
  };

  const openNotifModal = () => {
    setShowNotifModal(true);
  };

  const closeNotifModal = () => {
    setShowNotifModal(false);
  };

  return (
    <Box sx={journalWrapper}>
      {!loading ? (
        <Box sx={journalContainer}>
          <Box sx={headerContainer}>
            <Breadcrumbs>
              <LinkRouter
                underline="none"
                color="#111928"
                variant="h6"
                fontWeight="medium"
                to={"/app/participants"}
              >
                My Patients
              </LinkRouter>
              <LinkRouter
                underline="none"
                variant="body1"
                fontWeight="medium"
                color="#111928"
                to={`/app/participants/${id}`}
              >
                {studyNumber || ""}
              </LinkRouter>
              <Link
                underline="none"
                variant="body1"
                fontWeight="medium"
                color="#EB8900"
              >
                Journal
              </Link>
            </Breadcrumbs>
            <Box sx={{ display: "flex", gap: 2, mr: 2, alignItems: "center" }}>
              {/* <Button
                startIcon={<FilterIcon />}
                variant="outlined"
                onClick={openFilterMenu}
              >
                Filter
              </Button> */}
              <Typography variant="body1" fontWeight={"medium"}>
                Start Date :{" "}
                <Typography component={"span"} fontWeight={"regular"}>
                  {endDate?.minus({ day: 6 }).toFormat("dd-LL-yyyy")}
                </Typography>
              </Typography>
              <Typography variant="body1" fontWeight={"medium"}>
                End Date :{" "}
                <Typography component={"span"} fontWeight={"regular"}>
                  {endDate?.toFormat("dd-LL-yyyy")}
                </Typography>
              </Typography>
              <DatePicker
                inputFormat="dd/MM/yyyy"
                value={endDate}
                open={showCalendar}
                disableFuture
                onClose={() => setShowCalendar(false)}
                onChange={(newValue) => setEndDate(newValue)}
                renderInput={({ inputRef }) => (
                  <IconButton
                    ref={inputRef}
                    onClick={() => setShowCalendar(true)}
                  >
                    <CalendarIcon />
                  </IconButton>
                )}
              />
              {role === "doctor" && (
                <Button variant="contained" onClick={openNotifModal}>
                  Send Notification
                </Button>
              )}
            </Box>
          </Box>
          {/* <PFB /> */}
          {data.length ? (
            data?.map((item: any, index: number) => {
              return (
                <Accordion
                  key={index}
                  TransitionProps={{ unmountOnExit: true }}
                  expanded={expanded === `panel${index}`}
                  onChange={handleChange(`panel${index}`)}
                  sx={{
                    boxShadow: "none",
                    border: "1px solid #E5E7EB",
                    borderRadius: "8px !important",
                    mb: 2.5,
                  }}
                >
                  <AccordionSummary
                    expandIcon={
                      <IconButton>
                        <ExpandMore sx={{ fontSize: 24 }} />
                      </IconButton>
                    }
                    sx={{
                      ".Mui-expanded": {
                        m: "12px 0px !important",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        py: 1.5,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <Box>
                        <Typography
                          variant="h6"
                          color={
                            expanded !== `panel${index}` ? "#111928" : "#EB8900"
                          }
                          mb={0.5}
                        >
                          {item?.date}
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          fontWeight="regular"
                          color={
                            expanded !== `panel${index}` ? "#6B7280" : "#EB8900"
                          }
                          sx={{ textTransform: "capitalize" }}
                        >
                          {item?.day}
                        </Typography>
                      </Box>
                      {/* <Button
                      startIcon={<Edit />}
                      variant="outlined"
                      onClick={(e) => e.stopPropagation()}
                      sx={{ mr: 1 }}
                    >
                      Edit Logs
                    </Button> */}
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails sx={{ p: 0 }}>
                    <Divider />
                    <Food food={item?.food} />
                    <Divider />
                    <Box sx={foodContainer}>
                      <Sleep data={item?.sleep?.[0]} />
                      <Steps data={item?.step?.[0]} />
                    </Box>
                    <Divider />
                    <Box sx={foodContainer}>
                      <Bp data={item?.bp || []} />
                      <Weight data={item?.weight?.[0]} />
                    </Box>
                    <Divider />
                    <Box sx={foodContainer}>
                      <BloodGlucose data={item?.glucose || []} />
                      <Hb data={item?.hba1c || []} />
                    </Box>
                    <Divider />
                    <Box sx={foodContainer}>
                      {/* <FootJournal feet={item?.feet[0]} /> */}
                      <MedicationJournal meds={item?.medication} />
                    </Box>
                  </AccordionDetails>
                </Accordion>
              );
            })
          ) : (
            <Box sx={{ display: "flex", justifyContent: "center", p: 2 }}>
              <Typography
                variant="subtitle1"
                fontWeight="regular"
                color={"gray"}
              >
                No Data found for this date range
              </Typography>
            </Box>
          )}
        </Box>
      ) : (
        <Box
          sx={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      )}
      <Menu
        anchorEl={filterEl}
        open={openFilter}
        onClose={closeFilterMenu}
        PaperProps={{
          style: {
            width: "300px",
          },
        }}
      >
        {menuItems?.map((item: any) => (
          <MenuItem key={item?.value} onClick={() => handleFilter(item?.value)}>
            <ListItemIcon>
              <Checkbox checked={selectedOptions.includes(item?.value)} />
            </ListItemIcon>
            <ListItemText>{item?.label}</ListItemText>
          </MenuItem>
        ))}
        <Box
          sx={{
            display: "flex",

            justifyContent: "space-evenly",
            position: "sticky",
            bottom: 0,
            bgcolor: "#fff",
            p: 1,
          }}
        >
          <Button variant="text" onClick={clearFilter}>
            Reset Filters
          </Button>
          <Button variant="contained" onClick={closeFilterMenu}>
            Show Results
          </Button>
        </Box>
      </Menu>
      {showNotifModal && (
        <NotificationModal
          userId={id}
          closeModal={closeNotifModal}
          showModal={showNotifModal}
        />
      )}
    </Box>
  );
};

export default Journal;

import { Box, Divider, SxProps } from "@mui/material";
import { StyledTab, StyledTabs, a11yProps } from "../../../Common/UI/TabPanel";
import { useEffect, useState } from "react";
import { ResponsiveBar } from "@nivo/bar";
import { errorToastMessage } from "../../../../utils/toast";
import { sleepFn } from "../../../../utils/delay";
import ChartLoader from "./ChartLoader";

const container: SxProps = {
  borderRadius: "12px",
  backgroundColor: "#ffffff",
  boxShadow: "0px 0px 12px 0px rgba(0, 0, 0, 0.08)",
  mb: 3,
  height: "450px",
  width: "100%",
  overflow: "hidden",
  display: "flex",
  flexDirection: "column",
};

const tabs = [
  {
    key: "steps",
    label: "Steps/Day",
  },
  {
    key: "km",
    label: "Kilometers/Day",
  },
  {
    key: "miles",
    label: "Miles/Day",
  },
  {
    key: "hour",
    label: "Hour-by-Hour",
  },
  {
    key: "avg",
    label: "Average Week",
  },
  {
    key: "floor",
    label: "Floors",
  },
];

const sampleData = [
  {
    date: "22/09/2023",
    value: 700,
  },
  {
    date: "23/09/2023",
    value: 500,
  },
  {
    date: "24/09/2023",
    value: 800,
  },
  {
    date: "25/09/2023",
    value: 500,
  },
  {
    date: "26/09/2023",
    value: 100,
  },
  {
    date: "27/09/2023",
    value: 500,
  },
  {
    date: "28/09/2023",
    value: 500,
  },
  {
    date: "29/09/2023",
    value: 100,
  },
];

const Sleep: React.FC = () => {
  const [type, setType] = useState("steps");
  const [data, setData] = useState({
    data: [] as any[],
    xAxis: "",
    yAxis: "",
    loading: true,
    error: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        setData({
          error: "",
          loading: true,
          xAxis: "Day",
          data: [],
          yAxis: "Average",
        });
        await sleepFn(200);
        setData({
          error: "",
          loading: false,
          xAxis: "Day",
          data: sampleData,
          yAxis: "Steps",
        });
      } catch (err) {
        setData((prev) => {
          return {
            ...prev,
            error: "Something went wrong",
            loading: false,
            data: [],
          };
        });
        errorToastMessage(err as Error);
      }
    };
    fetchData();
  }, [setData, type]);

  const tabChange = (_1: any, val: string) => {
    setType(val);
  };

  return (
    <Box sx={container}>
      <StyledTabs value={type} onChange={tabChange}>
        {tabs.map((tab, index) => (
          <StyledTab
            key={tab.key}
            label={tab.label}
            value={tab.key}
            {...a11yProps(index)}
          />
        ))}
      </StyledTabs>
      <Divider sx={{ color: "#E5E7EB" }} />
      <Box sx={{ flex: 1, minHeight: "1px", p: 4 }}>
        <ResponsiveBar
          theme={{
            fontFamily: "Poppins, sans-serif",
            axis: {
              domain: {
                line: {
                  stroke: "#E0E3EB",
                  strokeWidth: 1,
                },
              },
              ticks: {
                text: {
                  fontSize: 14,
                  fill: "#355962",
                  fontWeight: 500,
                },
              },
              legend: {
                text: {
                  fontSize: 14,
                  fill: "#9CA3AF",
                  fontWeight: 500,
                },
              },
            },
            grid: {
              line: {
                stroke: "#E0E3EB",
                strokeDasharray: "4 4",
              },
            },
          }}
          data={data.data}
          indexBy={"date"}
          keys={["value"]}
          margin={{ top: 10, right: 10, bottom: 60, left: 60 }}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            legend: data.xAxis,
            legendPosition: "middle",
            legendOffset: 50,
            tickSize: 0,
          }}
          axisLeft={{
            legend: data.yAxis,
            legendPosition: "middle",
            legendOffset: -50,
            tickSize: 0,
          }}
          colors={"#70AE71"}
          padding={0.85}
          enableLabel={false}
          layers={[
            "grid",
            "axes",
            "bars",
            "markers",
            "legends",
            "annotations",
            ChartLoader(
              data.loading,
              data.error,
              data.data.length === 0,
              "#70AE71"
            ),
          ]}
        />
      </Box>
    </Box>
  );
};

export default Sleep;

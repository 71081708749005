import { Box, Typography } from "@mui/material";
import { BpContainer, TimeBadge, footContainerWrapper } from "./style";
import { GlucoseIcon } from "../../Common/assets/FoodJournalIcons";
import { DateTime } from "luxon";

type Props = {
  data: any[];
};

const BloodGlucose: React.FC<Props> = ({ data }) => {
  return (
    <Box sx={footContainerWrapper}>
      <Typography variant="subtitle2" fontWeight={600} mb={2}>
        Blood Glucose
      </Typography>

      {data.length > 0 ? (
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: "20px",
            alignItems: "center",
          }}
        >
          {data.map((entry: any) => {
            return (
              <Box sx={BpContainer} key={entry.id}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1.75,
                    p: 1.75,
                    width: "100%",
                  }}
                >
                  <GlucoseIcon />
                  <Box>
                    <Typography
                      variant="h6"
                      color="#212121"
                      fontWeight="medium"
                    >
                      {entry.glucoseLevel}
                      <Typography
                        component="span"
                        variant="body1"
                        fontWeight="regular"
                        color="#676970"
                      >
                        {" "}
                        mg/dL
                      </Typography>
                    </Typography>
                    <Typography sx={TimeBadge}>
                      {DateTime.fromISO(entry?.utc).toFormat("hh:mm a")}
                    </Typography>
                    {entry.readingType && (
                      <Typography
                        sx={{
                          ...TimeBadge,
                          textTransform: "capitalize",
                          mt: 1,
                        }}
                      >
                        {entry.readingType}
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Box>
            );
          })}
        </Box>
      ) : (
        <Box sx={{ ...BpContainer, maxWidth: "100%" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1.75,
              p: 1.75,
            }}
          >
            <GlucoseIcon />
            <Typography variant="subtitle1" fontWeight="regular" color={"gray"}>
              Data Unavailable
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default BloodGlucose;

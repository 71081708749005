const downloadFile = (csvContent: string, fileName: string) => {
  const encodedUri = encodeURI(csvContent);
  const link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", fileName + ".csv");
  document.body.appendChild(link);
  link.click();
};

export const downloadDocSample = () => {
  const rows = [
    ["study_number", "password"],
    ["i-doctor-1", "Doctor@123"],
    ["i-doctor-2", "Doctor@123"],
    ["i-doctor-3", "Doctor@123"],
    ["i-doctor-4", "Doctor@123"],
    ["i-doctor-5", "Doctor@123"],
  ];
  const csvContent =
    "data:text/csv;charset=utf-8," + rows.map((e) => e.join(",")).join("\n");
  downloadFile(csvContent, "doc_import");
};

export const downloadStudyNumber = () => {
  const rows = [
    ["study_number", "doctor_username"],
    ["i-study-1", "Doctor002"],
    ["i-study-2", "Doctor002"],
    ["i-study-3", "Doctor002"],
    ["i-study-4", "Doctor002"],
    ["i-study-5", "Doctor002"],
  ];
  const csvContent =
    "data:text/csv;charset=utf-8," + rows.map((e) => e.join(",")).join("\n");
  downloadFile(csvContent, "study_number_import");
};

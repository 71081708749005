import { useEffect, useMemo, useState } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import { Add } from "@mui/icons-material";
import { debounce } from "lodash";
import { useSearchParams } from "react-router-dom";
import {
  HeaderLeftContent,
  HeaderRightContent,
  StyledHeader,
} from "../Common/styles/header";
import MedicationList from "./MedicationList";

const Medication = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchText, setSearchText] = useState(
    searchParams.get("search") || ""
  );
  const [page, setPage] = useState(parseInt(searchParams.get("page") || "0"));
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams();
    params.set("page", page.toString());

    if (searchText) {
      params.set("search", searchText);
    }

    setSearchParams(params, {
      replace: true,
    });
  }, [setSearchParams, page, searchText]);

  const modifySearchTerm = useMemo(
    () =>
      debounce((val) => {
        setPage(0);
        setSearchText(val);
      }, 500),
    []
  );

  return (
    <>
      <StyledHeader>
        <Box sx={HeaderLeftContent}>
          <Typography fontSize={30} fontWeight="bold">
            Medication Database
          </Typography>
          <TextField
            placeholder="Search for Medication"
            style={{ width: "300px" }}
            defaultValue={searchText}
            onChange={(e) => modifySearchTerm(e.target.value)}
          />
        </Box>
        <Box sx={HeaderRightContent}>
          <Button
            variant="contained"
            startIcon={<Add />}
            onClick={() => setShowModal(true)}
          >
            Add Medication
          </Button>
        </Box>
      </StyledHeader>
      <MedicationList
        page={page}
        setPage={setPage}
        showModal={showModal}
        setShowModal={setShowModal}
        searchText={searchText}
      />
    </>
  );
};

export default Medication;

import React, { useEffect, useState } from "react";
import { Add, ArrowBack, Delete, Edit } from "@mui/icons-material";
import {
  Box,
  Typography,
  Button,
  IconButton,
  CircularProgress,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Divider,
} from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  HeaderLeftContent,
  HeaderRightContent,
  StyledHeader,
} from "../../Common/styles/header";
import { errorToastMessage, toastMessage } from "../../../utils/toast";
import { BuilderWrapper } from "../ExerciseBuilder/exercise.style";
import { LoadingContainer } from "../../CMS/cms.style";
import { SectionBuilderType, SectionType } from "../exercise.type";
import SectionCards from "./SectionCards";
import AddSectionModal from "./AddSectionModal";
import AddExerciseModal from "../ExerciseBuilder/AddExerciseModal";
import { AxiosResponse } from "axios";
import http from "../../../utils/http";
import { CustomPagination, NoDataContainer } from "../../Common/styles/table";
import RearrangeExerciseModal from "./RearrangeExerciseModal";
import DragHandleIcon from "@mui/icons-material/DragHandle";

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const SectionBuilder = () => {
  const navigate = useNavigate();
  const { id, categoryId } = useParams();
  const title = useQuery().get("title") || "";

  const [loading, setLoading] = useState<boolean>(false);
  const [sectionData, setSectionData] = useState<SectionBuilderType>([]);

  const [showAddExerciseModal, setShowAddExerciseModal] =
    useState<boolean>(false);
  const [showAddSectionModal, setShowAddSectionModal] =
    useState<boolean>(false);
  const [showRearrangeModal, setShowRearrangeModal] = useState(false);

  const [selectedSection, setSelectedSection] = useState<null | SectionType>(
    null
  );
  const [selectedExerciseId, setSelectedExerciseId] = useState("");
  const [rearrangeExercises, setRearrangeExercises] = useState<{
    sectionId: string;
    exercises: any[];
  }>({ sectionId: "", exercises: [] });

  const [menuLoader, setMenuLoader] = useState(false);
  const [toggleLoader, setToggleLoader] = useState<boolean>(false);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [sectionAnchorEl, setSectionAnchorEl] = useState<null | HTMLElement>(
    null
  );
  const open = Boolean(anchorEl);
  const openSecMenu = Boolean(sectionAnchorEl);

  useEffect(() => {
    const fetchSectionData = async () => {
      try {
        setLoading(true);
        const res: AxiosResponse = await http.get(
          `/exercise-collection/${id}/categories/${categoryId}/section?pagination=false`
        );
        const data = res.data?.data?.sections;
        const sections = data.map((section: any) => ({
          id: section?.id,
          title: section?.title,
          exercises: section?.exerciseSectionItem,
        }));
        setSectionData(sections);
        setLoading(false);
      } catch (err) {
        errorToastMessage(err as Error);
        setLoading(false);
      }
    };

    fetchSectionData();
  }, [toggleLoader, categoryId, id]);

  const openEditSectionModal = (data: SectionType) => {
    setShowAddSectionModal(true);
    setSelectedSection(data);
  };

  const closeSectionModal = () => {
    setShowAddSectionModal(false);
    setSelectedSection(null);
  };

  const openAddExerciseModal = (data: SectionType) => {
    setShowAddExerciseModal(true);
    setSelectedSection(data);
  };

  const closeExerciseModal = () => {
    setShowAddExerciseModal(false);
    setSelectedSection(null);
  };

  const closeRearrangeModal = () => {
    setShowRearrangeModal(false);
    setRearrangeExercises({ sectionId: "", exercises: [] });
  };

  const refreshPage = () => {
    setToggleLoader((prev) => !prev);
  };

  const handleBack = () => {
    navigate(-1);
  };

  const openExerciseMenu = (
    event: React.MouseEvent<HTMLButtonElement>,
    module: any,
    exerciseId: string
  ) => {
    setAnchorEl(event.currentTarget);
    setSelectedSection(module);
    setSelectedExerciseId(exerciseId);
  };

  const openSectionMenu = (
    event: React.MouseEvent<HTMLButtonElement>,
    module: any
  ) => {
    setSectionAnchorEl(event.currentTarget);
    setSelectedSection(module);
  };

  const closeExerciseMenu = () => {
    setAnchorEl(null);
    setSelectedExerciseId("");
    setSelectedSection(null);
  };

  const closeSectionMenu = () => {
    setSectionAnchorEl(null);
    setSelectedSection(null);
  };

  const handleSectionAction = async (action: string) => {
    const targetId = selectedSection?.id;
    if (action === "edit" && targetId) {
      openEditSectionModal({
        title: selectedSection?.title,
        id: selectedSection?.id,
      });
      closeSectionMenu();
    } else if (action === "delete" && targetId) {
      const res: AxiosResponse = await http.delete(
        `/exercise-collection/${id}/categories/${categoryId}/section/${selectedSection?.id}`
      );
      toastMessage("success", res.data.message);
      closeSectionMenu();
      refreshPage();
    } else if (action === "rearrange" && targetId) {
      setRearrangeExercises({
        sectionId: selectedSection?.id,
        exercises: selectedSection?.exercises?.map((e) => e.exercises) || [],
      });
      closeSectionMenu();
    }
  };

  const removeExercise = async () => {
    try {
      setMenuLoader(true);
      const body = {
        removeIds: [selectedExerciseId],
      };
      const res: AxiosResponse = await http.post(
        `/exercise-collection/${id}/categories/${categoryId}/section/${selectedSection?.id}/item`,
        body
      );
      toastMessage("success", res.data.message);
      closeExerciseMenu();
      refreshPage();
      setMenuLoader(false);
    } catch (err) {
      setMenuLoader(false);
      errorToastMessage(err as Error);
    }
  };

  return (
    <>
      <StyledHeader>
        <Box sx={{ ...HeaderLeftContent, gap: 1 }}>
          <IconButton onClick={handleBack}>
            <ArrowBack htmlColor="#637E85" />
          </IconButton>
          <Typography variant="h2" sx={{ textTransform: "capitalize" }}>
            {title}
          </Typography>
        </Box>
        <Box sx={HeaderRightContent}>
          {sectionData?.length > 1 && (
            <Button
              variant="outlined"
              onClick={() => setShowRearrangeModal(true)}
            >
              Rearrange
            </Button>
          )}
          <Button
            variant="contained"
            startIcon={<Add />}
            onClick={() => setShowAddSectionModal(true)}
          >
            Add Section
          </Button>
        </Box>
      </StyledHeader>
      {!loading ? (
        sectionData?.length ? (
          <>
            <Box sx={BuilderWrapper}>
              {sectionData?.map((section: SectionType) => (
                <SectionCards
                  key={section?.title}
                  section={section}
                  openExerciseModal={openAddExerciseModal}
                  openMenu={openExerciseMenu}
                  openSectionMenu={openSectionMenu}
                />
              ))}
            </Box>
          </>
        ) : (
          <NoDataContainer sx={{ mt: 3 }}>
            <Typography variant="body1" color="gray">
              No Data
            </Typography>
          </NoDataContainer>
        )
      ) : (
        <Box sx={LoadingContainer}>
          <CircularProgress />
        </Box>
      )}
      {showAddSectionModal && (
        <AddSectionModal
          showModal={showAddSectionModal}
          closeModal={closeSectionModal}
          exerciseId={id || ""}
          categoryId={categoryId || ""}
          section={selectedSection}
          refreshPage={refreshPage}
        />
      )}
      {showAddExerciseModal && (
        <AddExerciseModal
          showModal={showAddExerciseModal}
          closeModal={closeExerciseModal}
          refreshPage={refreshPage}
          exerciseId={id || ""}
          categoryId={categoryId || ""}
          sectionId={selectedSection?.id || ""}
        />
      )}
      {showRearrangeModal && (
        <RearrangeExerciseModal
          callback={refreshPage}
          closeModal={closeRearrangeModal}
          parentId={id || ""}
          childId={categoryId || ""}
          items={sectionData}
          title={"Rearrange Sections"}
          type={"sections"}
        />
      )}

      {rearrangeExercises.sectionId && (
        <RearrangeExerciseModal
          callback={refreshPage}
          closeModal={closeRearrangeModal}
          parentId={id || ""}
          childId={categoryId || ""}
          sectionId={rearrangeExercises.sectionId}
          items={rearrangeExercises.exercises}
          title={"Rearrange Exercise"}
          type={"exercise"}
        />
      )}
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={closeExerciseMenu}
        PaperProps={{
          style: {
            minWidth: "200px",
          },
        }}
      >
        <MenuItem disabled={menuLoader ? true : false} onClick={removeExercise}>
          <ListItemIcon>
            {menuLoader ? (
              <CircularProgress size={16} />
            ) : (
              <Delete fontSize="small" />
            )}
          </ListItemIcon>
          <ListItemText>Remove</ListItemText>
        </MenuItem>
      </Menu>

      <Menu
        anchorEl={sectionAnchorEl}
        open={openSecMenu}
        onClose={closeSectionMenu}
        PaperProps={{
          style: {
            minWidth: "225px",
          },
        }}
      >
        {selectedSection?.exercises &&
          selectedSection?.exercises?.length > 1 && (
            <Box>
              <MenuItem
                key="rearrange"
                onClick={() => {
                  handleSectionAction("rearrange");
                }}
              >
                <ListItemIcon>
                  <DragHandleIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Rearrange</ListItemText>
              </MenuItem>
              <Divider />
            </Box>
          )}
        <MenuItem
          key="edit"
          onClick={() => {
            handleSectionAction("edit");
          }}
        >
          <ListItemIcon>
            <Edit fontSize="small" />
          </ListItemIcon>
          <ListItemText>Edit</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem
          key="delete"
          onClick={() => {
            handleSectionAction("delete");
          }}
        >
          <ListItemIcon>
            <Delete fontSize="small" color="error" />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};

export default SectionBuilder;

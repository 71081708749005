import { Box, Typography } from "@mui/material";
import React from "react";
import { DashboardCardStyle } from "./dashboard.style";
import { CircularProgressWithLabel } from "../Common/UI/ProgressWithLabel";

type Props = {
  title: string;
  count: number;
  progress?: number;
};

const DashboardCard = ({ title, count, progress }: Props) => {
  return (
    <Box sx={DashboardCardStyle}>
      <Typography variant="subtitle1" fontWeight="medium" color="#637E85">
        {title}
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-end",
          mt: "auto",
        }}
      >
        <Typography fontSize={32} fontWeight="medium" color="#355962">
          {count}
        </Typography>
        {progress && (
          <CircularProgressWithLabel value={progress} size={80} fontSize={20} />
        )}
      </Box>
    </Box>
  );
};

export default DashboardCard;

import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import { Box, CircularProgress } from "@mui/material";
import {
  QuesProgressData,
  contentProgressData,
  healthProgressData,
  ptAnalyticsData,
} from "../dashboardDetails";
import { errorToastMessage } from "../../../utils/toast";
import { CardsWrapper, DashboardCardStyle } from "../dashboard.style";
import DashboardCard from "../DashboardCard";
import { LinearProgressWithLabel } from "../../Common/UI/ProgressWithLabel";
import { LoadingContainer } from "../../CMS/cms.style";
import HealthProgress from "./HealthProgress";
// import http from "../../utils/http";
// import { AxiosResponse } from "axios";

const calculateTickValue = (data: any) => {
  const maxNo = Math.max(...data.map((v: any) => v.high + v.low + v.medium));
  const arr = Array.from(
    { length: Math.floor(maxNo / 20) },
    (_, i) => (i + 1) * 20
  );
  return maxNo > 20 ? arr.length + 1 : arr.length;
};

const ProgramInsights = () => {
  const [loading, setLoading] = useState(false);
  const [patientAnalytics, setPatientAnalytics] = useState<any[]>([]);
  const [contentProgress, setContentProgress] = useState<any[]>([]);
  const [quesProgress, setQuesProgress] = useState<any>({
    overallProgress: [],
  });
  const [healthProgress, setHealthProgress] = useState<any[]>([]);

  useEffect(() => {
    const fetchProgramData = async () => {
      try {
        setLoading(true);
        // const res: AxiosResponse = await http.get("/dashboard");
        setPatientAnalytics(ptAnalyticsData);
        setContentProgress(contentProgressData);
        setQuesProgress(QuesProgressData);
        setHealthProgress(healthProgressData);
        setLoading(false);
      } catch (err) {
        errorToastMessage(err as Error);
        setLoading(false);
      }
    };

    fetchProgramData();
  }, []);

  return !loading ? (
    <>
      <Typography variant="h6" fontWeight="medium" color="#355962" mb={3}>
        Patients Analytics
      </Typography>
      <Box sx={CardsWrapper}>
        {patientAnalytics.map((item: any) => (
          <DashboardCard
            key={item?.label}
            title={item?.label}
            count={item?.count}
            progress={item?.value}
          />
        ))}
      </Box>
      <Typography variant="h6" fontWeight="medium" color="#355962" mb={3}>
        Content Progress
      </Typography>
      <Box sx={CardsWrapper}>
        {contentProgress.map((item: any) => (
          <DashboardCard
            key={item?.label}
            title={item?.label}
            count={item?.count}
            progress={item?.value}
          />
        ))}
      </Box>
      <Typography variant="h6" fontWeight="medium" color="#355962" mb={3}>
        Questionnaire Progress
      </Typography>
      <Box sx={CardsWrapper}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <DashboardCard
            title="Total Questionnaires"
            count={quesProgress?.totalQuesCount}
          />
          <DashboardCard
            title="Questionnaries Answered"
            count={quesProgress?.AnsweredQuesCount}
            progress={quesProgress?.AnsweredQuesValue}
          />
        </Box>
        <Box sx={{ ...DashboardCardStyle, flex: 1, maxWidth: "100%" }}>
          <Typography variant="h6" fontWeight="medium" mb={3} color="#637E85">
            Questionnaires Progress
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            {quesProgress?.overallProgress.map((ques: any) => (
              <Box
                key={ques?.label}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: 2,
                }}
              >
                <Box sx={{ width: "235px" }}>
                  <Typography variant="body1" fontWeight="regular">
                    {ques?.label}
                  </Typography>
                </Box>
                <Box sx={{ flex: 1 }}>
                  <LinearProgressWithLabel value={ques?.value} />
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
      <HealthProgress
        data={healthProgress}
        tickValue={calculateTickValue(healthProgress) || 0}
      />
    </>
  ) : (
    <Box sx={LoadingContainer}>
      <CircularProgress />
    </Box>
  );
};

export default ProgramInsights;

import { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { useSearchParams, useLocation, useNavigate } from "react-router-dom";
import {
  HeaderLeftContent,
  HeaderRightContent,
  StyledHeader,
} from "../Common/styles/header";
import {
  MainTabPanel,
  StyledTab,
  StyledTabs,
  a11yProps,
} from "../Common/UI/TabPanel";
import AppointmentsTab from "./AppointmentsTab";

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const AppointmentListView = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const name = useQuery().get("name");
  const inviterId = useQuery().get("id");
  const groupId = useQuery().get("groupId");
  const inviteeId = useQuery().get("inviteeId");
  const navigate = useNavigate();
  const [type, setType] = useState(searchParams.get("type") || "day");

  useEffect(() => {
    const params = new URLSearchParams();
    if (type) {
      params.set("type", type);
    }
    if (name) {
      params.set("name", name);
    }
    if (inviterId) {
      params.set("id", inviterId);
    }
    if (groupId) {
      params.set("groupId", groupId);
    }
    if (inviteeId) {
      params.set("inviteeId", inviteeId);
    }
    setSearchParams(params, {
      replace: false,
    });
  }, [type, name, inviterId, groupId, inviteeId, setSearchParams]);

  const handleChange = (_: any, newValue: string) => {
    setType(newValue);
  };

  const openCalendarView = () => {
    let url = `/app/appointments?`;
    if (inviteeId) {
      url += `name=${name}&inviteeId=${inviteeId}`;
    }
    if (groupId) {
      url += `name=${name}&groupId=${groupId}`;
    }
    if (inviterId) {
      url += `name=${name}&id=${inviterId}`;
    }
    navigate(url);
  };

  return (
    <>
      <StyledHeader>
        <Box sx={HeaderLeftContent}>
          <Typography fontSize={30} fontWeight="bold">
            {name
              ? name + "'s Upcoming appointments"
              : "Your Upcoming appointments"}
          </Typography>
        </Box>
        <Box sx={HeaderRightContent}>
          <Button variant="outlined" onClick={openCalendarView}>
            Calendar View
          </Button>
        </Box>
      </StyledHeader>
      <Box
        sx={{
          borderTop: 1,
          borderColor: "#E5E7EB",
        }}
      >
        <StyledTabs value={type} onChange={handleChange}>
          <StyledTab label="Today" value="day" {...a11yProps(0)} />
          <StyledTab label="Week" value="week" {...a11yProps(1)} />
          <StyledTab label="Month" value="month" {...a11yProps(2)} />
          <StyledTab label="All Upcoming" value="upcoming" {...a11yProps(3)} />
        </StyledTabs>
      </Box>
      <Box sx={{ mt: "24px" }}>
        <MainTabPanel value={type} index={type}>
          <AppointmentsTab tabType={type} />
        </MainTabPanel>
      </Box>
    </>
  );
};

export default AppointmentListView;

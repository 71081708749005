import { Button, SxProps, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { DatePicker } from "@mui/x-date-pickers";
import { DateTime } from "luxon";
import React from "react";
import { useAppDispatch, useAppSelector } from "../../../Redux/hooks";
import {
  setHealthEndDate,
  setHealthStartDate,
} from "../../../Redux/reducers/healthSlice";

const HeaderStyle: SxProps = {
  height: "80px",
  width: "100%",
  background: "#ffffff",
  borderRadius: "0px 0px 8px 8px",
  mb: 4,
  display: "flex",
  alignItems: "center",
  p: 2,
  borderTop: "1px solid",
  borderColor: "#E0E3EB",
};

const HealthDateSelector: React.FC = () => {
  const dispatch = useAppDispatch();
  const { endDate, startDate } = useAppSelector((state) => state.health);

  const changeDate = (val: string | null, type: "start" | "end") => {
    if (type === "end") {
      dispatch(setHealthEndDate(val));
    } else {
      dispatch(setHealthStartDate(val));
    }
  };

  return (
    <Box sx={HeaderStyle}>
      <Typography fontSize={"14px"} fontWeight={"regular"} mr={1}>
        Start Date
      </Typography>
      <DatePicker
        inputFormat="dd/MM/yyyy"
        onChange={(newValue: any) => {
          changeDate(newValue?.toFormat("dd'-'LL'-'yyyy"), "start");
        }}
        value={startDate ? DateTime.fromFormat(startDate, "dd-LL-yyyy") : null}
        renderInput={(params) => (
          <TextField
            {...params}
            sx={{ maxWidth: "200px", mb: 1, mr: 2 }}
            inputProps={{
              ...params.inputProps,
              readOnly: true,
              placeholder: "DD/MM/YYYY",
            }}
          />
        )}
      />
      <Typography fontSize={"14px"} fontWeight={"regular"} mr={1}>
        End Date
      </Typography>
      <DatePicker
        inputFormat="dd/MM/yyyy"
        onChange={(newValue: any) => {
          changeDate(newValue?.toFormat("dd'-'LL'-'yyyy"), "end");
        }}
        value={endDate ? DateTime.fromFormat(endDate, "dd-LL-yyyy") : null}
        renderInput={(params) => (
          <TextField
            {...params}
            sx={{ maxWidth: "200px", mb: 1 }}
            inputProps={{
              ...params.inputProps,
              readOnly: true,
              placeholder: "DD/MM/YYYY",
            }}
          />
        )}
      />
      <span style={{ marginLeft: "auto" }}></span>
      <Button variant="text" sx={{ mr: 1 }}>
        Last 7 Days
      </Button>
      <Button variant="text" sx={{ mr: 1 }}>
        Last 30 Days
      </Button>
      <Button variant="text" sx={{ mr: 1 }}>
        Last 60 Days
      </Button>
      <Button variant="text">Year</Button>
    </Box>
  );
};

export default HealthDateSelector;

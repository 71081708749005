import { Add, Delete } from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

type Props = {
  question: any;
  modifyQuestion: any;
  preview: boolean;
};

const GridLogic: React.FC<Props> = ({ question, modifyQuestion, preview }) => {
  const { properties } = question;
  const [gridPreview, setGridPreview] = useState(preview);

  const onRowChange = (index: number, event: any, key: string) => {
    const newQuestion = {
      ...question,
      properties: {
        ...question.properties,
        rows: question.properties.rows.map((row: any, idx: number) => {
          return index === idx ? { ...row, [key]: event.target.value } : row;
        }),
      },
    };
    modifyQuestion(newQuestion);
  };

  const onColChange = (index: number, event: any, key: string) => {
    const newQuestion = {
      ...question,
      properties: {
        ...question.properties,
        columns: question.properties.columns.map((col: any, idx: number) => {
          return index === idx ? { ...col, [key]: event.target.value } : col;
        }),
      },
    };
    modifyQuestion(newQuestion);
  };

  const onColumnType = (index: number, value: string) => {
    const newQuestion = {
      ...question,
      properties: {
        ...question.properties,
        columns: question.properties.columns.map((col: any, idx: number) => {
          if (index === idx) {
            if (value === "checkbox") {
              return { ...col, type: value, options: [""] };
            } else {
              return { type: value, label: col.label };
            }
          } else {
            return col;
          }
        }),
      },
    };
    modifyQuestion(newQuestion);
  };

  const addField = (type: "row" | "column") => {
    if (type === "row") {
      const newQuestion = {
        ...question,
        properties: {
          ...question.properties,
          rows: [...question.properties.rows, { label: "" }],
        },
      };
      modifyQuestion(newQuestion);
    } else {
      const newQuestion = {
        ...question,
        properties: {
          ...question.properties,
          columns: [...question.properties.columns, { type: "short_text" }],
        },
      };
      modifyQuestion(newQuestion);
    }
  };

  const deleteField = (type: "row" | "column", index: number) => {
    if (type === "row") {
      if (question.properties.rows.length > 1) {
        const newQuestion = {
          ...question,
          properties: {
            ...question.properties,
            rows: question.properties.rows.filter(
              (_1: any, idx: number) => idx !== index
            ),
          },
        };
        modifyQuestion(newQuestion);
      }
    } else {
      if (question.properties.columns.length > 1) {
        const newQuestion = {
          ...question,
          properties: {
            ...question.properties,
            columns: question.properties.columns.filter(
              (_1: any, idx: number) => idx !== index
            ),
          },
        };
        modifyQuestion(newQuestion);
      }
    }
  };

  const addOption = (index: number) => {
    const newQuestion = {
      ...question,
      properties: {
        ...question.properties,
        columns: question.properties.columns.map((col: any, idx: number) => {
          if (index === idx) {
            return { ...col, options: [...col.options, ""] };
          } else {
            return col;
          }
        }),
      },
    };
    modifyQuestion(newQuestion);
  };

  const deleteOption = (optionIndex: number, columnIndex: number) => {
    const options = question.properties.columns[columnIndex].options;
    if (options.length > 1) {
      const newOption = options.filter(
        (_: any, index: number) => index !== optionIndex
      );
      const newQuestion = {
        ...question,
        properties: {
          ...question.properties,
          columns: question.properties.columns.map((col: any, idx: number) => {
            if (columnIndex === idx) {
              return { ...col, options: newOption };
            } else {
              return col;
            }
          }),
        },
      };
      modifyQuestion(newQuestion);
    }
  };

  const editOption = (optionIndex: number, columnIndex: number, event: any) => {
    const newQuestion = {
      ...question,
      properties: {
        ...question.properties,
        columns: question.properties.columns.map((col: any, idx: number) => {
          if (columnIndex === idx) {
            return {
              ...col,
              options: col.options.map((opt: any, index: number) =>
                index !== optionIndex ? opt : event.target.value
              ),
            };
          } else {
            return col;
          }
        }),
      },
    };
    modifyQuestion(newQuestion);
  };

  const toggleGridPreview = () => {
    setGridPreview((prev) => !prev);
  };

  return gridPreview ? (
    <GridPreview
      rows={properties.rows}
      columns={properties.columns}
      toggleGridPreview={toggleGridPreview}
      preview={preview}
    />
  ) : (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="subtitle1" fontWeight="medium" mb={1.5}>
          Rows
        </Typography>
        <Button variant="contained" onClick={toggleGridPreview}>
          Preview
        </Button>
      </Box>
      <>
        {properties.rows.map((row: any, index: number) => {
          return (
            <Box
              sx={{ display: "flex", mb: 2, alignItems: "flex-end" }}
              key={"row" + index}
            >
              <Box sx={{ flex: 1, minWidth: "0px" }}>
                <Typography variant="subtitle1" fontWeight="regular" mb={1}>
                  {"Row label " + (index + 1)}
                </Typography>
                <TextField
                  fullWidth
                  value={row.label}
                  onChange={(e) => onRowChange(index, e, "label")}
                />
              </Box>
              <Box ml={1}>
                <IconButton onClick={() => addField("row")}>
                  <Add fontSize="medium" sx={{ color: "primary.main" }} />
                </IconButton>
                <IconButton onClick={() => deleteField("row", index)}>
                  <Delete fontSize="medium" sx={{ color: "error.main" }} />
                </IconButton>
              </Box>
            </Box>
          );
        })}
      </>
      <Typography variant="subtitle1" fontWeight="medium" mb={2}>
        Columns
      </Typography>
      <>
        {properties.columns.map((column: any, index: number) => {
          return (
            <Box key={"col" + index}>
              <Box sx={{ display: "flex", alignItems: "flex-end", mb: 1 }}>
                <Box sx={{ flex: 1, minWidth: "0px" }}>
                  <Typography variant="subtitle1" fontWeight="regular" mb={1}>
                    {"Column label " + (index + 1)}
                  </Typography>
                  <TextField
                    placeholder="Column Label"
                    value={column.label}
                    fullWidth
                    onChange={(e) => onColChange(index, e, "label")}
                  />
                </Box>
                <Box ml={1}>
                  <IconButton onClick={() => addField("column")}>
                    <Add fontSize="medium" sx={{ color: "primary.main" }} />
                  </IconButton>
                  <IconButton onClick={() => deleteField("column", index)}>
                    <Delete fontSize="medium" sx={{ color: "error.main" }} />
                  </IconButton>
                </Box>
              </Box>
              <Box sx={{ flex: 1, minWidth: "0px", mb: 1 }}>
                <Typography variant="subtitle1" fontWeight="regular" mb={1}>
                  {"Column Type " + (index + 1)}
                </Typography>
                <Select
                  fullWidth
                  value={column.type}
                  onChange={(event) => onColumnType(index, event.target.value)}
                >
                  <MenuItem value="short_text">Short Text</MenuItem>
                  <MenuItem value="number">Number</MenuItem>
                  <MenuItem value="date">Calendar</MenuItem>
                  <MenuItem value="checkbox">Checkbox</MenuItem>
                  <MenuItem value="score_input">Score Input</MenuItem>
                </Select>
              </Box>
              {column.type === "checkbox" &&
                column.options.map((opt: any, idx: number) => {
                  return (
                    <Box
                      sx={{ display: "flex", alignItems: "flex-end", mb: 1 }}
                      key={"opt" + idx}
                    >
                      <Box sx={{ flex: 1, minWidth: "0px" }}>
                        <Typography
                          variant="subtitle1"
                          fontWeight="regular"
                          mb={1}
                        >
                          {"Option " + (idx + 1)}
                        </Typography>
                        <TextField
                          fullWidth
                          placeholder="Option Label"
                          value={opt}
                          onChange={(e) => editOption(idx, index, e)}
                        />
                      </Box>
                      <Box ml={1}>
                        <IconButton onClick={() => addOption(index)}>
                          <Add
                            fontSize="medium"
                            sx={{ color: "primary.main" }}
                          />
                        </IconButton>
                        <IconButton onClick={() => deleteOption(idx, index)}>
                          <Delete
                            fontSize="medium"
                            sx={{ color: "error.main" }}
                          />
                        </IconButton>
                      </Box>
                    </Box>
                  );
                })}
            </Box>
          );
        })}
      </>
    </>
  );
};

const GridPreview: React.FC<any> = ({
  columns,
  rows,
  toggleGridPreview,
  preview,
}) => {
  const renderCol = (column: any) => {
    switch (column.type) {
      case "short_text":
        return <TextField fullWidth />;
      case "number":
        return <TextField fullWidth type="number" />;
      case "date":
        return (
          <DatePicker
            value={null}
            onChange={() => {}}
            renderInput={(params) => <TextField {...params} fullWidth />}
          />
        );
      case "checkbox":
        return (
          <Select fullWidth defaultValue={""}>
            {column.options.map((opt: any, index: number) => {
              let text = opt || "Option " + (index + 1);
              return (
                <MenuItem key={"opt" + index} value={opt}>
                  {text}
                </MenuItem>
              );
            })}
          </Select>
        );
      case "score_input":
        return <TextField fullWidth type="number" />;
    }
  };

  return (
    <>
      <Box
        sx={{
          mb: 2,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="subtitle1" fontWeight="medium">
          Preview
        </Typography>
        {!preview && (
          <Button variant="contained" onClick={toggleGridPreview}>
            Exit Preview
          </Button>
        )}
      </Box>
      <table className="grid-preview">
        <thead>
          <tr>
            <th></th>
            {columns.map((col: any, idx: number) => {
              return <th key={idx}>{col.label || "Column " + (idx + 1)}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {rows.map((row: any, idx: number) => {
            return (
              <tr key={idx}>
                <td key={"row" + idx}>{row.label || "Row " + (idx + 1)}</td>
                {columns.map((col: any, idx: number) => {
                  return <td key={"col" + idx}>{renderCol(col)}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

export default GridLogic;

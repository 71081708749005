import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Divider, Typography } from "@mui/material";
import { sleepFn } from "../../../../utils/delay";
import { NoDataContainer } from "../../../Common/styles/table";

interface dataProps {
  name: string;
  label: string;
  value: string;
}

const BloodPressureMetrics = () => {
  const [pressureData, setPressureData] = useState<dataProps[]>([]);
  const [loader, setLoader] = useState<boolean>(false);

  useEffect(() => {
    const fetchpressureData = async () => {
      try {
        setLoader(true);

        await sleepFn(1000);
        setPressureData([
          {
            name: "Average",
            label: "Average Blood Pressure",
            value: "150/88",
          },
          {
            name: "High",
            label: "Max Blood Pressure",
            value: "180/90",
          },
          {
            name: "Low",
            label: "Min Blood Pressure",
            value: "120/80",
          },
        ]);

        setLoader(false);
      } catch (error) {
        setLoader(false);
      }
    };

    fetchpressureData();
  }, [setPressureData]);

  return (
    <Box sx={{ bgcolor: "white", borderRadius: "12px" }}>
      <Box p={"24px 16px"}>
        <Typography fontWeight={"medium"} fontSize={"22px"} color="#355962">
          Blood Pressure Insights
        </Typography>
      </Box>
      <Divider sx={{ color: "#E5E7EB" }} />
      {!loader ? (
        <>
          {pressureData.length > 0 ? (
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: {
                  xs: "repeat(1, 1fr)",
                  md: "repeat(2, 1fr)",
                  lg: "repeat(3, 1fr)",
                },
                gap: "16px",
                padding: "16px",
              }}
            >
              {pressureData.map((data: dataProps) => {
                return (
                  <Box
                    key={data.name}
                    sx={{ borderRadius: "12px", border: "1px solid #E0E3EB" }}
                  >
                    <Box p={"16px"}>
                      <Typography
                        sx={{
                          color: "#637E85",
                          fontSize: "14px",
                          fontWeight: "600",
                        }}
                      >
                        {data.name}
                      </Typography>
                    </Box>
                    <Divider sx={{ color: "#E5E7EB" }} />
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        p: "24px 16px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "400",
                          color: "#637E85",
                        }}
                      >
                        {data.label}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "18px",
                          fontWeight: "600",
                          color: "#355962",
                        }}
                      >
                        {data.value}
                      </Typography>
                    </Box>
                  </Box>
                );
              })}
            </Box>
          ) : (
            <NoDataContainer>
              <Typography variant="body1" color="gray">
                No Data
              </Typography>
            </NoDataContainer>
          )}
        </>
      ) : (
        <Box sx={{ display: "flex", justifyContent: "center", p: 2 }}>
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
};

export default BloodPressureMetrics;

import React from "react";
import { useAppSelector } from "../../../Redux/hooks";
import Sleep from "./Block/Sleep";
import Weight from "./Block/Weight";
import BloodPressure from "./Block/BloodPressure";
import PFB from "../FoodJournal/PFB";

const HealthGraph: React.FC = () => {
  const { type } = useAppSelector((state) => state.health);

  switch (type) {
    case "blood_glucose":
      return <PFB />;
    case "sleep":
      return <Sleep />;
    case "weight":
      return <Weight />;
    case "blood_pressure":
      return <BloodPressure />;
    default:
      return null;
  }
};

export default HealthGraph;

import {
  OnboardingQuestion,
  onboardingQuestionType,
} from "../types/onboarding";
import { v4 as uuid } from "uuid";
import {
  CalendarIcon,
  LongTextIcon,
  MCQIcon,
  NumberIcon,
  ShortTextIcon,
  SliderIcon,
} from "../Components/Onboarding/Builder/Icons";
export const onboardingQuestionTypes: {
  label: string;
  type: onboardingQuestionType;
}[] = [
  {
    label: "Statement",
    type: "statement",
  },
  {
    label: "Text",
    type: "text",
  },
  {
    label: "Number",
    type: "number",
  },
  // {
  //   label: "Date",
  //   type: "date",
  // },
  {
    label: "Multiple Choice",
    type: "multiple_choice",
  },
  {
    label: "Likert Scale",
    type: "likert_scale",
  },
  {
    label: "Group",
    type: "group",
  },
];

export const getQLabelFromType = (type: onboardingQuestionType) => {
  return onboardingQuestionTypes.find((q) => q.type === type)?.label;
};
export const OnboardingBuilderStepIconMap: any = {
  statement: LongTextIcon,
  text: ShortTextIcon,
  number: NumberIcon,
  multiple_choice: MCQIcon,
  likert_scale: CalendarIcon,
  group: SliderIcon,
};

export const createQuestion = (
  type: onboardingQuestionType
): OnboardingQuestion => {
  const question: OnboardingQuestion = {
    id: uuid(),
    type: type,
    variableName: "",
    title: "",
    titleZh: "",
    titleMs: "",
    titleTa: "",
    exit: false,
    imageUrl: "",
    userType: 0,
    description: "",
  };
  if (["multiple_choice", "likert_scale"].includes(type)) {
    question.choices = [
      {
        id: uuid(),
        label: "",
        labelZh: "",
        labelMs: "",
        labelTa: "",
        response: "",
        responseZh: "",
        responseMs: "",
        responseTa: "",
        nextStepId: "",
        exit: false,
        value: "",
        iconUrl: null,
      },
    ];
  } else if (type === "group") {
    question.childSteps = [
      {
        id: uuid(),
        type: "text",
        variableName: "",
        title: "",
        titleZh: "",
        titleMs: "",
        titleTa: "",
        imageUrl: "",
        description: "",
        exit: false,
        userType: 0,
      },
    ];
  }
  return question;
};

export const cloneQuestion = (
  question: OnboardingQuestion
): OnboardingQuestion => {
  if (["multiple_choice", "likert_scale"].includes(question.type)) {
    return {
      ...question,
      variableName: question.variableName
        ? question.variableName + " copy"
        : "copy",
      id: uuid(),
      choices: question.choices?.map((option) => {
        return {
          ...option,
          id: uuid(),
        };
      }),
    };
  } else if (question.type === "group") {
    return {
      ...question,
      variableName: question.variableName
        ? question.variableName + " copy"
        : "copy",
      id: uuid(),
      childSteps: question.childSteps?.map((subQ) => {
        return cloneQuestion(subQ);
      }),
    };
  }
  return {
    ...question,
    variableName: question.variableName
      ? question.variableName + " copy"
      : "copy",
    id: uuid(),
  };
};

export const createNewOption = () => {
  return {
    id: uuid(),
    label: "",
    labelZh: "",
    labelMs: "",
    labelTa: "",
    response: "",
    responseZh: "",
    responseMs: "",
    responseTa: "",
    nextStepId: "",
    exit: false,
    value: "",
    iconUrl: null,
  };
};

export const modifySubquestion = (
  question: OnboardingQuestion,
  newType: onboardingQuestionType
) => {
  if (["multiple_choice", "likert_scale"].includes(newType)) {
    const newQuestion = {
      ...question,
      id: uuid(),
      type: newType,
    };
    if (!newQuestion.choices) {
      newQuestion.choices = [
        {
          id: uuid(),
          label: "",
          labelZh: "",
          labelMs: "",
          labelTa: "",
          response: "",
          responseZh: "",
          responseMs: "",
          responseTa: "",
          nextStepId: "",
          exit: false,
          value: "",
          iconUrl: null,
        },
      ];
    }
    return newQuestion;
  } else {
    const newQuestion = {
      ...question,
      id: uuid(),
      type: newType,
    };
    delete newQuestion["choices"];
    return newQuestion;
  }
};

export const validateOnboarding = (
  questions: OnboardingQuestion[],
  parentQuestionIndex?: number
) => {
  const sanitizedQuestions = questions.map((question, index) => {
    const newQuestion: any = {
      id: question.id,
      type: question.type,
      title: question.title,
      titleZh: question.titleZh,
      titleMs: question.titleMs,
      titleTa: question.titleTa,
      variableName: question.variableName,
      imageUrl: question.imageUrl || null,
      exit: question.exit || false,
      position: index + 1,
      choices: [],
      childSteps: [],
      userType: question.userType || 0,
      description: question.description || "",
    };
    if (
      !newQuestion.title
      // ||
      // !newQuestion.titleZh ||
      // !newQuestion.titleMs ||
      // !newQuestion.titleTa
    ) {
      if (parentQuestionIndex !== undefined) {
        throw new Error(
          `Question ${parentQuestionIndex + 1} - SubQuestion ${
            index + 1
          } title cannot be empty`
        );
      } else {
        throw new Error(`Question ${index + 1} title cannot be empty`);
      }
    }
    if (!newQuestion.variableName) {
      if (parentQuestionIndex !== undefined) {
        throw new Error(
          `Question ${parentQuestionIndex + 1} - SubQuestion ${
            index + 1
          } variable name cannot be empty`
        );
      } else {
        throw new Error(`Question ${index + 1} variable name cannot be empty`);
      }
    }
    if (["multiple_choice", "likert_scale"].includes(question.type)) {
      newQuestion.choices = question.choices?.map((option, optionIndex) => {
        if (
          !option.label
          // ||
          // !option.labelZh ||
          // !option.labelMs ||
          // !option.labelTa
        ) {
          if (parentQuestionIndex !== undefined) {
            throw new Error(
              `Question ${parentQuestionIndex + 1} - SubQuestion ${
                index + 1
              } Option ${optionIndex + 1} cannot be empty`
            );
          } else {
            throw new Error(
              `Question ${index + 1} Option ${optionIndex + 1} cannot be empty`
            );
          }
        }
        return {
          position: optionIndex + 1,
          label: option.label,
          labelZh: option.labelZh,
          labelMs: option.labelMs,
          labelTa: option.labelTa,
          response: option.response || "",
          responseZh: option.responseZh || "",
          responseMs: option.responseMs || "",
          responseTa: option.responseTa || "",
          exit: option.nextStepId === "exit" ? true : false,
          nextStepId:
            option.nextStepId !== "exit" ? option.nextStepId || null : null,
          value: option.value || "",
          iconUrl: option.iconUrl || null,
        };
      });
    }
    if (question.type === "group" && question.childSteps) {
      newQuestion.childSteps = validateOnboarding(question.childSteps, index);
    }
    return newQuestion;
  });
  return sanitizedQuestions;
};

export const sanitizeQuestions = (
  questions: any[],
  isSubquestion?: boolean
): OnboardingQuestion[] => {
  const sanitizedQuestions: OnboardingQuestion[] = questions
    .sort((a: any, b: any) => {
      return (
        a[isSubquestion ? "childPosition" : "position"] -
        b[isSubquestion ? "childPosition" : "position"]
      );
    })
    .map((question) => {
      const newQuestion: OnboardingQuestion = {
        id: question.id || uuid(),
        type: question.type,
        title: question.title || "",
        titleZh: question.titleZh || "",
        titleMs: question.titleMs || "",
        titleTa: question.titleTa || "",
        variableName: question.variableName || "",
        imageUrl: question.imageUrl || null,
        exit: question.exit || false,
        userType: question.userType || 0,
        description: question.description || "",
      };
      if (["multiple_choice", "likert_scale"].includes(question.type)) {
        newQuestion.choices = question.choices
          ?.sort((a: any, b: any) => {
            return a.position - b.position;
          })
          .map((option: any) => {
            return {
              id: option.id || uuid(),
              label: option.label || "",
              labelZh: option.labelZh || "",
              labelMs: option.labelMs || "",
              labelTa: option.labelTa || "",
              response: option.response || "",
              responseZh: option.responseZh || "",
              responseMs: option.responseMs || "",
              responseTa: option.responseTa || "",
              exit: false,
              nextStepId: option.exit ? "exit" : option.nextStepId || "",
              value: option.value || "",
              iconUrl: option.iconUrl || null,
            };
          });
      }
      if (question.type === "group" && question.childSteps) {
        newQuestion.childSteps = sanitizeQuestions(question.childSteps, true);
      }
      return newQuestion;
    });
  return sanitizedQuestions;
};

export const Logo = () => (
  <svg
    width="200"
    height="55"
    viewBox="0 0 200 55"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      opacity="0.14"
      cx="27.764"
      cy="27.3929"
      r="27.3929"
      fill="url(#paint0_linear_983_31746)"
    />
    <path
      d="M47.1192 35.8037V18.4078C47.1192 15.6693 44.9381 13.4492 42.2476 13.4492C39.5571 13.4492 37.376 15.6693 37.376 18.4078V30.2369C37.8361 29.7918 38.4464 29.4222 39.0471 29.6311C39.3675 29.7425 39.6532 29.9584 39.7837 30.3387C39.9904 30.9407 39.663 31.576 39.3703 32.1397L38.0215 34.7369C37.9581 34.8589 37.8788 34.9716 37.7856 35.0719C37.5225 35.3551 37.376 35.73 37.376 36.1196V37.0071C37.376 39.0811 39.0277 40.7623 41.0653 40.7623H42.2476C44.9381 40.7623 47.1192 38.5423 47.1192 35.8037Z"
      fill="#7DC4E8"
    />
    <path
      d="M29.549 35.9701V18.2406C29.549 15.5059 27.3321 13.2891 24.5975 13.2891C21.8629 13.2891 19.646 15.5059 19.646 18.2406V30.2731C20.1135 29.823 20.7376 29.4476 21.3501 29.6622C21.6783 29.7771 21.9698 30 22.099 30.3933C22.2966 30.9946 21.973 31.6231 21.6803 32.1843L20.3022 34.8256C20.2377 34.9491 20.1568 35.0634 20.0617 35.1653C19.7946 35.4515 19.646 35.8285 19.646 36.2201V37.1123C19.646 39.2161 21.3515 40.9216 23.4554 40.9216H24.5975C27.3321 40.9216 29.549 38.7048 29.549 35.9701Z"
      fill="#7DC4E8"
    />
    <path
      d="M46.6329 24.474C48.5861 22.5208 51.7408 22.5088 53.6791 24.4471C55.6174 26.3855 55.6054 29.5401 53.6522 31.4933L45.5254 39.6201C43.5723 41.5733 40.4176 41.5853 38.4793 39.647C36.5409 37.7086 36.553 34.554 38.5061 32.6008L46.6329 24.474Z"
      fill="#327091"
    />
    <path
      d="M21.1494 14.7842C23.0797 12.8539 26.2094 12.8539 28.1397 14.7842C30.07 16.7146 30.07 19.8442 28.1397 21.7746L10.4854 39.4289C8.55506 41.3592 5.42538 41.3592 3.49505 39.4289C1.56472 37.4986 1.56472 34.3689 3.49505 32.4385L21.1494 14.7842Z"
      fill="url(#paint1_linear_983_31746)"
    />
    <g filter="url(#filter0_bd_983_31746)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.6058 15.251C20.0024 16.0715 19.646 17.0848 19.646 18.1814V30.2187C19.6552 30.2098 19.6646 30.2009 19.6739 30.192L28.1194 21.7442C28.9708 20.8926 29.4473 19.8081 29.549 18.6958V18.1814C29.549 17.6335 29.46 17.1065 29.2957 16.6139C29.0582 15.9293 28.6661 15.2865 28.1194 14.7397C26.1858 12.8055 23.0506 12.8055 21.117 14.7397L20.6058 15.251Z"
        fill="url(#paint2_linear_983_31746)"
      />
    </g>
    <path
      d="M38.5729 14.9652C40.5579 12.9802 43.7761 12.9802 45.7611 14.9652C47.7461 16.9502 47.7461 20.1684 45.7611 22.1534L28.0163 39.8982C26.0313 41.8832 22.813 41.8832 20.8281 39.8982C18.8431 37.9133 18.8431 34.695 20.8281 32.71L38.5729 14.9652Z"
      fill="url(#paint3_linear_983_31746)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.5359 39.4074C29.1724 38.5551 29.5493 37.4976 29.5493 36.352V23.9936C29.5398 24.0027 29.5302 24.0118 29.5206 24.0209L20.8025 32.7079C19.9238 33.5836 19.4319 34.6988 19.3269 35.8426V36.352C19.3269 37.058 19.47 37.7306 19.7289 38.3423C19.9774 38.9116 20.3353 39.445 20.8025 39.9106C22.7986 41.8996 26.0348 41.8996 28.0309 39.9106L28.5359 39.4074Z"
      fill="url(#paint4_linear_983_31746)"
    />
    <g filter="url(#filter1_bd_983_31746)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.6058 15.251C20.0024 16.0715 19.646 17.0848 19.646 18.1814V30.2187C19.6552 30.2098 19.6646 30.2009 19.6739 30.192L28.1194 21.7442C28.9708 20.8926 29.4473 19.8081 29.549 18.6958V18.1814C29.549 17.6335 29.46 17.1065 29.2957 16.6139C29.0582 15.9293 28.6661 15.2865 28.1194 14.7397C26.1858 12.8055 23.0506 12.8055 21.117 14.7397L20.6058 15.251Z"
        fill="url(#paint5_linear_983_31746)"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M46.1263 39.1031C46.7501 38.2743 47.1187 37.2503 47.1187 36.1421V23.9927C47.1091 24.0017 47.0994 24.0107 47.0897 24.0197L38.3719 32.5472C37.4931 33.4067 37.0012 34.5015 36.8962 35.6243V36.1422C36.8962 36.7044 36.9911 37.245 37.1661 37.7495C37.4121 38.4316 37.814 39.072 38.3719 39.6177C40.3679 41.5702 43.6042 41.5701 45.6002 39.6177L46.1263 39.1031Z"
      fill="url(#paint6_linear_983_31746)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M38.1759 15.4635C37.572 16.2935 37.2158 17.3153 37.2158 18.4203V30.6983C37.2252 30.6892 37.2347 30.6801 37.2442 30.6711L45.8259 22.0639C46.691 21.1962 47.1752 20.0913 47.2785 18.958V18.4203C47.2785 17.9438 47.2123 17.4829 47.0886 17.046C46.8575 16.2705 46.4366 15.5398 45.8259 14.9273C43.8611 12.9565 40.6754 12.9565 38.7105 14.9273L38.1759 15.4635Z"
      fill="url(#paint7_linear_983_31746)"
    />
    <path
      d="M88.7008 19.3828C89.86 19.3828 90.9114 19.5776 91.855 19.9672C92.8255 20.3568 93.6477 20.9152 94.3217 21.6424C94.9957 22.3696 95.5213 23.2396 95.8988 24.2525C96.2762 25.2395 96.4649 26.3563 96.4649 27.6029V39.7188H91.8954V28.2652C91.8954 26.7588 91.518 25.5771 90.7631 24.72C90.0083 23.863 88.9434 23.4344 87.5685 23.4344C86.2745 23.4344 85.2366 23.824 84.4548 24.6032C83.673 25.3823 83.2147 26.3692 83.0799 27.5639V27.6808V39.7188H78.5104V28.2652C78.5104 26.7588 78.1195 25.5771 77.3377 24.72C76.5829 23.863 75.5854 23.4344 74.3453 23.4344C72.8895 23.4344 71.7303 23.9019 70.8676 24.8369C70.0319 25.7459 69.614 26.9017 69.614 28.3041V39.7188H65.085V19.7334H69.3714V22.3826C69.9106 21.4995 70.6519 20.7853 71.5955 20.2399C72.566 19.6685 73.8196 19.3828 75.3562 19.3828C76.812 19.3828 78.106 19.6945 79.2383 20.3178C80.3706 20.9411 81.2737 21.8372 81.9476 23.0059C82.5677 21.967 83.4304 21.1099 84.5357 20.4347C85.6679 19.7334 87.0563 19.3828 88.7008 19.3828Z"
      fill="#212121"
    />
    <path
      d="M109.675 19.3828C112.371 19.3828 114.42 20.0581 115.822 21.4086C117.251 22.7592 117.965 24.72 117.965 27.2912V39.7188H113.396V37.9267C112.668 38.602 111.832 39.1344 110.888 39.524C109.972 39.9136 108.934 40.1083 107.775 40.1083C105.699 40.1083 104.028 39.6019 102.76 38.589C101.493 37.5761 100.86 36.1347 100.86 34.2647C100.86 33.1998 101.049 32.3038 101.426 31.5766C101.83 30.8234 102.37 30.2131 103.044 29.7456C103.744 29.2521 104.553 28.8885 105.47 28.6548C106.413 28.421 107.411 28.2782 108.462 28.2262L113.396 27.9535V27.3692C113.396 26.0706 113.099 25.0577 112.506 24.3305C111.94 23.5773 111.01 23.2007 109.716 23.2007C108.422 23.2007 107.478 23.5383 106.885 24.2136C106.292 24.8888 105.995 25.681 105.995 26.59L101.426 26.4342C101.426 25.5511 101.615 24.6941 101.992 23.863C102.37 23.0059 102.909 22.2527 103.61 21.6034C104.311 20.9281 105.173 20.3957 106.198 20.0061C107.222 19.5906 108.381 19.3828 109.675 19.3828ZM109.109 31.6156C108.031 31.6675 107.141 31.8883 106.44 32.2778C105.766 32.6414 105.429 33.2777 105.429 34.1868C105.429 35.0179 105.712 35.6022 106.279 35.9399C106.872 36.2775 107.626 36.4463 108.543 36.4463C109.999 36.4463 111.172 36.0957 112.061 35.3945C112.978 34.6932 113.436 33.5764 113.436 32.0441V31.3428L109.109 31.6156Z"
      fill="#212121"
    />
    <path
      d="M128.586 21.9936C129.071 21.2924 129.799 20.682 130.77 20.1626C131.74 19.6432 132.953 19.3835 134.409 19.3835C135.595 19.3835 136.674 19.5782 137.644 19.9678C138.642 20.3314 139.491 20.8768 140.192 21.604C140.92 22.3053 141.486 23.1753 141.89 24.2142C142.295 25.2271 142.497 26.3829 142.497 27.6815V39.7194H137.927V28.2658C137.927 26.7595 137.51 25.5777 136.674 24.7207C135.865 23.8636 134.773 23.4351 133.398 23.4351C131.943 23.4351 130.77 23.9026 129.88 24.8375C129.018 25.7466 128.586 26.9023 128.586 28.3048V39.7194H124.057V9.44922H128.586V21.9936Z"
      fill="#212121"
    />
    <path
      d="M155.167 19.3828C157.863 19.3828 159.912 20.0581 161.314 21.4086C162.742 22.7592 163.457 24.72 163.457 27.2912V39.7188H158.887V37.9267C158.159 38.602 157.324 39.1344 156.38 39.524C155.464 39.9136 154.426 40.1083 153.266 40.1083C151.191 40.1083 149.519 39.6019 148.252 38.589C146.985 37.5761 146.352 36.1347 146.352 34.2647C146.352 33.1998 146.54 32.3038 146.918 31.5766C147.322 30.8234 147.861 30.2131 148.535 29.7456C149.236 29.2521 150.045 28.8885 150.962 28.6548C151.905 28.421 152.903 28.2782 153.954 28.2262L158.887 27.9535V27.3692C158.887 26.0706 158.591 25.0577 157.998 24.3305C157.432 23.5773 156.502 23.2007 155.207 23.2007C153.913 23.2007 152.97 23.5383 152.377 24.2136C151.784 24.8888 151.487 25.681 151.487 26.59L146.918 26.4342C146.918 25.5511 147.106 24.6941 147.484 23.863C147.861 23.0059 148.4 22.2527 149.101 21.6034C149.802 20.9281 150.665 20.3957 151.689 20.0061C152.714 19.5906 153.873 19.3828 155.167 19.3828ZM154.601 31.6156C153.523 31.6675 152.633 31.8883 151.932 32.2778C151.258 32.6414 150.921 33.2777 150.921 34.1868C150.921 35.0179 151.204 35.6022 151.77 35.9399C152.363 36.2775 153.118 36.4463 154.035 36.4463C155.491 36.4463 156.663 36.0957 157.553 35.3945C158.469 34.6932 158.928 33.5764 158.928 32.0441V31.3428L154.601 31.6156Z"
      fill="#212121"
    />
    <path
      d="M169.549 39.7194V9.44922H174.078V39.7194H169.549Z"
      fill="#212121"
    />
    <path
      d="M189.203 19.3828C190.74 19.3828 192.169 19.6295 193.49 20.123C194.811 20.5905 195.943 21.2788 196.886 22.1878C197.857 23.0708 198.612 24.1616 199.151 25.4602C199.717 26.7328 200 28.1613 200 29.7456C200 31.3299 199.717 32.7583 199.151 34.0309C198.612 35.3036 197.857 36.3944 196.886 37.3034C195.943 38.1864 194.811 38.8747 193.49 39.3681C192.169 39.8616 190.74 40.1083 189.203 40.1083C187.666 40.1083 186.238 39.8616 184.917 39.3681C183.596 38.8747 182.45 38.1864 181.479 37.3034C180.509 36.3944 179.741 35.3036 179.174 34.0309C178.635 32.7583 178.366 31.3299 178.366 29.7456C178.366 28.1613 178.635 26.7328 179.174 25.4602C179.741 24.1616 180.509 23.0708 181.479 22.1878C182.45 21.2788 183.596 20.5905 184.917 20.123C186.238 19.6295 187.666 19.3828 189.203 19.3828ZM189.203 23.1228C187.397 23.1228 185.901 23.7071 184.714 24.8759C183.555 26.0186 182.976 27.6419 182.976 29.7456C182.976 31.8233 183.555 33.4466 184.714 34.6153C185.901 35.7581 187.397 36.3294 189.203 36.3294C190.093 36.3294 190.915 36.1866 191.67 35.9009C192.425 35.5892 193.085 35.1607 193.651 34.6153C194.217 34.0439 194.649 33.3557 194.945 32.5505C195.269 31.7194 195.431 30.7845 195.431 29.7456C195.431 28.7067 195.269 27.7847 194.945 26.9796C194.649 26.1485 194.217 25.4472 193.651 24.8759C193.085 24.3045 192.425 23.8759 191.67 23.5903C190.915 23.2786 190.093 23.1228 189.203 23.1228Z"
      fill="#212121"
    />
    <defs>
      <filter
        id="filter0_bd_983_31746"
        x="-144.354"
        y="-150.711"
        width="337.903"
        height="344.93"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="82" />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_983_31746"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-2" dy="3" />
        <feGaussianBlur stdDeviation="7.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_backgroundBlur_983_31746"
          result="effect2_dropShadow_983_31746"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect2_dropShadow_983_31746"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_bd_983_31746"
        x="-144.354"
        y="-150.711"
        width="337.903"
        height="344.93"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="82" />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_983_31746"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-2" dy="3" />
        <feGaussianBlur stdDeviation="7.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_backgroundBlur_983_31746"
          result="effect2_dropShadow_983_31746"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect2_dropShadow_983_31746"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_983_31746"
        x1="10.0882"
        y1="48.863"
        x2="48.8639"
        y2="9.9947"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#305F78" />
        <stop offset="0.481131" stop-color="#61D2F3" />
        <stop offset="1" stop-color="#7DD5E8" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_983_31746"
        x1="27.2321"
        y1="14.0799"
        x2="3.495"
        y2="39.1345"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#408CB5" />
        <stop offset="0.4375" stop-color="#76B1D0" />
        <stop offset="1" stop-color="#25526A" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_983_31746"
        x1="24.5975"
        y1="13.2891"
        x2="19.6435"
        y2="28.7061"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#28556D" />
        <stop offset="1" stop-color="#5698BC" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_983_31746"
        x1="40.8795"
        y1="18.1513"
        x2="25.6777"
        y2="35.0656"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#85B5CF" />
        <stop offset="0.456979" stop-color="#82B6D2" />
        <stop offset="1" stop-color="#327091" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_983_31746"
        x1="24.4381"
        y1="41.4023"
        x2="29.5164"
        y2="25.5376"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#036092" />
        <stop offset="0.932292" stop-color="#7BAFCB" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_983_31746"
        x1="24.5975"
        y1="13.2891"
        x2="19.6435"
        y2="28.7061"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#28556D" />
        <stop offset="1" stop-color="#5698BC" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_983_31746"
        x1="42.0074"
        y1="41.082"
        x2="46.9177"
        y2="25.4556"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#036092" />
        <stop offset="1" stop-color="#7BAFCB" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_983_31746"
        x1="42.2472"
        y1="13.4492"
        x2="37.1886"
        y2="29.1492"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#036092" />
        <stop offset="1" stop-color="#7BAFCB" />
      </linearGradient>
    </defs>
  </svg>
);

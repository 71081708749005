import React, { useEffect, useState } from "react";
import { Box, CircularProgress } from "@mui/material";
import { DTxCardStyle } from "../dashboard.style";
import { ResponsiveLine } from "@nivo/line";
import { errorToastMessage } from "../../../utils/toast";
import { retentionRateData } from "../dashboardDetails";
import { LoadingContainer } from "../../CMS/cms.style";

const RetentionRateTab = () => {
  const [loading, setLoading] = useState(false);
  const [retentionRate, setRetentionRate] = useState<any>([]);

  useEffect(() => {
    const fetchRetentionRateData = async () => {
      try {
        setLoading(true);
        // const res: AxiosResponse = await http.get("/dashboard");
        setRetentionRate([
          {
            id: "lineChart",
            data: retentionRateData,
          },
        ]);

        setLoading(false);
      } catch (err) {
        errorToastMessage(err as Error);
        setLoading(false);
      }
    };

    fetchRetentionRateData();
  }, []);

  return !loading ? (
    <Box sx={{ ...DTxCardStyle, height: "550px" }}>
      {/* <Typography variant="subtitle1" fontWeight="medium" color="#637E85">
        User Activity By State
      </Typography> */}
      <ResponsiveLine
        data={retentionRate}
        margin={{ top: 30, right: 50, bottom: 100, left: 100 }}
        xScale={{ type: "point" }}
        yScale={{
          type: "linear",
          min: 0,
          max: 100,
        }}
        yFormat=" >-.2f"
        colors={"#3F83F8"}
        lineWidth={4}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 0,
          tickPadding: 10,
          tickRotation: 0,
          legend: "Time",
          legendOffset: 60,
          legendPosition: "middle",
        }}
        axisLeft={{
          tickSize: 0,
          tickPadding: 15,
          tickRotation: 0,
          legend: "Heart Rate",
          legendOffset: -90,
          legendPosition: "middle",
          tickValues: [0, 25, 50, 75, 100],
          renderTick: ({ x, y, rotate, textAnchor, value }) => (
            <text
              x={x}
              y={y}
              fill="#355962"
              textAnchor={textAnchor}
              dominantBaseline="middle"
              transform={`translate(-15 ,2) rotate(${rotate})`}
              fontSize={14}
              fontWeight={500}
            >
              {value === 0 ? value : `${value}%`}
            </text>
          ),
        }}
        enablePoints={false}
        theme={{
          fontSize: 14,
          grid: {
            line: {
              strokeDasharray: "6 6",
            },
          },
          axis: {
            ticks: {
              text: {
                fontSize: 14,
                fontWeight: 500,
                fill: "#355962",
              },
            },
            legend: {
              text: {
                fill: "#9CA3AF",
                fontWeight: 500,
                fontSize: 14,
              },
            },
          },
        }}
      />
    </Box>
  ) : (
    <Box sx={LoadingContainer}>
      <CircularProgress />
    </Box>
  );
};

export default RetentionRateTab;

import { Box } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../Redux/hooks";
import { setCloudType } from "../../Redux/reducers/cloudSlice";
import {
  a11yProps,
  MainTabPanel,
  StyledTab,
  StyledTabs,
} from "../Common/UI/TabPanel";
import CloudHeader from "./CloudHeader";
import CloudUrlLoader from "./CloudUrlLoader";
import CloudUrlSetter from "./CloudUrlSetter";
import Highlights from "./Highlights";
import Tags from "./Tags";

const Cloud = () => {
  const dispatch = useAppDispatch();
  const { type, urlLoaded } = useAppSelector((state) => state.cloud);

  const handleChange = (_: any, newValue: string) => {
    dispatch(setCloudType(newValue));
  };

  return urlLoaded ? (
    <>
      <CloudHeader />
      <Box sx={{ borderTop: 1, borderColor: "#E5E7EB" }}>
        <StyledTabs value={type} onChange={handleChange}>
          <StyledTab label="Tags" value="tag" {...a11yProps(0)} />
          <StyledTab label="Highlights" value="highlight" {...a11yProps(1)} />
        </StyledTabs>
      </Box>
      <Box
        sx={{
          // height: "calc(100% - 146px)",
          // overflow: "auto",
          mt: "24px",
        }}
      >
        <MainTabPanel value={type} index={"tag"}>
          <Tags />
        </MainTabPanel>
        <MainTabPanel value={type} index={"highlight"}>
          <Highlights />
        </MainTabPanel>
      </Box>
      <CloudUrlSetter />
    </>
  ) : (
    <CloudUrlLoader />
  );
};

export default Cloud;

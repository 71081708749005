import { Box, Divider, SxProps, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { StyledTab, StyledTabs, a11yProps } from "../../Common/UI/TabPanel";
import { useAppDispatch, useAppSelector } from "../../../Redux/hooks";
import { setHealthType } from "../../../Redux/reducers/healthSlice";
import { useSearchParams } from "react-router-dom";

const Container: SxProps = {
  p: 2,
  display: "flex",
  alignItems: "center",
  borderRadius: "8px 8px 0px 0px",
  backgroundColor: "#ffffff",
};

const tabs = [
  // {
  //   key: "blood_glucose",
  //   label: "Blood Glucose",
  // },
  {
    key: "blood_pressure",
    label: "Blood Pressure",
  },
  {
    key: "heart_rate",
    label: "Heart Rate",
  },
  {
    key: "hba1c",
    label: "HBA1C",
  },
  {
    key: "activity",
    label: "Activity",
  },
  {
    key: "weight",
    label: "Weight",
  },
  {
    key: "nutrition",
    label: "Nutrition",
  },
  {
    key: "medication",
    label: "Medication",
  },
  {
    key: "sleep",
    label: "Sleep",
  },
];

const HealthTabs: React.FC = () => {
  const dispatch = useAppDispatch();
  const { type } = useAppSelector((state) => state.health);
  let [, setSearchParams] = useSearchParams();

  const tabChange = (_1: any, val: string) => {
    dispatch(setHealthType(val));
  };

  useEffect(() => {
    const params = new URLSearchParams();
    params.set("type", type);
    setSearchParams(params, {
      replace: true,
    });
  }, [type, setSearchParams]);

  return (
    <>
      <Box sx={Container}>
        <Typography fontWeight={"medium"} fontSize={"22px"} color="#355962">
          Health
        </Typography>
      </Box>
      <Divider sx={{ color: "#E5E7EB" }} />
      <StyledTabs value={type} onChange={tabChange}>
        {tabs.map((tab, index) => (
          <StyledTab
            key={tab.key}
            label={tab.label}
            value={tab.key}
            {...a11yProps(index)}
          />
        ))}
      </StyledTabs>
    </>
  );
};

export default HealthTabs;

import React, { useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormLabel,
  Modal,
  TextField,
} from "@mui/material";
import * as yup from "yup";
import { Form, Formik } from "formik";
import { AxiosResponse } from "axios";
import { ModalBaseStyles, ModalHeader } from "../../Common/styles/modal";
import { InputWrapper, LabelStyle } from "../../Common/styles/form";
import http from "../../../utils/http";
import { errorToastMessage, toastMessage } from "../../../utils/toast";
import { SectionType } from "../exercise.type";

let schema = yup.object().shape({
  title: yup.string().required("*Section Name is Required"),
});

type Props = {
  showModal: boolean;
  closeModal: () => void;
  exerciseId: string;
  categoryId: string;
  section: SectionType | null;
  refreshPage: () => void;
};

const AddSectionModal = ({
  showModal,
  closeModal,
  exerciseId,
  categoryId,
  section,
  refreshPage,
}: Props) => {
  const [submitLoader, setSubmitLoader] = useState<boolean>(false);

  const submitHandler = async (values: any) => {
    try {
      setSubmitLoader(true);
      let res: AxiosResponse;
      if (section) {
        res = await http.put(
          `/exercise-collection/${exerciseId}/categories/${categoryId}/section/${section?.id}`,
          values
        );
      } else {
        res = await http.post(
          `/exercise-collection/${exerciseId}/categories/${categoryId}/section`,
          values
        );
      }
      toastMessage("success", res.data.message);
      closeModal();
      refreshPage();
    } catch (err) {
      setSubmitLoader(false);
      errorToastMessage(err as Error);
    }
  };

  return (
    <Modal open={showModal} onClose={closeModal}>
      <Box sx={{ ...ModalBaseStyles, minHeight: "20vh" }}>
        <ModalHeader
          title={section?.id ? "Edit Section" : "Add Section"}
          onCloseClick={closeModal}
        />
        <Formik
          initialValues={{
            title: section?.title || "",
          }}
          validationSchema={schema}
          onSubmit={(values) => {
            submitHandler(values);
          }}
        >
          {({ getFieldProps, errors, touched }) => (
            <Form>
              <FormControl sx={InputWrapper}>
                <FormLabel sx={LabelStyle} htmlFor="section-name">
                  Section Name
                </FormLabel>
                <TextField
                  placeholder="Section Name"
                  id="section-name"
                  {...getFieldProps("title")}
                  error={touched?.title && errors?.title ? true : false}
                  helperText={
                    touched?.title && errors?.title
                      ? (errors?.title as string)
                      : " "
                  }
                />
              </FormControl>
              <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
                {!submitLoader ? (
                  <>
                    <Button type="submit" variant="contained">
                      Add
                    </Button>
                    <Button onClick={closeModal} variant="outlined">
                      Cancel
                    </Button>
                  </>
                ) : (
                  <CircularProgress size={25} />
                )}
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

export default AddSectionModal;
